import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../../components/PageTitle';
import LoadingIndicator from '../../components/LoadingIndicator';
import NotFoundPage from '../NotFoundPage';

import api from '../../api';
import getParameterByName from '../../utils/get-parameter-by-name';

const pageNames = {
  about: 'About Realm',
  terms: 'Website Terms & Conditions',
  privacy: 'Privacy Policy',
  confidentiality: 'Confidentiality Agreement',
  'cookies-policy': 'Cookies Policy',
  contact: 'Contact Us'
};

const CMSNames = {
  about: 'about',
  terms: 'terms-and-conditions',
  privacy: 'privacy-policy',
  confidentiality: 'confidentiality-agreement',
  'cookies-policy': 'gdpr'
};

class Company extends Component {
  state = {
    loading: false,
    pageType: '',
    pageTitle: '',
    pageSource: '',
    pageContent: []
  };

  componentDidMount() {
    this.loadContent();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.search !== prevProps.location.search ||
      this.props.application.locale !== prevProps.application.locale
    ) {
      this.loadContent();
    }
  }

  loadContent = () => {
    const type = getParameterByName('page');
    const name = pageNames[type];
    const cmsContent = CMSNames[type];
    this.setState(
      {
        loading: true,
        is404: !CMSNames[type],
        pageType: type,
        pageTitle: name,
        pageSource: cmsContent,
        pageContent: []
      },
      () => {
        this.fetchStory();
      }
    );
  };

  fetchStory = async () => {
    const content = this.state.pageSource;
    const response = await api.get('/cms/content', {
      params: {
        site: 'marketing',
        section: 'company',
        content,
        language: this.props.application.locale
      }
    });
    this.setState({
      pageContent: response.data.data.data,
      loading: false
    });
    window.scrollTo(0, 0);
  };

  render() {
    if (this.state.is404) {
      return <NotFoundPage />;
    }
    if (this.state.pageContent.length === 0 || this.state.loading) {
      return (
        <div className="page-with-title">
          <div className="realm--loading-container">
            <LoadingIndicator />
          </div>
        </div>
      );
    }
    return (
      <div className="page-with-title">
        <PageTitle title={this.state.pageTitle} />
        <div className="realm--page-for-copy">
          {this.state.pageType === 'about' ? (
            <div>ABOUT US</div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.pageContent[0].content.rendered
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

Company.propTypes = {
  application: PropTypes.object,
  location: PropTypes.object
};

export default Company;
