/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import { createMuiTheme, Grid, MuiThemeProvider } from '@material-ui/core';

import api from '../../api';
import localstring from '../../services/Localization';

import PageTitle from '../../components/PageTitle';
import LoadingIndicator from '../../components/LoadingIndicator';
import TableView from './TableView';
import MatchingAlertCard from '../../components/Matching/MatchingAlertCard';


const theme = createMuiTheme({
  palette: {
    type: 'dark'
  },
  overrides: {
    MuiTab: {
      label: {
        fontSize: '14px !important'
      }
    },
    MuiTabs: {
      flexContainer: {
        background: '#888'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'black'
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'red'
        }
      }
    }
  }
});

class MatchAlerts extends Component {
  state = {
    data: [],
    gridView: true,
    isLoading: false,
    noRecordsFound: false
  };

  componentDidMount() {
    this.getMatchAlerts();
  }

  getMatchAlerts = async () => {
    const { session } = this.props;
    this.setState({ isLoading: true });
    const thisAgent = session.agentData ? session.agentData._id : '';
    if (!thisAgent) {
      this.setState({
        isLoading: false,
        noRecordsFound: true
      });
      return null;
    }
    const response = await api.post('/match/getAlerts', {
      page: 1,
      limit: 50,
      agentId: thisAgent
    });
    this.setState(
      {
        data: response.data.data.data,
        isLoading: false,
        noRecordsFound: response.data.data.total === 0
      },
      () => {
        const el = document.getElementById('page-wrapper');
        if (el && isFunction(el.scrollTo)) {
          el.scrollTo(0, 0);
        }
      }
    );
    return null;
  };

  toggleView = (isGridView) => {
    this.setState({ gridView: isGridView });
  };

  showMatchAlertsDetail = (property) => {
    this.props.history.push(`/match-detail?id=${property._id}`);
  };

  renderGridView = () => (
    <div className="realm--grid-container">
      <Grid container spacing={24}>
        {this.state.data.map((message) => (
          <MatchingAlertCard key={message.messageId} message={message} />
        ))}
      </Grid>
    </div>
  );

  renderTableView = () => <TableView data={this.state.data} />;

  renderView = () => {
    const { gridView, noRecordsFound } = this.state;
    if (noRecordsFound) {
      return (
        <div className="realm--no-records-found">
          <div className="realm--no-records-found-message">
            <p><strong>No match alerts were found.</strong></p>
          </div>
        </div>
      );
    }
    return gridView || this.props.application.isMobileOnly ?
      this.renderGridView() : this.renderTableView();
  };

  render() {
    const { application } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="page-with-title">
        <PageTitle title={localstring.my_matching_alerts} />
        <MuiThemeProvider theme={theme}>
          {!application.isMobileOnly && (
            <div
              className="realm--legend-container"
              style={{
                opacity: isLoading ? '.5' : '1',
                pointerEvents: isLoading ? 'none' : 'auto'
              }}
            >
            </div>
          )}
          {isLoading ? (
            <div className="realm--loading-container">
              <LoadingIndicator />
            </div>
          ) : (
            this.renderView()
          )}
        </MuiThemeProvider>
      </div>
    );
  }
}

MatchAlerts.propTypes = {
  application: PropTypes.object,
  history: PropTypes.object,
  session: PropTypes.object
};

export default MatchAlerts;
