import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent
} from '@material-ui/core';

class OfficeCard extends PureComponent {
  render() {
    const { office } = this.props;
    if (!office) {
      return null;
    }
    return (
      <div className="realm--office-card">
        <Card>
          <CardContent>
            <span className="realm--office-card-title">
              {office.officeName}
            </span>
            <br />
            <span className="realm--office-card-address">
              {office.streetAddress}
              <br />
              {office.city}, {office.state}  {office.zipcode}
              <br />
              {office.phone}
            </span>
          </CardContent>
          <CardActions>
            {this.props.isAdmin ?
              <Button>Edit</Button>
              : null
            }
          </CardActions>
        </Card>
      </div>
    );
  }
}

OfficeCard.propTypes = {
  isAdmin: PropTypes.bool,
  office: PropTypes.shape({
    city: PropTypes.string,
    officeName: PropTypes.string,
    state: PropTypes.string,
    streetAddress: PropTypes.string,
    zipcode: PropTypes.string
  })
};

export default OfficeCard;
