import 'babel-polyfill';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import DevTools from './components/DevTools';
import { attachInterceptors } from './helpers/axios-interceptors';

import { IS_DEVELOPMENT } from './constants';
import store from './store';
import theme from './theme';
import App from './Application';

const MOUNT_NODE = document.getElementById('app');

const Realm = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Fragment>
        {IS_DEVELOPMENT && <DevTools />}
        <App />
      </Fragment>
    </MuiThemeProvider>
  </Provider>
);

// Set up axios interceptors
attachInterceptors();

ReactDOM.render(<Realm />, MOUNT_NODE);
