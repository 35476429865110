import LocalizedStrings from 'react-localization';

const localstring = new LocalizedStrings({
  en: {
    login: 'Sign in',
    login_username: 'Username',
    login_password: 'Password',
    login_please: 'Please Sign In.',

    logo_back_office: 'Back Office',

    continue_and_accept: 'Continue and Accept All',

    signing_in: 'Signing you into The Realm',

    language: 'Language',
    currency: 'Currency',
    units: 'Units',
    menu1_user_account: 'Account',
    menu1_user_profile: 'Profile:',
    menu1_user_logout: 'Sign Out',

    footer_copy1: 'Copyright',
    footer_copy2: 'REALM LLC',

    nav_menu_dashboard: 'Dashboard',
    nav_menu_dashboard_item1: 'Dashboard',

    nav_menu_matching: 'Matching',
    nav_menu_matching_alerts: 'Matching Alerts',
    // nav_menu_matching_item1: 'Matching Results',
    // nav_menu_matching_item2: 'Test Portal',

    nav_menu_brokerages: 'Brokerages',
    nav_menu_brokerages_item1: 'List',
    nav_menu_brokerages_item2: 'Add',
    nav_menu_brokerages_item3: 'Ingest',

    nav_menu_agents: 'Agents',
    nav_menu_agents_item1: 'List',
    nav_menu_agents_item2: 'Add',
    nav_menu_agents_item3: 'Ingest',

    nav_menu_clients: 'Clients',
    nav_menu_clients_item1: 'My Clients',
    nav_menu_clients_item2: 'Add',
    nav_menu_clients_item3: 'Ingest',

    nav_menu_properties: 'Properties',
    nav_menu_properties_item1: 'List',
    nav_menu_properties_item2: 'Add',
    nav_menu_properties_item3: 'Ingest',

    nav_menu_blog: 'Blog',

    nav_menu_concierge: 'Concierge',
    nav_menu_concierge_item1: 'Contact an Agent',
    nav_menu_concierge_item2: 'Contact a Brokerage',

    nav_menu_administration: 'Administration',
    nav_menu_administration_item1: 'Admins',
    nav_menu_administration_item2: 'Users',
    nav_menu_administration_item3: 'Upload',
    nav_menu_administration_item4: 'Logs',

    inactivity_warning: 'Inactivity Warning',
    inactivity_message:
      'You have been inactive for a while. Click Continue to stay signed in.',

    about_realm: 'About Realm',
    contact_us: 'Contact Us',
    contact_us_title: 'How can we help?',
    contact_to: 'Send to',
    contact_to_help: 'Help/Support',
    contact_to_suggestions: 'To the REALM Team',
    contact_re: 'Contacting you about...',
    contact_re_feedback: 'Feedback/Suggestions',
    contact_re_tech: 'Technical Support',
    contact_re_account: 'Help with my account',
    contact_re_other: 'Other',
    contact_message: 'Your message',
    contact_send: 'Send',
    contact: 'Contact',
    message_sent: 'Your message was sent',
    message_error: 'Sorry, there was an error. Please try again later.',

    terms: 'Terms & Conditions',
    privacy_policy: 'Privacy Policy',
    confidentiality: 'Confidentiality Agreement',
    cookies: 'Cookies Policy',
    copyright: 'Copyright',

    dash_page_name: 'Dashboard',
    dash_welcome: 'Welcome',
    dash_profile_completion: 'Profile Completion',
    dash_activity: 'Activity in Your Realm',
    dash_latest_word: 'The Latest Word',
    dash_realm_team: 'The REALM Team',
    dash_fine_tune: 'Fine Tune Now',
    dash_your_realm: 'Your Realm',
    dash_profile_views: 'Profile Views',
    dash_agent_connect: 'Agent Connect',
    dash_property_matching: 'Property Matches',
    dash_client_matching: 'Client Matches',
    dash_matching_alerts: 'Client Match Alerts',

    matching_page_name: 'Match My Properties',

    my_profile: 'My Profile',
    edit_profile: 'Edit Profile',
    my_clients: 'My Clients',
    my_properties: 'My Properties',
    my_matching_alerts: 'Matching Alerts',
    more: 'Read More',
    details: 'Details',
    see_listings: 'See Listings',
    link_to_full_listing: 'Full Listing',
    email_agent: 'Email Agent',

    agent: 'Agent',
    broker_admin: 'Brokerage Admin',
    properties: 'Properties',
    clients: 'Clients',
    agents: 'Agents',
    high_matches: 'High Matches',
    med_matches: 'Medium Matches',
    low_Matches: 'Near Matches',
    matched: 'Matched',
    matches: 'Matches',
    upload_now: 'Upload Now',
    drop_photo: 'Drop your photo here',
    photo_limit: 'Please limit the file size to 3MB',
    or_enter_url: 'Or enter the URL to your photo here',
    you_are_uploading: 'You are uploading a file named: ',
    wish_to_proceed: 'Do you wish to proceed?',
    cancel: 'Cancel',
    retry: 'Retry',
    close: 'Close',
    error: 'Error',
    tune_tags: 'Tune Your Tags',
    drop_csv: 'Drop your CSV file here',
    sort: 'Sort By',

    first_name: 'First Name',
    middle_name: 'Middle Name',
    last_name: 'Last Name',
    address: 'Address',
    city: 'City',
    country: 'Country',
    state: 'State',
    zip: 'Zip Code',
    email_type: 'Email type',
    office_name: 'Office Name',
    office_phone: 'Office Phone',
    yes: 'Yes',
    no: 'No',
    prefix: 'Prefix',
    suffix: 'Suffix',
    valid_email_required: 'A valid email is required.',
    phone_number: 'Phone Number',
    phone_type: 'Phone Type',
    phone_optional: 'Phone number is optional.',
    brokerage_name: 'Brokerage Name',
    select_brokerage: 'Select Brokerage',
    create_new_agent: 'Create New Agent',
    create_new_brokerage: 'Create New Brokerage',
    create_office_location: 'Would you like to create an office location?',

    no_tags_client: 'There have been no tags chosen for this client.',

    // items added through 9/20/18

    onboarding: {
      back: 'Back',
      brokerage: 'Brokerage',
      continue: 'Continue',
      email: 'Email',
      forgot_password: 'Forgot your password?',
      reset_password: 'Reset your password',
      label: 'Onboarding',
      login: 'Sign In',
      name: 'Name',
      next: 'Next',
      no_data: 'No Data',
      step1: 'Agreements',
      step2: 'Your Account',
      step3: 'Clients',
      step4: 'Property Tags',
      step5: 'In the REALM',
      password: 'Password',
      password_confirm: 'Password Confirm',
      phone: 'Phone',
      save: 'Save',
      save_continue: 'Save & Continue',
      set_password: 'Set Password',
      show_password: 'Show Password',
      skip: 'Skip',
      username: 'Username',
      welcome_title: 'Your <span>REALM</span> begins today.',
      welcome_pass:
        'Welcome to <span>REALM</span>, relationship powered, lifestyle inspired.<br />Let us help you get there, please create a secure password.',
      welcome_help1:
        'Use 8 or more characters with a mix of letters, numbers & symbols.',
      welcome_help2:
        'You will receive an email with your registration. You are requested to login and authenticate next.',
      login_title: 'Let’s get started',
      login_welcome:
        'Welcome to <span>REALM</span>, relationship powered, lifestyle inspired.<br />Let us help you get there, please login.',

      agreements_title: 'Welcome to <span>REALM</span>',
      agreements_p1:
        'REALM is the world’s most elite global alliance of independent brokers, partnering together to create a true global network. ',
      agreements_p2:
        'REALM enhances your client relationships through experiences that make a lifetime rather than search criteria. This is done with a curated lifestyle digital content platform, combining machine learning and artificial intelligence.',
      agreements_p3:
        'REALM serves the network of agents by providing the most exclusive, secure, confidential, and technology enhanced relationship optimizing the listing network between clients and agents.',
      agreements_p4:
        'To get started with the REALM, we will walk you through a few necessary steps to ensure a partnership experience. Starting with your agreement to the REALM policies.',
      agreements_accept: 'I Accept the REALM Policies',
      agreements_decline: 'I Decline the REALM Policies.',
      agreements_terms: 'Terms & Conditions',
      agreements_privacy: 'Privacy Policy',
      agreements_confidentiality: 'Confidentiality Agreement',
      agreements_cookies: 'Cookies Policy',
      agreements_decline_disclaimer1:
        'To decline these policies removes your access to the REALM.',
      agreements_decline_disclaimer2:
        'If you want to discuss these policies, <a href="">please contact us</a>.',

      account_label: 'Your Account',
      account_title: '<span>REALM</span> is your partner for success.',
      account_welcome1: 'If you need help with updating your account, simply',
      account_welcome2: 'contact us',
      account_welcome3: 'and we will be in touch.',

      account_form_label1: 'About Yourself',
      account_form_label2: 'Specialties',
      account_form_label3: 'How to Connect with Me',
      account_form_help1:
        'Take the time to tell other agents about your experience.',
      account_form_help2:
        'Add specialties to help other agents find you and your properties.',
      account_form_help3:
        'Add your phone numbers, and other ways you would want another agent to contact you (i.e. WhatsApp, your social profiles like LinkedIn).',

      account_form_social_title: 'Social Contacts:',
      account_form_contacts_title: 'Contact Information:',
      account_form_upload_photo: 'Upload Photo',
      account_form_new_password: 'New Password',
      account_form_change_password: 'Change Password',
      account_form_changing_password: 'Changing password ...',
      account_form_confirm_password: 'Confirm Password',
      account_form_password_change_successful:
        'Password was successfully changed',
      account_form_go_to_login_new_password:
        'Please go to the login screen to use your new credentials.',
      account_form_request_change_password:
        'If you request to change your password, your current password will no longer be valid.  You will receive an email with further instructions. Change your password?',
      account_form_passwords_match: 'Your passwords match!',
      account_form_ensure_passwords_match: 'Make sure your passwords match.',
      account_form_ensure_passwords_length:
        'Passwords must be 8 characters or more and contain at least 1 number, 1 uppercase letter and 1 special character.',
      account_form_password_correct_length: 'Your password is strong!',
      account_form_confirmation_code: 'Confirmation Code',
      account_form_verification_code_email:
        'Please use the code you received via email.',

      clients1_welcome: 'Add Clients',
      clients1_dropzone:
        'Drag and drop your REALM formatted client .csv file here or <a>select a file</a>',
      clients1_p1:
        'REALM collects, secures, and repositions your client’s data feeds to improve the holistic real estate experience – for each individual broker and its collective member-base. REALM’s best-in-class technology is revolutionary for the Real Estate market providing the highest level of security and protection of encrypted data.',
      clients1_p2:
        'You will be the only one to see your client data. All client names will be anonymized. The data you share will be used to provide the best property matches for your clients.',
      clients1_p3:
        '<strong>We do not have any of your clients in the REALM.</strong> Please begin by uploading your client list for your curated, lifestyle experience.',
      clients1_p4:
        '<h4>Congratulations! Looks like you have already uploaded your clients in the REALM. Please continue to the next step of your onboarding experience.<h4>',

      clients1_help1: 'Need help',
      clients1_help2: 'on how to get started?',
      clients1_policy: 'Review the REALM data policy.',
      clientslist_p1: 'Your current client list is show below:',
      clientsupload_welcome: 'Clients Upload',
      clientsupload_p1:
        "Please map your fields to the Realm's fields for your Clients.",
      clientsupload_p2: 'Unmatched fields will be ignored',

      addmoreclients_welcome: 'Add More Clients',
      addmoreclients_p1:
        'This feature will allow you to REPLACE your current client list.',

      replaceclients_welcome: 'Add Clients',
      replaceclients_p1:
        'This feature will allow you to REPLACE your current client list.',

      clientstags_welcome: 'Clients – Add Lifestyle Keywords',
      clientstags_p1:
        'For the full REALM experience, we have identified lifestyle keywords that will help us match properties to active buyers. Add keywords to your active properties for an enhanced matching process.',
      clientstags_p2:
        'As you select the lifestyle keywords, think about the type of buyer that would be a good fit for your property based on its uniqueness or value to the buyer.',

      property_welcome: 'Properties',
      property_p1: 'Your current property listings are shown below:',

      complete_title: 'you are now in the <span>REALM</span>',
      complete_welcome:
        'We look forward to our journey with you. Get started by exploring the site.',
      complete_dash: 'Visit Your Dashboard',
      complete_match: 'Visit Your Matches'
    }
  },
  zh_hans: {
    login: '登录',
    login_username: '用户名',
    login_password: '密码',

    logo_back_office: '后台办公室',

    menu1_user_account: '帐户',
    menu1_user_profile: '轮廓:',
    menu1_user_logout: '登出',

    nav_menu_dashboard: '仪表板',
    nav_menu_dashboard_item1: '仪表板',

    nav_menu_matching: '匹配',
    nav_menu_matching_item1: '匹配结果',
    nav_menu_matching_item2: '测试门户',

    nav_menu_brokerages: '券商',
    nav_menu_brokerages_item1: '名单',
    nav_menu_brokerages_item2: '加',
    nav_menu_brokerages_item3: '摄取',

    nav_menu_agents: '代理',
    nav_menu_agents_item1: '名单',
    nav_menu_agents_item2: '加',
    nav_menu_agents_item3: '摄取',

    nav_menu_clients: '客户端',
    nav_menu_clients_item1: '名单',
    nav_menu_clients_item2: '加',
    nav_menu_clients_item3: '摄取',

    nav_menu_properties: '属性',
    nav_menu_properties_item1: '名单',
    nav_menu_properties_item2: '加',
    nav_menu_properties_item3: '摄取',

    nav_menu_blog: '博客',

    nav_menu_concierge: '门房',
    nav_menu_concierge_item1: '联系代理商',
    nav_menu_concierge_item2: '联系经纪人e',

    nav_menu_administration: '行政',
    nav_menu_administration_item1: '用户',
    nav_menu_administration_item2: '摄取数据',
    nav_menu_administration_item3: '日志',
    nav_menu_administration_item4: '手动日志',

    dash_page_name: '仪表板'
  },

  ar: {
    login: 'تسجيل الدخول',
    login_username: 'اسم المستخدم',
    login_password: 'كلمه السر',

    logo_back_office: 'مكتب خلفي',

    menu1_user_account: 'الحساب',
    menu1_user_profile: 'الملف الشخصي:',
    menu1_user_logout: 'الخروج',

    nav_menu_dashboard: 'لوحة القيادة',
    nav_menu_dashboard_item1: 'لوحة القيادة',

    nav_menu_matching: 'مطابقة',
    nav_menu_matching_item1: 'نتائج المطابقة',
    nav_menu_matching_item2: 'اختبار البوابة',

    nav_menu_brokerages: 'شركات السمسرة',
    nav_menu_brokerages_item1: 'قائمة',
    nav_menu_brokerages_item2: 'إضافة',
    nav_menu_brokerages_item3: 'ابتلاع',

    nav_menu_agents: 'عملاء',
    nav_menu_agents_item1: 'قائمة',
    nav_menu_agents_item2: 'إضافة',
    nav_menu_agents_item3: 'ابتلاع',

    nav_menu_clients: 'عملاء',
    nav_menu_clients_item1: 'قائمة',
    nav_menu_clients_item2: 'إضافة',
    nav_menu_clients_item3: 'ابتلاع',

    nav_menu_properties: 'الخصائص',
    nav_menu_properties_item1: 'قائمة',
    nav_menu_properties_item2: 'إضافة',
    nav_menu_properties_item3: 'ابتلاع',

    nav_menu_blog: 'مدونة',

    nav_menu_concierge: 'خدمات الاستقبال والإرشاد',
    nav_menu_concierge_item1: 'اتصل بوكيل',
    nav_menu_concierge_item2: 'اتصل بالسمسرة',

    nav_menu_administration: 'الادارة',
    nav_menu_administration_item1: 'المستخدمين',
    nav_menu_administration_item2: 'ادخال البيانات',
    nav_menu_administration_item3: 'سجلات',
    nav_menu_administration_item4: 'سجلات يدوية',

    dash_page_name: 'لوحة القيادة'
  }
});

export default localstring;
