import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';

import api from '../../api';

class AdminActions extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: false,
      importRealmTagsComplete: false,
      importClientTagsComplete: false,
      importListingTagsComplete: false,
      matchingComplete: false,
      nonClientsComplete: false,
    };
  }

  // importTags = async () => {
  //   this.setState({ loading: true, importComplete: false });
  //   const response = await api.post('importCSV/tags', {
  //     importFrom: 'local',
  //     delete: true
  //   });
  //   this.setState({
  //     data: response.data.data.data,
  //     loading: false,
  //     importComplete: true
  //   });
  // };

  // Import realmData Tag Names / Categories
  importTags = async () => {
    this.setState({ loading: true, importRealmTagsComplete: false });

    // Grab CSV Tags from File field
    const fileToUpload = document.getElementById('file').files[0];

    if (!fileToUpload) {
      alert('CSV File upload required');
      this.setState({ loading: false, importRealmTagsComplete: false });
    } else {
      const data = new FormData();
      data.append('file', fileToUpload);
      data.append('fromLocal', false); // Change to true if importing from tags.csv file from local repo. Upload will be ignored.
      data.append('deleteTags', true);

      const response = await api.post('/importCSV/tags', data);

      this.setState({ data: response.data.data.data });
      this.setState({ loading: false, importRealmTagsComplete: true });
    }
  };

  // Import TIBIL assigned client tags
  importClientTags = async () => {
    this.setState({ loading: true, importClientTagsComplete: false });

    // Grab JSON TIBIL file from fileClientTags field
    const fileToUpload = document.getElementById('fileClientTags').files[0];

    if (!fileToUpload) {
      alert('JSON File upload required');
      this.setState({ loading: false, importClientTagsComplete: false });
    } else {
      const data = new FormData();
      data.append('file', fileToUpload);
      data.append('deleteTags', false);

      const response = await api.post('/etl/agentClientTags', data);
      this.setState({ data: response.data.data.data });
      this.setState({ loading: false, importClientTagsComplete: true });
    }
  };

  // Import TIBIL assigned listing tags
  importListingTags = async () => {
    this.setState({ loading: true, importListingTagsComplete: false });

    // Grab JSON TIBIL file from fileListingTags field
    const fileToUpload = document.getElementById('fileListingTags').files[0];

    if (!fileToUpload) {
      alert('JSON File upload required');
      this.setState({ loading: false, importListingTagsComplete: false });
    } else {
      const data = new FormData();
      data.append('file', fileToUpload);
      data.append('deleteTags', false);

      const response = await api.post('/etl/listingTags', data);
      this.setState({
        data: response.data.data.data,
        loading: false,
        importListingTagsComplete: true
      });
    }
  };

  matchListings = async () => {
    this.setState({ loading: true, matchingComplete: false });
    const response = await api.get('/match/allListings');
    this.setState({
      data: response.data.data.data,
      loading: false,
      matchingComplete: true
    });
  };

  matchListingTest = async () => {
    this.setState({ loading: true, matchingComplete: false });
    const response = await api.get(
      '/match/oneListing/5b6c6b479e89b41f53e0e52c'
    );
    this.setState({
      data: response.data.data.data,
      loading: false,
      matchingComplete: true
    });
  };

  handleRealmTagImport = () => {
    // Handle import of uploaded tag CSV file
    this.importTags();
  };

  handleClientTagImport = () => {
    // Handle import of uploaded client tag JSON file
    this.importClientTags();
  };

  handleListingTagImport = () => {
    // Handle import of uploaded listing tag JSON file
    this.importListingTags();
  };

  handleMatchTest = () => {
    this.matchListingTest();
  };

  handleMatchingAll = () => {
    this.matchListings();
  };

  handleUpload() {
    const data = new FormData();
    data.append('foo', 'bar');
    data.append('file', document.getElementById('file').files[0]);

    api
      .post('/', data)
      .then((res) => {
        alert(res.data.msg);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  }

  handleCreateNonClients = async () => {
    this.setState({ loading: true, nonClientsComplete: false });
    const response = await api.post('/agentClients/nonclients');
    this.setState({
      data: response.data.data,
      loading: false,
      matchingComplete: true
    });
    alert(`Found ${response.data.data.count} clients to not duplicate.  There are ${response.data.data.potentialClients} potential (non) clients`);
    this.setState({ nonClientsComplete: true });
  };

  render() {
    return (
      <div className="page-with-title">
        <PageTitle title="Admin Tools" />
        {this.state.loading ? (
          <LoadingIndicator />
        ) : (
            <div className="realm--page-for-copy">
              <div>
                <h3>Import Tags</h3>
                <br />
                <input type="file" id="file" />
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleRealmTagImport}
                >
                  Import Realm Tags (CSV)
              </Button>
                {this.state.importRealmTagsComplete ? (
                  <span> Importing tags complete</span>
                ) : null}


                <br />
                <br />
                <input type="file" id="fileClientTags" />
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleClientTagImport}
                >
                  Import TIBIL Client Tags (JSON)
              </Button>
                {this.state.importClientTagsComplete ? (
                  <span> Importing client tags complete</span>
                ) : null}


                <br />
                <br />
                <input type="file" id="fileListingTags" />
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleListingTagImport}
                >
                  Import TIBIL Listing Tags (JSON)
              </Button>
                {this.state.importListingTagsComplete ? (
                  <span> Importing listing tags complete</span>
                ) : null}

              </div>
              <br />
                <br />
              <hr />
              <div>
                <h3>Match Listings</h3>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleMatchTest}
                  style={{ marginRight: 18 }}
                >
                  Match Listings Test
              </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleMatchingAll}
                  style={{ marginRight: 18 }}
                >
                  Match All Listings
              </Button>
                {this.state.matchingComplete ? (
                  <span> Matching complete</span>
                ) : null}
              </div>
              <br />
                <br />
              <hr />
              <div>
                <h3>Create Non-Clients</h3>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNonClients}
                  style={{ marginRight: 18 }}
                >
                  Create Non-Clients
              </Button>
                {this.state.nonClientsComplete ? (
                  <span> Non-Client creation complete</span>
                ) : null}
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default AdminActions;
