/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import { Button } from '@material-ui/core';

import localstring from '../../services/Localization';

class CookieWarning extends Component {
  handleClick = (e) => {
    e.preventDefault();
    if (isFunction(this.props.acceptCookies)) {
      this.props.acceptCookies();
    }
  };

  render() {
    return (
      <div className="realm--cookie-warning">
        <p className="realm--cookie-warning-message">
          The REALM relies on cookies to collect and process data. We may use
          this data to make assumptions on what content may interest you. By
          clicking <strong>"Continue and Accept All"</strong>, you agree to
          allow cookies to be placed. To get more information about these
          cookies and the processing of your personal data, check our Privacy
          Policy & Cookie Policy.
        </p>
        <div className="realm--cookie-warning-actions">
          <Button
            onClick={this.props.acceptCookies}
            color="primary"
            variant="contained"
          >
            {localstring.continue_and_accept}
          </Button>
        </div>
      </div>
    );
  }
}

CookieWarning.propTypes = {
  acceptCookies: PropTypes.func
};

export default CookieWarning;
