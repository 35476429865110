/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import api from '../../../api';

import Edit from '../../../components/Users/Edit';
import Delete from '../../../components/Users/Delete';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PageTitle from '../../../components/PageTitle';
import RegisterAgent from '../../../components/Users/RegisterAgent';

class Users extends Component {
  state = {
    loading: false,
    isVisible: false,
    users: []
  };

  componentDidMount() {
    this.getUsers();
  }

  getUsers = async () => {
    this.setState({ loading: true });
    const response = await api.get('/admin/users', {
      params: {}
    });
    this.setState({
      users: response.data.data,
      loading: false
    });
  };

  createAdmin = async () => {
    this.setState({ isVisible: true });
  };

  handleClose = () => {
    this.setState({ isVisible: false });
  };

  renderRow(row) {
    return (
      <span>
        <Edit
          onRefresh={this.getUsers}
          user={row.row}
          userId={row.row._id}
        />{' '}
        |
        <Delete
          onRefresh={this.getUsers}
          user={row.row}
          userId={row.row._id}
        />{' '}
        |
        <a href="#"> Reset PW</a>
      </span>
    );
  }

  render() {
    const { isAdmin } = this.props;
    const { users, isVisible } = this.state;
    return (
      <div className="page-with-title">
        <PageTitle title="Users" addPlus="true" handleClick={this.createAdmin} />
        <br />
        {this.state.loading ? <LoadingIndicator /> : <span />}
        <ReactTable
          data={users}
          defaultSorted={[
            {
              id: 'firstName',
              desc: true
            }
          ]}
          filterable
          defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
          columns={[
            {
              id: 'firstName',
              Header: 'First Name',
              accessor: 'firstName',
              width: 100,
              filterMethod: (filter, row) => {
                let name = `${row._original.firstName}`;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.startsWith(match);
              }
            },
            {
              Header: 'Last Name',
              accessor: 'lastName',
              width: 120,
              filterMethod: (filter, row) => {
                let name = `${row._original.lastName}`;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.startsWith(match);
              }
            },
            {
              Header: 'Email',
              accessor: 'email',
              filterMethod: (filter, row) => {
                let name = `${row._original.email}`;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.startsWith(match);
              }
            },
            {
              Header: '_id',
              accessor: '_id',
              filterable: false
            },
            {
              Header: 'Pool Id',
              accessor: 'userPoolId',
              filterable: false
            },
            {
              Header: 'Actions',
              filterable: false,
              show: isAdmin,
              Cell: (row) => this.renderRow(row)
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        <RegisterAgent
          isVisible={isVisible}
          handleClose={this.handleClose}
          onRefresh={this.getUsers}
        />
        <br />
      </div>
    );
  }
}

Users.propTypes = {
  isAdmin: PropTypes.bool
};

export default Users;
