import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import TagSelect from './TagSelect';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 800,
    maxWidth: 800
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
});

class AddTags extends Component {
  state = {
    open: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.parent.state.openSelector) {
      this.setState({
        open: true
      });
    }
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: Number(event.target.value)
    });
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.parent.setState({ openSelector: false });
    this.props.parent.loadTags();
  };

  render() {
    const { type } = this.props;
    let title = 'Matching Qualities';
    if (type === 'agents' || type === 'brokerages') {
      title = 'Specialties';
    }
    return (
      <Fragment>
        {this.props.title === 'tune' ? null : (
          <Button
            style={{
              minHeight: 'auto',
              height: 32,
              margin: 4,
              fontSize: 12,
              borderRadius: 12,
              backgroundColor: '#918fcb',
              color: '#fff'
            }}
            onClick={this.handleClickOpen}
          >
            {this.props.title ? this.props.title : 'Add Tags'}
          </Button>
        )}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          margin="dense"
          maxWidth={'md'}
          onClose={this.handleClose}
          open={this.state.open}
        >
          <DialogTitle>
            {title} for {this.props.name}
          </DialogTitle>
          <DialogContent>
            <TagSelect type={this.props.type} id={this.props.id} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.handleClose}
              variant="contained"
            >
              DONE
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

AddTags.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.any,
  type: PropTypes.oneOf(['agents', 'agentClients', 'brokerages', 'listings', 'properties'])
};

export default withStyles(styles, { withTheme: true })(AddTags);
