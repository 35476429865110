import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import api from '../../api';
import getParameterByName from '../../utils/get-parameter-by-name';
import localstring from '../../services/Localization';
import ErrorDialog from '../../components/Dialogs/ErrorDialog';
import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';
import ProfilePicture from '../../components/ProfilePicture';
import TagList from '../../components/Tags/TagList';

class AgentProfile extends Component {
  constructor(props) {
    super(props);
    const aid = props.location.search.split('=')[1];
    this.state = {
      isMe: false,
      agentData: null,
      errorMessage: '',
      isError: false,
      isLoading: false,
      agentId: aid,
      photoURL: null,
      personData: null,
      refresh: null
    };
  }

  componentDidMount() {
    const { session } = this.props;
    const agentId = getParameterByName('id') || null;
    if (session.isAuthenticated) {
      this.loadThisAgent(agentId);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      const agentId = getParameterByName('id');
      this.loadThisAgent(agentId);
    }
  }

  loadThisAgent = async (id) => {
    const { session } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await api.get(`/agents/${id}`);
      if (response.data && response.data.data && response.data.data.data) {
        const agentData = response.data.data.data;
        const photoURL =
          agentData.person.realmData.photoUrl || agentData.photoURL;
        this.setState({
          agentData,
          brokerageName: agentData.brokerages[0].name,
          isMe:
            session.isAdmin ||
            (session.agentData && session.agentData._id === id),
          isLoading: false,
          personData: agentData.person,
          photoURL
        });
      }
      // TODO: if is null, need USER DOESN'T EXIST
      this.setState({ isLoading: false });
    } catch (err) {
      // TODO: if is error, need USER DOESN'T EXIST
      this.setState({
        errorMessage: `User was not found: ${err}`,
        isError: true,
        isLoading: false
      });
    }
  };

  showName = () => {
    let name = 'Agent Profile';
    if (this.state.personData && this.state.personData.name) {
      if (this.state.personData.name.firstName) {
        name = this.state.personData.name.firstName;
        if (this.state.personData.name.lastName) {
          name += ` ${this.state.personData.name.lastName}`;
        }
      } else if (this.state.personData.name.lastName) {
        name = this.state.personData.name.lastName;
      } else if (this.state.personData.name.fullName) {
        name = this.state.personData.name.fullName;
      }
    }
    return name;
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
      isError: false,
      isLoading: false
    });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="page-with-title" key={this.state.refresh}>
          <PageTitle goBack history={this.props.history} />
          <LoadingIndicator />
        </div>
      );
    }
    return (
      <div className="page-with-title" key={this.state.refresh}>
        <PageTitle goBack history={this.props.history} />
        {this.state.agentData && (
          <div className="realm--agent-profile">
            <Grid container spacing={16} alignItems="center">
              <Grid className="text-centered" item xs={12}>
                <h1 className="realm--serified">{this.showName()}</h1>
                <ProfilePicture
                  centered
                  fullName={this.state.agentData.fullName}
                  photoURL={this.state.photoURL}
                  style={{ marginBottom: 10 }}
                />
                <div>{this.state.brokerageName}</div>
                <div style={{ margin: '10px 0' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 10 }}
                  >
                    <Link to={`/my-account/?id=${this.state.agentId}`}>
                      {localstring.edit_profile}
                    </Link>
                  </Button>
                  <Button color="primary" variant="contained">
                    <Link to={`/properties/list/?agent=${this.state.agentId}`}>
                      {localstring.see_listings}
                    </Link>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3 className="realm--serified text-centered">Specialties</h3>
                <div className="realm--agent-profile-content-panel">
                  <TagList
                    format="default"
                    id={this.state.agentId}
                    name={this.state.agentData.fullName}
                    type="agents"
                    readonly={!this.state.isMe}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3 className="realm--serified text-centered">
                  Contact Information
                </h3>
                <div className="realm--agent-profile-content-panel">
                  {this.state.personData.primaryEmail && (
                    <div>
                      Primary Email:{' '}
                      <a href={`mailto:  ${this.state.personData.primaryEmail}`}>
                        {this.state.personData.primaryEmail}
                      </a>
                    </div>
                  )}
                  {this.state.personData.emails.map((email) => (
                    <div key={email}>
                      Other Email:{' '}
                      <a href={`mailto:  ${email.address}`}>{email.address}</a>
                    </div>
                  ))}
                  {this.state.personData.phones.map((phone) => (
                    <div key={`${phone.phoneType}:${phone.number}`}>
                      {phone.phoneType}: {phone.number || 'No phone listed'}
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <ErrorDialog
          handleClose={this.handleClose}
          message={this.state.errorMessage}
          open={this.state.isError}
        />
      </div>
    );
  }
}

AgentProfile.propTypes = {
  session: PropTypes.object
};

export default AgentProfile;
