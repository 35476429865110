import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';

class BrokerageCard extends Component {
  render() {
    const { data, id } = this.props;
    if (!data) {
      return null;
    }
    return (
      <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
        <Card
          style={{ position: 'relative', height: '100%', paddingBottom: 20 }}
        >
          <CardContent>
            <div className="realm--agentclient-card-content">
              <h3>{data.name}</h3>
            </div>
            <div className="realm--agentclient-card-info">
              {data.agents.length > 0 && <p><Link to={`/agents/?brokerage=${this.props.id}`}>{data.agents.length} Agents</Link></p>}
              {data.offices.length > 0 && <p>{data.offices.length} Offices</p>}
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

BrokerageCard.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string
};

export default BrokerageCard;
