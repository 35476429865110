import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import merge from 'lodash.merge';
import Pagination from 'material-ui-flat-pagination';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Switch
} from '@material-ui/core';

import api from '../../api';
import sortList from '../../utils/sort-list';
import * as storage from '../../persistence/storage';

import AgentClientCard from '../../components/AgentClientCard';
import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';
import Sorter from '../../components/Sorter/Sorter';
import TagDialog from '../../components/Tags/TagDialog';
import Tag from '../../components/Tags/Tag';
import TagList from '../../components/Tags/TagList';
import UploadClients from '../../components/Upload/UploadClients';
import ViewToggle from '../../components/PageControls/ViewToggle';

class Clients extends Component {
  state = {
    data: [],
    offset: 0,
    totalRecords: 0,
    gridView: false,
    loading: false,
    agentPersonId: null,
    noRecordsFound: false,
    getFilterParams: {
      page: 1, // from 1 to n
      limit: 25, // max number of results to return
      filter: {}, // in case we want to call a specific {<field>:value} as if we're creating the mongo query
      sortBy: 'fullName', // column to sort by
      sortDir: 1, // direction: 1 ascending, -1 descending
      name: '', // name string to filter by
      brokerage: null, // brokerageID to filter by
      tags: [], // tags array to filter by
      upload: null, // upload status to filter by
      invite: null, // invite status to filter by
      searchName: '',
      tagKey: Date.now(),
    },
    showOnlyTagged: true,
    showUploadDialog: false
  };

  componentDidMount() {
    this.getClients();
    console.log('dsa', api.defaults.headers);
  }

  getClients = async () => {
    this.setState({ loading: true });
    let thisAgent = '';
    if (this.state.agentPersonId) {
      thisAgent = this.state.agentPersonId;
    } else {
      const agentData = storage.get('agentData');
      const ajson = JSON.parse(agentData);
      if (ajson && ajson.person._id) {
        thisAgent = ajson.person._id;
      }
    }
    const { getFilterParams } = this.state;
    getFilterParams.person = thisAgent || undefined;
    if( this.state.showOnlyTagged ) {
      getFilterParams.filter = {'realmData.lifestyleNames': {$ne: null}};
    } else {
      getFilterParams.filter = {};
    }
    const response = await api.post(
      '/agentClients/filter',
      getFilterParams
    );
    const { docs } = response.data.data;
    this.setState({
      data: docs,
      agentPersonId: thisAgent,
      loading: false,
      totalRecords: response.data.data.total,
      noRecordsFound: response.data.data.total === 0
    });
  };

  uploadClients = () => {
    this.setState({ showUploadDialog: true });
  };

  handleClose = () => {
    this.setState({ showUploadDialog: false });
  };

  // PAGINATION
  nextPage = (offset) => {
    const { limit } = this.state.getFilterParams;
    const newState = merge({}, this.state.getFilterParams, {
      page: (offset / limit) + 1
    });
    this.setState({ offset, getFilterParams: newState }, () => {
      this.getClients();
    });
  };

  // FILTERS
  applyNameFilter = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      this.getClients();
    }
    return null;
  };

  applyTagFilter = () => {
    this.getClients();
  };

  clearNameFilter = () => {
    const newState = merge({}, this.state.getFilterParams, { searchName: '', page: 1 });
    this.setState({ getFilterParams: newState, offset: 0 }, () => {
      this.getClients();
    });
  };

  clearTagFilter = () => {
    const newState = Object.assign({}, this.state.getFilterParams, {
      tags: [],
      page: 1
    });
    this.setState(
      {
        getFilterParams: newState,
        offset: 0
      },
      () => {
        this.setState({
          tagKey: Date.now()
        });
        this.getClients();
      }
    );
  };

  setNameFilter = (e) => {
    const searchName = e.target.value.toLowerCase();
    const newState = merge({}, this.state.getFilterParams, {
      searchName,
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 });
  };

  setTagFilter = (tags = []) => {
    const newState = Object.assign({}, this.state.getFilterParams, { tags, page: 1 });
    this.setState({
      getFilterParams: newState,
      offset: 0
    });
  };

  // HANDLE SORT
  handleSort = (event) => {
    const index = event.target.value;
    const newSort = sortList[index].order;
    const newState = Object.assign({}, this.state.getFilterParams, { page: 1, sortDir: newSort });
    this.setState({ getFilterParams: newState, offset: 0 },
      () => {
        this.getClients();
      });
  };

  toggleView = (isGridView) => {
    this.setState({ loading: true, gridView: isGridView });
    this.getClients();
  };

  // ********************************
  //
  //      show client profile
  //
  // ********************************
  showClientProfile = (client) => {
    window.location.replace(`/client-profile?id=${client._id}`);
  };

    // ********************************
  //
  //      show-only-tagged toggle
  //
  // ********************************
  handleShowTagged = () => {
    this.setState(
      {
        showOnlyTagged: !this.state.showOnlyTagged
      },
      () => {
        this.getClients();
        /*
        const newState = this.state.showMine
          ? Object.assign({}, this.state.getFilterParams, {
            filter: { listingAgents: [this.state.selectedAgent] },
            searchAddress: '',
            searchName: '',
            tags: [],
            tagKey: Date.now()
          })
          : Object.assign({}, this.state.getFilterParams, {
            filter: null,
            searchAddress: '',
            searchName: '',
            tags: [],
            tagKey: Date.now()
          });

        this.setState(
          {
            getFilterParams: newState,
            offset: 0
          },
          () => {
            this.getProperties();
          }
        );
        */
      }
    );
  };

  // ********************************
  //
  //      render cards for card view
  //
  // ********************************
  renderCards = (data) =>
    data.map((card) => (
      <AgentClientCard
        data={card.client}
        editableTags
        id={card._id}
        realmData={card.realmData}
        type="agentClients"
      />
    ));

  // ********************************
  //
  //      render grid view
  //
  // ********************************
  renderView = () => {
    const { data, gridView, noRecordsFound } = this.state;
    if (noRecordsFound) {
      return (
        <div className="realm--no-records-found">
          <div className="realm--no-records-found-message">
            <p><strong>No records were found.</strong></p>
            <p>To add clients, start by pressing the plus in the upper right-hand corner.</p>
          </div>
        </div>
      );
    }
    if (gridView || this.props.application.isMobileOnly) {
      return (
        <div className="realm--grid-container">
          <Grid container spacing={24}>
            {data && this.renderCards(data)}
          </Grid>
        </div>
      );
    }

  // ********************************
  //
  //      main entry for rendering
  //
  // ********************************
    return (
      <ReactTable
        className="-striped -highlight"
        data={data}
        defaultPageSize={this.state.getFilterParams.limit}
        filterable={false}
        showPagination={false}
        sortable={false}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            columns: [
              {
                id: 'clientName',
                Header: 'Name',
                width: 250,
                accessor: (n) => n.name,
                Cell: (cellInfo) => (
                  <span>
                    {cellInfo.original.client.name ? (
                      <div
                        key={`${cellInfo.original.client.name.firstName} ${
                          cellInfo.original.client.name.lastName
                          }`}
                      >
                        <Link to={`/client-profile?id=${cellInfo.original._id}`}>
                          {cellInfo.original.client.name.firstName}{' '}
                          {cellInfo.original.client.name.lastName}
                        </Link>
                      </div>
                    ) : null}
                  </span>
                )
              },
              {
                id: 'clientPhone',
                Header: 'Phone(s)',
                filterable: false,
                width: 250,
                accessor: (n) => n.phones,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original.client.phones.map((phone, i) => (
                      <div key={`phone-${phone.number}-${i}`}>
                        {phone.phoneType}: {phone.number}
                      </div>
                    ))}
                  </div>
                )
              },
              {
                id: 'clientEmail',
                Header: 'Email',
                filterable: false,
                width: 250,
                accessor: (n) => n.emails,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original.client.emails.map((email, i) => (
                      <div key={`email-${email.address}-${i}`}>
                        <a href={`mailto:  ${email.address}`}>
                          {email.address}
                        </a>
                      </div>
                    ))}
                  </div>
                )
              },
              {
                id: 'clientTags',
                Header: 'Matching Qualities',
                filterable: false,
                accessor: (n) => n.emails,
                Cell: (cellInfo) => (
                  <div
                    key={`cellfortaglist-${cellInfo.original._id}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <TagList
                      format="default"
                      id={cellInfo.original._id}
                      name={`${cellInfo.original.client.name.firstName} ${
                        cellInfo.original.client.name.lastName
                      }`}
                      type="agentClients"
                    />
                  </div>
                )
              }
            ]
          }
        ]}
      />
    );
  };

  render() {
    const { isMobile, isMobileOnly } = this.props.application;
    const { getFilterParams, offset, totalRecords } = this.state;
    const tagMessage =
      getFilterParams.tags.length > 1
        ? `You are filtering on ${getFilterParams.tags.length} tags`
        : 'You are filtering on 1 tag';
    const showPagination = this.state.totalRecords > getFilterParams.limit;
    return (
      <Fragment>
        <div className="page-with-title">
          <PageTitle
            addPlus={!isMobile}
            handleClick={this.uploadClients}
            title="My Clients"
          />
          <div
            className="realm--filters"
            style={{
              opacity: this.state.loading ? '.5' : '1',
              pointerEvents: this.state.loading ? 'none' : 'auto'
            }}
          >
            {!isMobileOnly && (
              <div className="realm--filter toggle">
                <ViewToggle clickHandler={this.toggleView} gridView={false} />
              </div>
            )}

            <div className="realm--filter switch">
              <Switch
                checked={this.state.showOnlyTagged}
                onChange={this.handleShowTagged}
                color="primary"
              />
              <span className="realm--property-labels">
                {`Tagged Only`}
              </span>
            </div>

            <div className="realm--filter">
              <TagDialog
                existingFilterTags={getFilterParams.tags}
                filterable
                key={this.state.tagKey}
                onFilter={this.setTagFilter}
                onFilterDone={this.applyTagFilter}
                title="Filter by qualities"
                type="agentClients"
              />
              {getFilterParams.tags.length !== 0 && (
                <Tag
                  format="default"
                  label={tagMessage}
                  onDelete={this.clearTagFilter}
                />
              )}
            </div>
            <div className="realm--filter ie">
              <Sorter
                handleSorterPicked={this.handleSort}
                sortList={sortList}
              />
            </div>
            <div className="realm--filter ie">
              <TextField
                id="nameSearch"
                label="Search by name"
                onKeyDown={this.applyNameFilter}
                onChange={this.setNameFilter}
                value={getFilterParams.searchName}
                InputProps={
                  getFilterParams.searchName
                    ? {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ position: 'relative' }}
                        >
                          <IconButton
                            aria-label="Clear input field"
                            onClick={this.clearNameFilter}
                            style={{
                              position: 'absolute',
                              top: -10,
                              right: -15
                            }}
                          >
                            <i
                              className="fa fa-close"
                              style={{ fontSize: 12 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    : {}
                }
              />
            </div>
            {showPagination && (
              <div className="realm--filter">
                <Pagination
                  currentPageColor="primary"
                  limit={getFilterParams.limit}
                  offset={offset}
                  total={totalRecords}
                  onClick={(e, offset) => this.nextPage(offset)}
                  otherPageColor="default"
                />
              </div>
            )}
            {this.state.loading ? (
              <div className="realm--loading-container">
                <LoadingIndicator />
              </div>
            ) : (
              this.renderView()
            )}
            {showPagination && (
              <Pagination
                currentPageColor="primary"
                disabled={this.state.isLoading}
                style={{ marginTop: 30, marginBottom: 30, textAlign: 'center' }}
                limit={this.state.getFilterParams.limit}
                offset={this.state.offset}
                total={this.state.totalRecords}
                onClick={(e, offset) => this.nextPage(offset)}
                otherPageColor="default"
              />
            )}
            <UploadClients
              agentId={this.props.session.agentData ? this.props.session.agentData._id : undefined}
              isVisible={this.state.showUploadDialog}
              forName="me"
              handleClose={this.handleClose}
              onRefresh={this.uploadClients}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

Clients.propTypes = {
  application: PropTypes.object,
  session: PropTypes.object
};

export default Clients;
