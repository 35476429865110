import React from 'react';
import { Link } from 'react-router-dom';
import ContactUs from '../Dialogs/ContactUs';
import localstring from '../../services/Localization';

const Footer = () => (
  <footer className="realm--main-footer">
    <div className="realm--main-footer-wrapper">
      <div className="realm--main-footer-links">
        <Link to="/company?page=terms">{localstring.terms}</Link>
        <Link to="/company?page=privacy">
          {localstring.privacy_policy}
        </Link>
        <Link to="/company?page=confidentiality">
          {localstring.confidentiality}
        </Link>
        <Link to="/company?page=cookies-policy">
          {localstring.cookies}
        </Link>
        <ContactUs showText />
      </div>
      <div className="realm--copyright">&#169; {localstring.footer_copy1} {(new Date()).getFullYear()}, {localstring.footer_copy2}</div>
    </div>
  </footer>
);

export default Footer;
