import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core';
import { PieChart, Pie, Cell } from 'recharts';

const styles = {
  barArea: {
    marginLeft: 0
  },
  bar: {
    height: 16
  },
  topBar: {
    color: '#fff',
    display: 'inline-block',
    fontSize: 11,
    height: 14,
    marginLeft: 8,
    width: 100,
    background: '#267b26'
  },
  middleBar: {
    color: '#fff',
    display: 'inline-block',
    fontSize: 11,
    height: 14,
    marginLeft: 8,
    width: 100,
    background: '#b3893c'
  },
  bottomBar: {
    color: '#fff',
    display: 'inline-block',
    fontSize: 11,
    height: 14,
    marginLeft: 8,
    width: 100,
    background: '#867b39'
  },
  barNumber: {
    display: 'inline-block',
    textAlign: 'right',
    width: 30,
    marginRight: 4
  },
  smallBar: {
    height: 11,
    marginLeft: 4
  },
  smallNumber: {
    fontSize: 11
  },
  pieTotal: {
    textAlign: 'center'
  }
};

const PIE_COLORS = ['#86bb86', '#e2b055', '#ecd85d', '#ac5f0f'];

class MatchChart extends Component {
  state = {
    high: 0,
    med: 0,
    low: 0,
    total: 0,
    wHigh: 100,
    wMed: 100,
    wLow: 100,
    isSmall: false,
    useBars: true
  };

  componentWillMount() {
    const {
      high,
      low,
      med,
      small,
      usePie
    } = this.props;
    const useBars = !usePie;
    let total = high + med + low;
    if (total === 0) {
      total = 1;
    }
    const pieData = [
      { name: 'Highly Matched', value: high },
      { name: 'Moderately Matched', value: med },
      { name: 'Near Matches', value: low }
    ];

    this.setState({
      high,
      med,
      low,
      total,
      wHigh: Math.floor((high * 100) / total),
      wMed: Math.floor((med * 100) / total),
      wLow: Math.floor((low * 100) / total),
      isSmall: small,
      useBars,
      pieData
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <span>
        {this.state.useBars ? (
          <div className={classes.barArea}>
            <div className={classes.bar}>
              <div
                className={classNames(
                  classes.barNumber,
                  this.state.isSmall ? classes.smallNumber : ''
                )}
              >
                {this.state.high}
              </div>
              <div
                className={classNames(
                  classes.topBar,
                  this.state.isSmall ? classes.smallBar : ''
                )}
                style={{ width: this.state.wHigh }}
              />
            </div>
            <div className={classes.bar}>
              <div
                className={classNames(
                  classes.barNumber,
                  this.state.isSmall ? classes.smallNumber : ''
                )}
              >
                {this.state.med}
              </div>
              <div
                className={classNames(
                  classes.middleBar,
                  this.state.isSmall ? classes.smallBar : ''
                )}
                style={{ width: this.state.wMed }}
              />
            </div>
            <div className={classes.bar}>
              <div
                className={classNames(
                  classes.barNumber,
                  this.state.isSmall ? classes.smallNumber : ''
                )}
              >
                {this.state.low}
              </div>
              <div
                className={classNames(
                  classes.bottomBar,
                  this.state.isSmall ? classes.smallBar : ''
                )}
                style={{ width: this.state.wLow }}
              />
            </div>
          </div>
        ) : (
          <div className="realm--house-card-pie-bar-area">
            <div className={classes.pieTotal}>{this.state.total} Matches</div>
            {this.state.pieData && (
              <PieChart width={100} height={40}>
                <Pie
                  cx={50}
                  cy={32}
                  data={this.state.pieData}
                  dataKey="value"
                  endAngle={0}
                  fill="#666"
                  innerRadius={15}
                  outerRadius={35}
                  startAngle={180}
                  stroke="#343548"
                >
                  {this.state.pieData.map((entry, index) => (
                    <Cell key={`piechart-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            )}
          </div>
        )}
      </span>
    );
  }
}

MatchChart.propTypes = {
  classes: PropTypes.object,
  high: PropTypes.number,
  low: PropTypes.number,
  med: PropTypes.number,
  small: PropTypes.string,
  usePie: PropTypes.string
};

export default withStyles(styles)(MatchChart);
