/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import api from '../../../api';
import genericAvatar from '../../../images/img/avatar-portrait.png';

import Delete from '../../../components/Users/Delete';
import Edit from '../../../components/Users/Edit';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PageTitle from '../../../components/PageTitle';
import RegisterAdmin from '../../../components/Users/RegisterAdmin';

class Admins extends Component {
  state = {
    users: [],
    loading: false,
    isVisible: false
  };

  componentDidMount() {
    this.getAdmins();
  }

  getAdmins = async () => {
    this.setState({ loading: true });
    const response = await api.get('/admin/admins', {
      params: {}
    });
    this.setState({ users: response.data.data });
    this.setState({ loading: false });
  };

  createAdmin = async () => {
    this.setState({ isVisible: true });
  };

  handleClose = () => {
    this.setState({ isVisible: false });
  };

  showAvatar(user) {
    let avatar = genericAvatar;
    if (user.person.realmData && user.person.realmData.photoUrl) {
      avatar = user.person.realmData.photoUrl;
    } else {
      if (user.person.realmData) {
        avatar = null;
      }
    }
    return avatar;
  }

  renderRow(row) {
    return (
      <span>
        <Edit userId={row.row._id} user={row.row} onRefresh={this.getAdmins} />{' '}
        |{' '}
        <Delete
          userId={row.row._id}
          user={row.row}
          onRefresh={this.getAdmins}
        />{' '}
        | <a href="#">Reset PW</a>
      </span>
    );
  }

  render() {
    const { users, isVisible } = this.state;
    return (
      <div className="page-with-title">
        <PageTitle
          title="Administrators"
          addPlus
          handleClick={this.createAdmin}
        />
        <br />
        {this.state.loading ? <LoadingIndicator /> : <span />}
        <ReactTable
          defaultSorted={[
            {
              id: 'firstName',
              desc: false
            }
          ]}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          data={users}
          columns={[
            {
              id: 'photo',
              Header: '',
              accessor: 'photoUrl',
              width: 50,
              filterable: false,
              maxWidth: 50,
              Cell: (row) => (
                <div className="tinyAvatarCircle">
                  <img
                    src={this.showAvatar(row.original)}
                    alt={'account / sign out'}
                  />
                </div>
              )
            },
            {
              id: 'firstName',
              Header: 'First Name',
              accessor: 'firstName',
              width: 100,
              filterMethod: (filter, row) => {
                let name = `${row._original.firstName}`;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.startsWith(match);
              }
            },
            {
              Header: 'Last Name',
              accessor: 'lastName',
              width: 120,
              filterMethod: (filter, row) => {
                let name = `${row._original.lastName}`;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.startsWith(match);
              }
            },
            {
              Header: 'Email',
              accessor: 'email',
              filterMethod: (filter, row) => {
                let name = `${row._original.email}`;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.startsWith(match);
              }
            },
            {
              Header: '_id',
              accessor: '_id',
              filterable: false
            },
            {
              Header: 'Pool Id',
              accessor: 'userPoolId',
              filterable: false
            },
            {
              Header: 'Actions',
              filterable: false,
              show: this.props.session.isAdmin,
              Cell: (row) => this.renderRow(row)
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        <RegisterAdmin
          isVisible={isVisible}
          handleClose={this.handleClose}
          onRefresh={this.getAdmins}
        />
        <br />
      </div>
    );
  }
}

Admins.propTypes = {
  session: PropTypes.object
};

export default Admins;
