import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SelectableTag extends Component {
  state = {
    checked: this.props.tag.checked
  };

  handleChange = (e) => {
    this.setState({
      checked: e.target.checked
    });
    this.props.changeHandler(e);
  }

  render() {
    const { tag } = this.props;
    return (
      <div key={tag.id} className="realm--tag-list-selectable-tag">
        <input
          checked={this.state.checked}
          name={tag.tagName}
          onChange={this.handleChange}
          type="checkbox"
          id={tag.id}
        />
        <label htmlFor={tag.id}>{tag.label}</label>
      </div>
    );
  }
}

SelectableTag.propTypes = {
  changeHandler: PropTypes.func,
  tag: PropTypes.object
};

export default SelectableTag;
