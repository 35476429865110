import createReducer from './create-reducer';
import * as storage from '../persistence/storage';
import * as constants from '../constants';
import getRoles from '../utils/get-roles';

// The initial state of the App
const initialState = {
  acceptCookies: JSON.parse(storage.get('acceptCookies')) || false,
  agentData: JSON.parse(storage.get('agentData')) || null,
  apiError: false,
  authenticationFailed: false,
  avatar: storage.get('avatar') || '',
  brokerages: JSON.parse(storage.get('brokerages')) || null,
  currentPerson: JSON.parse(storage.get('person')) || null,
  currentUser: JSON.parse(storage.get('me')) || null,
  errorMessage: '',
  isAdmin: Boolean(storage.get('isAdmin')) || false,
  isAgent: Boolean(storage.get('isAgent')) || false,
  isAuthenticated: Boolean(storage.get('token')) || false,
  isConcierge: Boolean(storage.get('isConcierge')) || false,
  isDemo: Boolean(storage.get('isDemo')) || false,
  isOnboarding: Boolean(storage.get('isOnboarding')) || null,
  isRealmAdmin: Boolean(storage.get('isRealmAdmin')) || false,
  loading: false,
  token: storage.get('token') || '',
  totalBrokerages: Number.parseInt(storage.get('totalBrokerages'), 10) || 0
};

const checkForOnboarding = (payload) => {
  const { person, roles } = payload;
  if (!person || !roles) {
    return false;
  }
  const isAgent = roles.includes('agent');
  const hasUserAccount = typeof person.realmData !== 'undefined';
  if (
    hasUserAccount &&
    isAgent &&
    (typeof person.realmData.userAccount === 'undefined' ||
      typeof person.realmData.userAccount.onBoardedAgentAt === 'undefined')
  ) {
    storage.put('isOnboarding', 'true');
    return true;
  }
  return false;
};

const actionHandlers = {
  [constants.ACCEPT_COOKIES]: () => ({ acceptCookies: true }),
  [constants.AUTHENTICATE_PENDING]: () => ({ loading: true }),
  [constants.AUTHENTICATE_SUCCESS]: (_, action) => ({
    currentUser: action.payload.user,
    isAuthenticated: true,
    isAdmin: getRoles(action.payload, 'admin'),
    isAgent: getRoles(action.payload, 'agent'),
    isConcierge: getRoles(action.payload, 'concierge'),
    isDemo: getRoles(action.payload, 'demo'),
    isRealmAdmin: getRoles(action.payload, 'realmAdmin'),
    loading: false,
    token: action.payload.token
  }),
  [constants.AUTHENTICATE_REJECTED]: (_, action) => ({
    authenticationFailed: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [constants.AUTHENTICATE_RETRY]: () => ({
    authenticationFailed: false,
    errorMessage: ''
  }),
  [constants.CLEAR_SESSION]: () => ({
    agentData: null,
    apiError: false,
    authenticationFailed: false,
    brokerages: null,
    currentPerson: null,
    currentUser: null,
    errorMessage: '',
    isAdmin: false,
    isAgent: false,
    isAuthenticated: false,
    isDemo: false,
    isOnboarding: false,
    isRealmAdmin: false,
    loading: false,
    token: ''
  }),
  [constants.FETCH_AGENT_PENDING]: () => ({ loading: true, loaded: false }),
  [constants.FETCH_AGENT_SUCCESS]: (state, action) => ({
    agentData: action.payload,
    apiError: false,
    avatar: state.avatar ? state.avatar : action.payload.photoURL,
    loading: false
  }),
  [constants.FETCH_AGENT_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [constants.FETCH_BROKERAGES_PENDING]: () => ({ loading: true, loaded: false }),
  [constants.FETCH_BROKERAGES_SUCCESS]: (state, action) => ({
    brokerages: action.payload.brokerages,
    totalBrokerages: action.payload.totalBrokerages,
    apiError: false,
    loading: false
  }),
  [constants.FETCH_BROKERAGES_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [constants.FETCH_PERSON_PENDING]: () => ({ loading: true, loaded: false }),
  [constants.FETCH_PERSON_SUCCESS]: (state, action) => ({
    apiError: false,
    avatar:
      action.payload.person.realmData &&
      action.payload.person.realmData.photoUrl
        ? action.payload.person.realmData.photoUrl
        : '',
    currentPerson: action.payload.person,
    isOnboarding: checkForOnboarding(action.payload),
    loading: false
  }),
  [constants.FETCH_PERSON_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [constants.UPDATE_PERSON_SUCCESS]: (state, action) => ({
    currentPerson: action.payload
  }),
  [constants.COMPLETE_ONBOARDING]: () => ({ isOnboarding: false }),
};

export default createReducer(initialState, actionHandlers);
