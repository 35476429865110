import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from 'lodash.isfunction';

import { Grid, withStyles } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import api from '../../api';
import genericHouse from '../../images/img/estateblur2.jpg';
import numberWithCommas from '../../utils/number-with-commas';
import styles from './styles';

import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';
import ProfilePicture from '../../components/ProfilePicture';
import TagList from '../../components/Tags/TagList';

class MatchAlertsDetail extends Component {
  constructor(props) {
    super(props);
    const lid = props.location.search.split('=')[1];
    this.state = {
      agent: null,
      agentPhotoURL: '',
      property: {},
      loading: false,
      messageId: lid,
      title: '',
      price: '',
      photoURL: null,
      myClients: [],
    };
  }

  componentWillMount() {
    this.loadProperty();
  }

  loadProperty = async () => {
    this.setState({ loading: true });
    const matchResponse = await api.get(
      `/match/getAlert/${this.state.messageId}`
    );
    const { data } = matchResponse.data.data;
    this.setState({
      agentPhotoURL: data.fromAgent.photoURL,
      agent: data.fromAgent,
      myClients: data.clientDetails
    });
    const propertyResponse = await api.get(
      `/listings/${data.listing._id}`
    );
    const property = propertyResponse.data.data.data;
    this.setState({
      photoURL:
        property.pictures && property.pictures.length > 0
          ? property.pictures[0].pictureUrl
          : genericHouse,
      property
    });
    if (property.title && property.title.en) {
      this.setState({
        title: property.title.en
      });
      if (property.price) {
        this.setState({ price: ` | Listed at: $${property.price.amount}` });
      }
    } else {
      this.setState({ title: property.fullAddress });
      if (property.price) {
        this.setState({ price: `Listed at: $${property.price.amount}` });
      }
    }
    this.setState({ loading: false }, () => {
      const el = document.getElementById('page-wrapper');
      if (el && isFunction(el.scrollTo)) {
        el.scrollTo(0, 0);
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { agent, agentPhotoURL } = this.state;
    return (
      <div className="page-with-title">
        <PageTitle title="Return to Matching Alerts" backTo="/match-alerts" />
        {this.state.loading ? (
          <div className="realm--loading-container">
            <LoadingIndicator />
          </div>
        ) : (
          <Fragment>
            <div className="realm--matching-details" id="container">
              <div
                className={classNames(
                  'realm--details-background',
                  { photoLoaded: this.state.photoURL },
                  classes.aboveFold
                )}
                style={
                  this.state.photoURL
                    ? {
                      background: `linear-gradient(rgba(0,0,0,0), transparent, transparent), url(${
                        this.state.photoURL
                      }) center center`
                    }
                    : {}
                }
              />
              <div className="realm--shadow" />
              <div className="realm--grid-container header">
                <div className="realm--matching-title">
                  Matches for: {this.state.title}
                </div>
                <div className="realm--listed-at">
                  {numberWithCommas(this.state.price)}
                </div>
              </div>

              <div className="realm--grid-container">
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      style={{
                        background: 'rgba(0,0,0,.65)',
                        borderRadius: 4,
                        padding: 20,
                      }}
                    >
                      <h4 className="text-centered">This listing is from:</h4>
                      <ProfilePicture
                        centered
                        fullName={agent.person.name.fullName}
                        photoURL={agentPhotoURL}
                      />
                      <h2 className="text-centered">{agent.person.name.fullName}</h2>
                      {agent.brokerages.length && <p className="text-centered">{agent.brokerages[0].name}</p>}
                      <p className="text-centered">
                        {agent.person.emails.map((email) => {
                          if (email.address.trim() !== '') {
                            return (
                              <span
                                style={{
                                  display: 'block',
                                  marginBottom: 5
                                }}
                                key={`email-${email.address}-{id}`}
                              >
                                <a href={`mailto: ${email.address}`}>
                                  <EmailIcon
                                    style={{ fontSize: 18, marginRight: 6 }}
                                  />
                                  {`Email: ${email.address}`}
                                </a>
                              </span>
                            );
                          }
                          return null;
                        })}
                      </p>
                      <p className="text-centered">
                        {agent.person.phones.map((phone) => {
                          if (phone.number && phone.number.trim() !== '') {
                            return (
                              <span
                                style={{
                                  display: 'block',
                                  marginBottom: 5
                                }}
                                key={`phone-${agent.person._id}-${
                                  phone.phoneType
                                }-${phone.number}`}
                              >
                                <PhoneIcon
                                  style={{ fontSize: 18, marginRight: 6 }}
                                />{' '}
                                {phone.phoneType}: {phone.number}
                              </span>
                            );
                          }
                          return null;
                        })}
                      </p>
                    </div>

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="realm--client-agent-container">
                      <span style={{ display: 'block', fontSize: 18, margin: 0 }}>You have</span>
                      <span style={{ display: 'block', fontSize: 60, margin: 0 }}>{this.state.myClients.length}</span>
                      <span style={{ display: 'block', fontSize: 18, margin: 0 }}>clients that were matched<br /> to this property</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} className="text-centered">
                    <div className="realm--tag-container">
                      <h4>Top Matching Qualities</h4>
                      <div style={{ maxHeight: 290 }}>
                        <TagList
                          format="default"
                          id={this.state.property._id}
                          name={this.state.title}
                          type="listings"
                          readonly
                          maxHeight={190}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            {this.state.myClients.length > 0 && (
              <div className="realm--agents-container">
                <h4>My Clients That Match This Listing</h4>
                <br />
                <Grid
                  container
                  spacing={16}
                  style={{
                    display: 'flex'
                  }}
                >
                  {this.state.myClients.map((c) => {
                    const { client } = c;
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={client._id}
                        style={{
                          display: 'flex',
                          width: '100%',
                          minHeight: 100
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 20,
                            fontSize: 12,
                            background: 'rgba(255,255,255,.1)',
                            borderRadius: 4
                          }}
                        >
                          <h4 style={{ fontSize: 18, marginBottom: 10 }}>
                            {client.name.fullName}
                          </h4>
                          <p>
                            {client.emails.map((email) => {
                              if (email.address.trim() !== '') {
                                return (
                                  <span
                                    style={{
                                      display: 'block',
                                      marginBottom: 5
                                    }}
                                    key={`email-${email.address}-{id}`}
                                  >
                                    <a href={`mailto: ${email.address}`}>
                                      <EmailIcon
                                        style={{ fontSize: 18, marginRight: 6 }}
                                      />
                                      {`Email: ${email.address}`}
                                    </a>
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </p>
                          <p>
                            {client.phones.map((phone) => {
                              if (phone.number && phone.number.trim() !== '') {
                                return (
                                  <span
                                    style={{
                                      display: 'block',
                                      marginBottom: 5
                                    }}
                                    key={`phone-${client._id}-${
                                      phone.phoneType
                                    }-${phone.number}`}
                                  >
                                    <PhoneIcon
                                      style={{ fontSize: 18, marginRight: 6 }}
                                    />{' '}
                                    {phone.phoneType}: {phone.number}
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </p>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

MatchAlertsDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MatchAlertsDetail);
