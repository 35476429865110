import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash.merge';

import { Button, Grid } from '@material-ui/core';

import api from '../../../api';
import getParameterByName from '../../../utils/get-parameter-by-name';
import localization from '../../../services/Localization';
import { API_ROOT } from '../../../constants';

import ChangePassword from '../../../components/Dialogs/ChangePassword';
import EditField from '../../Onboarding/components/EditField';
import ErrorDialog from '../../../components/Dialogs/ErrorDialog';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PageTitle from '../../../components/PageTitle';
import ProfilePicture from '../../../components/ProfilePicture';
import ResetPassword from '../../../components/Dialogs/ResetPasswordRequest';
import SuccessDialog from '../../../components/Dialogs/SuccessDialog';
import UploadPhoto from '../../../components/Upload/UploadPhoto';

class MyAccount extends Component {
  constructor(props) {
    super(props);
    const {
      currentPerson: { emails, phones, realmData }
    } = props.session;
    const incomingID = getParameterByName('id');
    this.state = {
      avatar: null,
      loadingUser: !!incomingID,
      agentID: incomingID || null,
      emails,
      phones,
      currentPerson: this.props.session.currentPerson,
      socialAccounts: realmData.socialAccounts || [],
      // preferredContact: [],
      attemptRetry: false,
      errorMessage: '',
      isDirty: false,
      isError: false,
      isMe:
        props.session.isAdmin ||
        (!incomingID && props.session.currentPerson) ||
        (props.session.agentData && props.session.agentData._id === incomingID),
      showStepRequestNewPassword: false,
      showStepChangePassword: false,
      showPasswordRequestSuccess: false,
      showSaveSuccessful: false
    };
  }

  _isMounted = false;

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    if (this.state.agentID) {
      this.getAgentInformation();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /* manage state */

  handleEmailState = (e) => {
    this.checkForDirtyForm();
    const { name, value } = e.target;
    const { emails } = this.state;
    const newObj = {
      emailType: name,
      address: value
    };
    const newState = [];
    const existingKeys = emails.map((obj) => obj.emailType);
    if (!existingKeys.includes(name)) {
      newState.push(newObj);
    }
    emails.length > 0 &&
      emails.map((obj) => {
        if (obj.emailType === newObj.emailType) {
          newState.push(newObj);
        } else {
          newState.push(obj);
        }
        return null;
      });
    this.setState({ emails: newState });
    return null;
  };

  handlePhoneState = (e) => {
    this.checkForDirtyForm(e);
    const { name, value } = e.target;
    const { phones } = this.state;
    const newObj = {
      phoneType: name,
      number: value,
      extension: '',
      isPersonal: false,
      preferred: false
    };
    const newState = [];
    const existingKeys = phones.map((obj) => obj.phoneType);
    if (!existingKeys.includes(name)) {
      newState.push(newObj);
    }
    phones.length > 0 &&
      phones.map((obj) => {
        if (obj.phoneType === newObj.phoneType) {
          newState.push(newObj);
        } else {
          newState.push(obj);
        }
        return null;
      });
    this.setState({ phones: newState });
    return null;
  };

  handleSocialAccountState = (e) => {
    this.checkForDirtyForm();
    const { name, value } = e.target;
    const { socialAccounts } = this.state;
    const newObj = { socialType: name, url: value };
    const newState = [];
    const existingKeys = socialAccounts.map((obj) => obj.socialType);
    if (!existingKeys.includes(name)) {
      newState.push(newObj);
    }
    socialAccounts.length > 0 &&
      socialAccounts.map((obj) => {
        if (obj.socialType === newObj.socialType) {
          newState.push(newObj);
        } else {
          newState.push(obj);
        }
        return null;
      });
    this.setState({ socialAccounts: newState });
    return null;
  };

  getSocialValues = () => {
    const { socialAccounts } = this.state;
    const obj = {};
    socialAccounts.map((a) => {
      obj[a.socialType] = a.url;
      return null;
    });
    return obj;
  };

  getPhoneType = (type) => {
    const { phones } = this.state;
    let value = '';
    phones.map((a) => {
      if (a.phoneType === type) {
        value = a.number;
      }
      return null;
    });
    return value;
  }

  getCleanedEmails = () =>
    this.state.emails.map((e) => ({
      address: e.address
    }));

  checkForDirtyForm = () => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
  };

  /* update current user */
  updatePerson = async () => {
    const { currentPerson } = this.props.session;
    const updatedPerson = merge(currentPerson, {
      emails: this.state.emails,
      phones: this.state.phones,
      realmData: {
        socialAccounts: this.state.socialAccounts
      }
    });
    await this.props.actions.updatePerson({
      realmData: updatedPerson.realmData
    });
    this.setState({
      isDirty: false
    });
    this.showSuccessDialog();
  };

  /* update agent */
  getAgentInformation = async () => {
    const response = await api.get(`${API_ROOT}/agents/${this.state.agentID}`);
    const currentPerson = response.data.data.data;
    this.setState({
      avatar: currentPerson.realmData.photoUrl || 'default',
      currentPerson: currentPerson.person,
      emails: currentPerson.person.emails,
      phones: currentPerson.person.phones,
      socialAccounts: currentPerson.person.realmData.socialAccounts || [],
      loadingUser: false
    });
  };

  updateRecord = async () => {
    const {
      currentPerson,
      isMe,
      phones
    } = this.state;
    const realmData = Object.assign({}, currentPerson.realmData, { socialAccounts: this.state.socialAccounts });
    const updatedPerson = Object.assign({}, currentPerson, {
      emails: this.getCleanedEmails(),
      phones,
      realmData
    });
    if (!isMe) {
      try {
        await this.props.actions.updatePerson(updatedPerson);
      } catch (e) {
        this.setState({
          errorMessage:
            'Your changes failed to update. Please contact REALM support for further assistance.',
          isError: true
        });
      }
    } else {
      api
        .put(`${API_ROOT}/people/${updatedPerson._id}`, updatedPerson)
        .then(() => {
          this.setState({
            isDirty: false
          });
          this.showSuccessDialog();
        })
        .catch(() => {
          this.setState({
            errorMessage:
              'Your changes failed to update. Please contact REALM support for further assistance.',
            isError: true
          });
        });
    }
  };

  /* dialog handling */
  showSuccessDialog = () => {
    if (this._isMounted) {
      this.setState({ showSaveSuccessful: true }, () => {
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({ showSaveSuccessful: false });
          }
        }, 1500);
      });
    }
  };

  handleCloseUpload = (uploadSuccessful) => {
    if (uploadSuccessful) {
      this.props.actions.getPersonData();
      this.props.actions.getAgentData();
    }
  };

  handleClose = () => {
    this.setState({
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      showStepRequestNewPassword: false,
      showStepChangePassword: false,
      showPasswordRequestSuccess: false
    });
  };

  /* reset password stuff */
  goToLogin = () => {
    this.props.history.push('/logout');
  };

  requestResetPassword = () => {
    this.setState({
      showStepRequestNewPassword: true
    });
  };

  cancelResetPassword = () => {
    this.setState({
      showStepRequestNewPassword: false
    });
  };

  resetPassword = async (emailAddress) => {
    this.setState({
      showStepRequestNewPassword: true
    });
    try {
      const response = await api.post('/user/reset-password', {
        username: emailAddress
      });
      if (response.status === 200) {
        this.setState({
          emailAddress,
          showStepRequestNewPassword: false,
          showStepChangePassword: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepRequestNewPassword: false
      });
    }
  };

  changePassword = async (authObj) => {
    if (!authObj.password || !authObj.verificationCode) {
      return null;
    }
    try {
      const response = await api.post('/user/confirm-password', {
        code: authObj.verificationCode,
        password: authObj.password,
        username: this.state.emailAddress
      });
      if (response.status === 200) {
        this.setState({
          showPasswordRequestSuccess: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepChangePassword: false,
        attemptRetry: true
      });
    }
    return null;
  };

  render() {
    if (this.state.loadingUser) {
      return (
        <div className="realm--loading-container">
          <LoadingIndicator />
        </div>
      );
    }
    const { session } = this.props;
    const avatar = this.state.isMe ? session.avatar : this.state.avatar;
    const currentPerson = this.state.isMe ? session.currentPerson : this.state.currentPerson;
    const { primaryEmail, emails } = currentPerson;
    const { firstName, lastName } = currentPerson.name;
    const { agentID } = this.state;
    const { onboarding: lang } = localization;
    const social = this.getSocialValues();
    return (
      <div className="page-with-title">
        <PageTitle
          backTo={agentID ? `/agent-profile?id=${agentID}` : undefined}
          title={agentID ? 'Back to Profile' : 'My Account'}
        />
        <div className="realm--my-account">
          <Fragment>
            <div className="realm--my-account-header">
              <h1 className="text-centered" style={{ marginBottom: 10 }}>
                {`${firstName} ${lastName}`}
              </h1>
              <div
                className="text-centered"
                style={{ fontSize: 14, color: '#ccc', marginTop: 8 }}
              >
                <em>
                  {localization.login_username}: {primaryEmail}
                </em>
              </div>
              <ProfilePicture
                fullName={`${firstName} ${lastName}`}
                photoURL={avatar || undefined}
                style={{ margin: '20px auto' }}
              />
              <div style={{ marginBottom: 10 }}>
                <UploadPhoto
                  block
                  forName={`${firstName} ${lastName}`}
                  handleClose={this.handleCloseUpload}
                  hasPhoto={!!avatar || undefined}
                />
              </div>
              <div>
                <ResetPassword
                  block
                  handleOpen={this.requestResetPassword}
                  handleClose={this.cancelResetPassword}
                  handleReset={this.resetPassword}
                  open={this.state.showStepRequestNewPassword}
                  showButton
                />
              </div>
            </div>
            <div className="realm--my-account-inputs">
              <div className="realm--my-account-row">
                <h4>{lang.account_form_contacts_title}</h4>
              </div>
              <div className="realm--my-account-container">
                <div className="realm--my-account-row vertical">
                  <div className="realm--my-account-column">
                    <EditField
                      change={this.handleEmailState}
                      className="left-aligned"
                      name="email1"
                      label="Email"
                      value={
                        typeof emails !== 'undefined' &&
                        typeof emails[0] !== 'undefined'
                          ? emails[0].address || ''
                          : ''
                      }
                      placeholder="Enter your email address"
                    />
                    <EditField
                      change={this.handleEmailState}
                      className="left-aligned"
                      name="email2"
                      label="Alternative Email"
                      value={
                        typeof emails !== 'undefined' &&
                        typeof emails[1] !== 'undefined'
                          ? emails[1].address || ''
                          : ''
                      }
                      placeholder="Enter another email address (optional)"
                    />
                    <EditField
                      change={this.handleEmailState}
                      className="left-aligned"
                      name="email3"
                      label="Alternative Email"
                      value={
                        typeof emails !== 'undefined' &&
                        typeof emails[2] !== 'undefined'
                          ? emails[2].address || ''
                          : ''
                      }
                      placeholder="Enter another email address (optional)"
                    />
                  </div>
                </div>
                <div className="realm--my-account-row vertical">
                  <div className="realm--my-account-column">
                    <EditField
                      change={this.handlePhoneState}
                      className="left-aligned"
                      format="+1 (###) ###-####"
                      mask="_"
                      name="Office"
                      label="Work Phone"
                      value={this.getPhoneType('Office')}
                      placeholder="Enter your work phone number"
                    />
                    <EditField
                      change={this.handlePhoneState}
                      className="left-aligned"
                      format="+1 (###) ###-####"
                      mask="_"
                      name="Home"
                      label="Home Phone"
                      value={this.getPhoneType('Home')}
                      placeholder="Enter your home phone number (optional)"
                    />
                    <EditField
                      change={this.handlePhoneState}
                      className="left-aligned"
                      format="+1 (###) ###-####"
                      mask="_"
                      name="Mobile"
                      label="Mobile Phone"
                      value={this.getPhoneType('Mobile')}
                      placeholder="Enter an mobile phone number (optional)"
                    />
                  </div>
                </div>
              </div>
              <div className="realm--my-account-row">
                <h4>{lang.account_form_social_title}</h4>
                <div className="realm--my-account-column">
                  <EditField
                    change={this.handleSocialAccountState}
                    className="left-aligned"
                    name="facebook"
                    label="Facebook"
                    value={social.facebook || ''}
                    placeholder="Enter the URL for your Facebook account"
                  />
                  <EditField
                    change={this.handleSocialAccountState}
                    className="left-aligned"
                    name="instagram"
                    label="Instagram"
                    value={social.instagram || ''}
                    placeholder="Enter the URL for your Instagram account"
                  />
                  <EditField
                    change={this.handleSocialAccountState}
                    className="left-aligned"
                    name="linkedin"
                    label="LinkedIn"
                    value={social.linkedin || ''}
                    placeholder="Enter the URL for your LinkedIn account"
                  />
                  <EditField
                    change={this.handleSocialAccountState}
                    className="left-aligned"
                    name="snapchat"
                    label="Snapchat"
                    value={social.snapchat || ''}
                    placeholder="Enter the URL for your Snapchat account"
                  />
                  <EditField
                    change={this.handleSocialAccountState}
                    className="left-aligned"
                    name="twitter"
                    label="Twitter"
                    value={social.twitter || ''}
                    placeholder="Enter the URL for your Twitter account"
                  />
                  <EditField
                    change={this.handleSocialAccountState}
                    className="left-aligned"
                    name="whatsapp"
                    label="WhatsApp"
                    value={social.whatsapp || ''}
                    placeholder="Enter the URL for your WhatsApp account"
                  />
                </div>
              </div>
            </div>
            <Grid container>
              <Button
                variant="contained"
                color="primary"
                disabled={!this.state.isDirty}
                onClick={this.updateRecord}
                style={{ display: 'block', width: 280, margin: '0 auto' }}
              >
                {lang.save}
              </Button>
            </Grid>
          </Fragment>
        </div>
        <ChangePassword
          handleReset={this.changePassword}
          isSuccess={this.state.showPasswordRequestSuccess}
          onSuccess={this.goToLogin}
          open={this.state.showStepChangePassword}
        />
        <SuccessDialog open={this.state.showSaveSuccessful} />
        <ErrorDialog
          handleClose={this.handleClose}
          handleRetry={
            this.state.attemptRetry ? this.onRetryPassword : undefined
          }
          message={this.state.errorMessage}
          open={this.state.isError}
        />
      </div>
    );
  }
}

MyAccount.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object,
  session: PropTypes.object
};

export default MyAccount;
