import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import localstring from '../../services/Localization';

import exampleWallImage from '../../images/img/exampleWall.jpg';
import realmR from '../../images/logos/REALM_white_R.png';

const styles = (theme) => ({
  postPanel: {
    textAlign: 'left',
    minHeight: 250,
    padding: 8,
    color: '#fff',
    fontWeight: 300,
    borderTop: '1px solid rgba(255,255,255,0.3)'
  },
  postImage: {
    fontSize: 50,
    fontWeight: 300,
    color: '#fff'
  },
  postTitle: {
    textAlign: 'right',
    paddingTop: 36,
    paddingRight: 32,
    paddingLeft: '30%',
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontFamily: '"Cormorant", serif',
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
    }
  },
  postAuthor: {
    fontSize: 12,
    lineHeight: '14px',
    letterSpacing: '0.075em',
    fontWeight: 300,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  postDate: {
    fontSize: 10,
  },
  postTopper: {
    marginTop: 8,
    marginBottom: 16
  },
  postText: {
    marginTop: 14,
    fontSize: 16,
    fontWeight: 300,
    letterSpacing: '0.075em',
    padding: 18,
    paddingTop: 0,
    color: '#ddd',
    '&:hover': {
      color: '#fff'
    }
  },
  postURL: {
    color: 'gray',
    '&:hover': {
      color: 'black'
    }
  },
  wallImage: {
    background: `url(${exampleWallImage}) center center`,
    height: 280,
    backgroundSize: 'auto 166% !important',
    backgroundRepeat: 'no-repeat !important',
    transition: 'background-size 0.5s ease',
    '&:hover': {
      backgroundSize: 'auto 170% !important',
      transition: 'background-size 0.5s ease',
      color: '#fff'
    },
  },
  authorAvatar: {
    borderRadius: 50,
    height: 42,
    width: 42,
    backgroundColor: '#9999b8',
    marginRight: 14,
    textAlign: 'center',
    paddingTop: 7,
    paddingLeft: 3,
    display: 'inline-block'
  }
});

class DashPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
      author: 'From the Realm Team',
      date: '',
      image: '',
      alt: '',
      title: '',
      slug: ''
    };
  }


  componentWillMount() {
    const { props } = this;

    let postDate = props.post.date; // 	"date": "2018-09-14T12:23:21",
    postDate = postDate.substring(5, 7) + '.' + postDate.substring(8, 10) + '.' + postDate.substring(2, 4);

    this.setState({
      post: props.post,
      author: props.author,
      date: postDate,
      image: props.post.fifu_image_url,
      alt: props.post.fifu_image_alt,
      title: this.getBlogName(props.post),
      slug: props.post.slug
    });
  }

  getBlogName(post) {
    let name = post.title.rendered;
    const firstBrace = name.indexOf('{') + 1;
    const lastBrace = name.lastIndexOf('}');
    name = name.substring(firstBrace, lastBrace);
    return (name);
  }

  createMarkup(content) {
    const morePos = content.indexOf('<p class="link-more"');
    const excerptOnly = content.substring(0, morePos-5) + `<i>${localstring.more}</i></p>`;
    return { __html: excerptOnly };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.postPanel}>
          <div className={classes.postTopper}>
            <div className={classes.authorAvatar}>
              <img src={realmR} style={{ width: '16px' }} alt={'realm avatar'} />
            </div>
            <div className={classes.postAuthor}>
              {this.state.author}
              <br />
              <span className={classes.postDate}>
                {this.state.date}
              </span>
            </div>
          </div>

          <Link to={`/blogpost?name=${this.state.slug}`}>
            <div className={classes.wallImage} alt={this.state.alt}
              style={(this.state.image ? { background: `linear-gradient(#000, transparent, transparent), url(${this.state.image}) top center` } : {})}
            >
              <div className={classes.postTitle}>
                {this.state.title}
              </div>
            </div>
            <div className={classes.postText}
              dangerouslySetInnerHTML={this.createMarkup(this.state.post.excerpt.rendered)}
            />
          </Link>
        </div>
      </div>
    );
  }
}

DashPost.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(DashPost);
