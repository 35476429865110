import React from 'react';
import { StepLabel, withStyles } from '@material-ui/core';

const styles = () => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    alignItems: 'center',
    // '&$alternativeLabel': {
    //   flexDirection: 'column',
    // },
    '&$disabled': {
      cursor: 'default',
    },
  },
  /* Styles applied to the root element if `orientation="horiizontal". */
  horizontal: {},
  /* Styles applied to the root element if `orientation="vertical". */
  vertical: {},
  /* Styles applied to the `Typography` component which wraps `children`. */
  label: {
    color: '#c5c3bb',
    '&$active': {
      color: '#ac5f0f',
      fontWeight: 400,
    },
    '&$completed': {
      color: '#ac5f0f',
      fontWeight: 400,
    },
    '&$error': {
      color: 'red',
    },
  },
  /* Styles applied to the `Typography` component if `active={true}`. */
  active: {},
  /* Styles applied to the `Typography` component if `completed={true}`. */
  completed: {},
  /* Styles applied to the root element and `Typography` component if `error={true}`. */
  error: {},
  /* Styles applied to the root element and `Typography` component if `disabled={true}`. */
  disabled: {},
  /* Styles applied to the `icon` container element. */
  iconContainer: {
    display: 'flex',
    paddingRight: 8
  }
});

const RealmStepLabel = (props) => <StepLabel {...props} />;

RealmStepLabel.muiName = 'StepLabel';

export default withStyles(styles, { name: 'MuiStepLabel' })(RealmStepLabel);
