import { combineReducers } from 'redux';
import application from './application';
import session from './session';

const reducers = combineReducers({
  application,
  session
});

export default reducers;
