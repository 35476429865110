import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import genericAvatar from '../../images/img/avatar-portrait.png';

class ProfilePicture extends Component {
  state = {
    attemptImageReload: false
  };

  imageDidNotLoad = () => {
    if (!this.state.attemptImageReload) {
      this.setState({
        attemptImageReload: true
      });
    }
  };

  render() {
    const {
      fullName,
      photoURL,
      centered,
      ...rest
    } = this.props;
    const { attemptImageReload } = this.state;
    const classList = {
      [this.props.size]: this.props.size,
      centered
    };
    return (
      <div
        className={classNames('realm--profile-picture', classList)}
        {...rest}
      >
        <img
          alt={`${fullName}'s Realm profile`}
          src={photoURL && !attemptImageReload ? photoURL : genericAvatar}
          onError={this.imageDidNotLoad}
        />
      </div>
    );
  }
}

ProfilePicture.propTypes = {
  centered: PropTypes.bool,
  fullName: PropTypes.string,
  photoURL: PropTypes.string,
  size: PropTypes.oneOf(['md', 'sm', 'lg', 'xs'])
};

ProfilePicture.defaultProps = {
  size: 'md'
};

export default ProfilePicture;
