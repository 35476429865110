/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import merge from 'lodash.merge';
import isFunction from 'lodash.isfunction';
import Pagination from 'material-ui-flat-pagination';
import ReactTable from 'react-table';

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Switch
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReportIcon from '@material-ui/icons/Report';
import GestureIcon from '@material-ui/icons/Gesture';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import api from '../../api';
import getParameterByName from '../../utils/get-parameter-by-name';
import sortList from '../../utils/sort-list';
import { API_ROOT } from '../../constants';

import AddAgentDialog from '../../components/Dialogs/AddAgentDialog';
import AddListingDialog from '../../components/Dialogs/AddListingDialog';
import AgentClientCard from '../../components/AgentClientCard';
import BrokeragePicker from '../../components/Brokerages/BrokeragePicker';
import ErrorDialog from '../../components/Dialogs/ErrorDialog';
import LoadingIndicator from '../../components/LoadingIndicator';
import ProfilePicture from '../../components/ProfilePicture';
import Sorter from '../../components/Sorter';
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import Tag from '../../components/Tags/Tag';
import TagDialog from '../../components/Tags/TagDialog';
import TagList from '../../components/Tags/TagList';
import PageTitle from '../../components/PageTitle';
import UploadClients from '../../components/Upload/UploadClients';
import ViewToggle from '../../components/PageControls/ViewToggle';

class Agents extends Component {
  state = {
    activeAgentId: null,
    activeAgentBrokerage: null,
    data: [],
    offset: 0,
    totalRecords: 0,
    getFilterParams: {
      page: 1, // from 1 to n
      limit: 25, // max number of results to return
      filter: {}, // in case we want to call a specific {<field>:value} as if we're creating the mongo query
      sortBy: 'fullName', // column to sort by
      sortDir: 1, // direction: 1 ascending, -1 descending
      name: '', // name string to filter by
      brokerage: null, // brokerageID to filter by
      tags: [], // tags array to filter by
      upload: null, // upload status to filter by
      invite: null, // invite status to filter by
      searchName: '',
      showArchived: this.props.session.isAdmin
    },
    // addAgentError: false,
    // addAgentErrorMessage: '',
    errorMessage: '',
    gridView: false,
    // inviteAgentFormOpen: false,
    isAddingAgent: false,
    isError: false,
    isLoading: false,
    openListingDialog: false,
    showSaveSuccessful: false,
    tagKey: Date.now()
  };

  _isMounted = false;

  componentDidMount() {
    this.getAgents();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInviteAgent = async (agentData) => {
    const { person } = agentData;
    const inviteData = {
      firstName: person.name.firstName,
      lastName: person.name.lastName,
      email: person.primaryEmail,
      roles: ['agent'],
      person: person._id
    };

    let okToSend = true;
    //let okToSend = false;
    // if (inviteData.email.includes('+')) {
    //   okToSend = true;
    // }

    if (!okToSend) {
      alert(
        'During Development, you may not send invites to actual email addresses.  Please assign a betarealmuser+<initials>@gmail.com email address first.'
      );
      return;
    }

    // Invite (generate a user login) an existing agent as a Realm Admin
    this.setState({ isLoading: true });

    await api
      .post('/admin/register', inviteData)
      .then(() => {
        this.setState({ isLoading: false });
        alert(`User Invite successfully sent to: ${inviteData.email}`);
      })
      .catch((ex) => {
        this.setState({ isLoading: false });
        alert(ex.response.data.msg);
      });

    if (isFunction(this.props.onRefresh)) {
      this.props.onRefresh();
    }
    this.handleClose();
  };

  onResendInvite = async (agentData) => {
    const { person } = agentData;

    const inviteData = {
      email: person.primaryEmail
    };

    let okToSend = true;
    // let okToSend = false;
    // if (inviteData.email.includes('+')) {
    //   okToSend = true;
    // }

    if (!okToSend) {
      alert(
        'During Development, you may not send invites to actual email addresses.  Please assign a betarealmuser+<initials>@gmail.com email address first.'
      );
      return;
    }

    // Invite (generate a user login) an existing agent as a Realm Admin
    this.setState({ isLoading: true });

    await api
      .post('/admin/register/resend', inviteData)
      .then(() => {
        this.setState({ isLoading: false });
        alert(`User invite re-sent with new password to: ${inviteData.email}`);
      })
      .catch((ex) => {
        this.setState({ isLoading: false });
        alert(ex.response.data.msg);
      });

    if (isFunction(this.props.onRefresh)) {
      this.props.onRefresh();
    }
    this.handleClose();
  };

  getAgents = async (brokerage = null, sortOrder = null) => {
    this.setState({ isLoading: true });

    // Get Query Params for brokerage ... if any
    const queryFilter = this.props.location.search
      ? {
        brokerages: getParameterByName('brokerage')
      }
      : {};
    const filterParams = this.state.getFilterParams;
    filterParams.filter = queryFilter;
    if (brokerage) {
      if (brokerage === 'All') {
        filterParams.filter = {};
      } else {
        filterParams.filter.brokerages = brokerage;
      }
    } else {
      filterParams.filter = queryFilter;
    }
    if (sortOrder) {
      filterParams.sortDir = sortOrder;
    }
    try {
      const response = await api.post('/agents/filter', filterParams);
      const { data } = response.data.data;
      if (this._isMounted) {
        this.setState({
          data,
          isLoading: false,
          totalRecords: response.data.data.total,
          noRecordsFound: response.data.data.total === 0
        });
      }
    } catch (err) {
      this.setState({
        errorMessage: `There was an problem with the request: ${err}. Please contact REALM support if you continue to have problems.`,
        isError: true
      });
    }
  };

  reload = () => {
    this.getAgents();
  };

   // FILTERS
   handleShowArchived = () => {
    const { getFilterParams } = this.state;
    const newState = merge({}, this.state.getFilterParams, {
        showArchived: !this.state.getFilterParams.showArchived,
        page: 0
      });

    this.setState(
      {
        getFilterParams: newState,
      },
      () => {
        this.getAgents();
        }
    )
  }


  handleNewDescription = (agent) => (e) => {
    const text = e.target.value;
    agent.realmData.description = text;
  };

  saveNewDescription = (agent) => {
    api
      .put(`${API_ROOT}/agents/${agent._id}`, { realmData: agent.realmData })
      .then(() => {
        this.showSuccessDialog();
      })
      .catch(() => {
        this.setState({
          errorMessage:
            'Your changes failed to update. Please contact REALM support for further assistance.',
          isError: true
        });
      });
  };

  handleInviteEmailChange = (agent) => (e) => {
    const text = e.target.value;
    agent.person.emails[0].address = text;
    agent.person.primaryEmail = text;
  };

  saveInviteEmail = (agent) => {
    api
      .put(`${API_ROOT}/people/${agent.person._id}`, {
        emails: agent.person.emails,
        primaryEmail: agent.person.primaryEmail
      })
      .then(() => {
        this.showSuccessDialog();
      })
      .catch(() => {
        this.setState({
          errorMessage:
            'Your changes failed to update. Please contact REALM support for further assistance.',
          isError: true
        });
      });
  };

  // Returns the appropriate button for the Agent's User Account "Status" column - Invite, Pending, Accepted, Declined
  handleAccountStatus = (agentData) => {
    // We only need the person information
    const { person } = agentData;

    if (!person) {
      return null;
    }
    // If the userAccount status hasn't been set...then this agent has not been invited yet
    if (person.realmData.userAccount === undefined) {
      return (
        <Button
          color="default"
          fullWidth
          onClick={() => this.onInviteAgent(agentData)}
          size="small"
          variant="outlined"
        >
          Invite
        </Button>
      );
    }

    // Othewise...
    const dateFormat = { year: '2-digit', month: 'short', day: '2-digit' };

    switch (person.realmData.userAccount.status) {
      case 'Pending':
        return (
          <Button
            color="default"
            fullWidth
            variant="outlined"
            size="small"
            onClick={() => this.onResendInvite(agentData)}
          >
            Resend Invite
          </Button>
        );
      case 'DeclinedTerms':
        return (
          <span>
            <ReportIcon />{' '}
            {new Date(
              person.realmData.userAccount.declinedTermsAt
            ).toLocaleDateString(dateFormat)}
          </span>
        );
      case 'AcceptedTerms':
        return (
          <span>
            <GestureIcon />{' '}
            {new Date(
              person.realmData.userAccount.acceptedTermsAt
            ).toLocaleDateString(dateFormat)}
          </span>
        );
      case 'Activated':
        return (
          <span>
            <CheckIcon />{' '}
            {new Date(
              person.realmData.userAccount.activatedAt
            ).toLocaleDateString(dateFormat)}
          </span>
        );
      default:
        return (
          <Button
            color="default"
            fullWidth
            variant="outlined"
            size="small"
            onClick={() => this.onInviteAgent(agentData)}
          >
            Invite
          </Button>
        );
    }
  };

  // Get's client count from agent and status of CSV upload
  // NOTE: There will be no client count until an Agent is invited - This would need to be fixed at db migration
  handleClientStatus = (agentData) => {
    // TODO: Explain count and status
    const clientCount =
      agentData.realmData.clientCount === undefined
        ? 'N/A'
        : agentData.realmData.clientCount;
    const uploadStatus =
      agentData.realmData.clientUpload === undefined ? (
        '0'
      ) : (
          <Fragment>
            <a
              href={
                agentData.realmData.clientUpload === null
                  ? ''
                  : agentData.realmData.clientUpload.url
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadIcon />
            </a>
          </Fragment>
        );

    let name = 'Unknown';
    if (
      agentData.person &&
      agentData.person.name &&
      agentData.person.name.firstName
    ) {
      name = `${agentData.person.name.firstName} ${
        agentData.person.name.lastName
        }`;
    }
    return (
      <Fragment>
        {clientCount} {'/'} {uploadStatus}
        <UploadClients
          agentId={agentData._id}
          forName={name}
          handleClose={this.handleCloseUpload}
          showButton
          showIconButton
        />
      </Fragment>
    );
  };

  returnClientCount = (agentData) => {
    // TODO: Explain count and status
    const clientCount =
      agentData.realmData.clientCount === undefined
        ? 'N/A'
        : agentData.realmData.clientCount;
    const uploadStatus =
      agentData.realmData.clientUpload === undefined ? (
        '0'
      ) : (
          <Fragment>
            <a
              href={
                agentData.realmData.clientUpload === null
                  ? ''
                  : agentData.realmData.clientUpload.url
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadIcon />
            </a>
          </Fragment>
        );
    return (
      <Fragment>
        {clientCount} {'/'} {uploadStatus}
      </Fragment>
    );
  };

  showAgentProfile = (agent) => {
    window.location.replace(`/agent-profile?id=${agent._id}`);
  };

  // PAGINATION
  nextPage = (offset) => {
    const { limit } = this.state.getFilterParams;
    const newState = merge({}, this.state.getFilterParams, {
      page: offset / limit + 1
    });
    this.setState({ offset, getFilterParams: newState }, () => {
      this.getAgents();
    });
  };

  // FILTERS
  applyBrokerageFilter = (e) => {
    const brokerage = e.target.value;
    const newState = merge({}, this.state.getFilterParams, {
      brokerage,
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 }, () => {
      this.getAgents(brokerage);
    });
  };

  applyNameFilter = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      this.getAgents();
    }
    return null;
  };

  applyTagFilter = () => {
    this.getAgents();
  };

  clearNameFilter = () => {
    const newState = merge({}, this.state.getFilterParams, {
      searchName: '',
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 }, () => {
      this.getAgents();
    });
  };

  clearTagFilter = () => {
    const newState = Object.assign({}, this.state.getFilterParams, {
      tags: [],
      page: 1
    });
    this.setState(
      {
        getFilterParams: newState,
        offset: 0
      },
      () => {
        this.setState({
          tagKey: Date.now()
        });
        this.getAgents();
      }
    );
  };

  setNameFilter = (e) => {
    const searchName = e.target.value.toLowerCase();
    const newState = merge({}, this.state.getFilterParams, {
      searchName,
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 });
  };

  setTagFilter = (tags = []) => {
    const newState = Object.assign({}, this.state.getFilterParams, {
      tags,
      page: 1
    });
    this.setState({
      getFilterParams: newState,
      offset: 0
    });
  };

  handleSort = (event) => {
    const index = event.target.value;
    const newSort = sortList[index].order;
    const newState = Object.assign({}, this.state.getFilterParams, {
      page: 1,
      sortDir: newSort
    });
    this.setState({ getFilterParams: newState, offset: 0 }, () => {
      this.getAgents();
    });
  };

  toggleView = (isGridView) => {
    this.setState({ isLoading: true, gridView: isGridView });
    this.getAgents();
  };

  // ADD AGENT
  toggleNewAgentDialog = () => {
    this.setState({
      isAddingAgent: !this.state.isAddingAgent
    });
  };

  createNewAgent = async (agentRecord, brokerageId, onSuccess, onFailure) => {
    try {
      await api.post('/people', agentRecord);
      await api.post('/agents', {
        brokerages: [brokerageId],
        person: agentRecord
      });
      if (isFunction(onSuccess)) {
        onSuccess();
        this.getAgents();
      }
    } catch (e) {
      if (isFunction(onFailure)) {
        onFailure(e);
      }
    }
  };

  // ADD LISTING
  createListing = (id, brokerage) => {
    this.setState({
      activeAgentId: id,
      activeAgentBrokerage: brokerage,
      openListingDialog: true
    });
  };

  createListingClose = () => {
    this.setState({
      activeAgentId: null,
      activeAgentBrokerage: null,
      openListingDialog: false
    });
  };

  // DIALOGS
  showSuccessDialog = () => {
    if (this._isMounted) {
      this.setState({ showSaveSuccessful: true }, () => {
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({ showSaveSuccessful: false });
          }
        }, 1500);
      });
    }
  };

  handleCloseUpload = () => {
    // this.setState({ showUpload: false });
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
      isError: false
    });
  };

  // RENDER
  renderCards = (data) =>
    data.map((card) => (
      <AgentClientCard
        brokerages={card.brokerages}
        data={card.person}
        editableTags={this.props.session.isAdmin}
        id={card._id}
        key={card.id}
        realmData={card.realmData}
        photoURL={card.photoURL}
        type="agents"
      />
    ));

  renderEditPanel = (row) => {
    const { original: data } = row;
    return (
      <div className="editArea">
        <Grid container spacing={24}>
          <Grid item xs sm={3}>
            {(data.photoURL || data.person.realmData.photoUrl) && (
              <Fragment>
                <ProfilePicture
                  fullName={`${data.person.name.firstName} ${
                    data.person.name.lastName
                    }`}
                  photoURL={data.photoURL || data.person.realmData.photoUrl}
                />
              </Fragment>
            )}
            <h2 style={{ margin: '10px 0', width: '100%' }}>
              {data.person.name.firstName} {data.person.name.lastName}
            </h2>
            <p>
              Profile URL:{' '}
              <Link to={`/agent-profile/?id=${data._id}`}>
                {`${window.location.hostname}/agent-profile/?id=${data._id}`}
              </Link>
            </p>
          </Grid>
          <Grid item xs sm={3}>
            <div>
              <TextField
                defaultValue={data.realmData.description || ''}
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                margin="normal"
                multiline
                placeholder="Tell the Realm about yourself"
                style={{ marginBottom: 10 }}
                variant="outlined"
                onChange={this.handleNewDescription(data)}
              />
              <Button
                color="primary"
                onClick={() => this.saveNewDescription(data)}
                variant="contained"
              >
                UPDATE
              </Button>
            </div>
          </Grid>
          <Grid item xs sm={3}>
            <div>
              <TextField
                style={{ marginBottom: 10 }}
                id="invite-email"
                label="Invite Email"
                fullWidth
                placeholder="betauser + gmail please"
                margin="normal"
                variant="outlined"
                onChange={this.handleInviteEmailChange(data)}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => this.saveInviteEmail(data)}
              >
                UPDATE
              </Button>
            </div>
          </Grid>
          <Grid item xs sm={2}>
            <h4>Actions</h4>
            <div style={{ marginBottom: 5 }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() =>
                  this.createListing(data._id, data.currentBrokerage || null)
                }
              >
                Create New Listing
              </Button>
            </div>
            <div style={{ marginBottom: 5 }}>
              {this.handleAccountStatus(data)}
            </div>
            <div>
              <UploadClients
                agentId={data._id}
                forName={`${data.person.name.firstName} ${
                  data.person.name.lastName
                  }`}
                handleClose={this.handleCloseUpload}
                showButton
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderView = () => {
    const { data, gridView, noRecordsFound } = this.state;
    if (noRecordsFound) {
      return (
        <div className="realm--no-records-found">
          <div className="realm--no-records-found-message">
            <p>
              <strong>No records were found.</strong>
            </p>
            {/* <p>To add agents, start by pressing the plus in the upper right-hand corner.</p> */}
          </div>
        </div>
      );
    }
    if (gridView || this.props.application.isMobileOnly) {
      return (
        <div className="realm--grid-container">
          <Grid container spacing={24}>
            {data && this.renderCards(data)}
          </Grid>
        </div>
      );
    }
    return (
      <ReactTable
        className="-striped -highlight"
        data={data}
        defaultPageSize={this.state.getFilterParams.limit}
        filterable={false}
        showPagination={false}
        sortable={false}
        getTdProps={() => {
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal) {
                handleOriginal();
              }
            }
          };
        }}
        columns={[
          {
            columns: [
              {
                id: 'agentName',
                Header: 'Name',
                accessor: (n) => n.name,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original.person ? (
                      <Link to={`/agent-profile?id=${cellInfo.original._id}`}>
                        <div style={{ whiteSpace: 'normal' }}>
                          {cellInfo.original.person.name.firstName}{' '}
                          {cellInfo.original.person.name.lastName}
                        </div>
                      </Link>
                    ) : null}
                  </div>
                )
              },
              {
                id: 'brokerage',
                Header: 'Brokerage',
                accessor: (n) => n.brokerages,
                Cell: (cellInfo) => (
                  <div style={{ whiteSpace: 'normal' }}>
                    {cellInfo.original.currentBrokerage && (
                      <Link
                        to={`/brokerages/list?brokerage=${
                          cellInfo.original.brokerages[0]._id
                          }`}
                      >
                        {cellInfo.original.currentBrokerage.name}
                      </Link>
                    )}
                  </div>
                )
              },
              {
                id: 'properties',
                Header: 'Properties',
                accessor: '',
                filterable: false,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original._id.length > 0 && (
                      <Link
                        to={`/properties/list/?agent=${cellInfo.original._id}`}
                      >
                        See Listings
                      </Link>
                    )}
                  </div>
                )
              },

              {
                id: 'agentPhone',
                Header: 'Phone(s)',
                filterable: false,
                accessor: (n) => n.phones,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original.person ? (
                      <span>
                        {cellInfo.original.person.phones.map((phone, idx) => (
                          <div key={`phone-${phone.number}-${idx}`}>
                            {phone.phoneType ? `${phone.phoneType}: ` : ''}
                            {phone.number}
                          </div>
                        ))}
                      </span>
                    ) : (
                        <span />
                      )}
                  </div>
                )
              },
              {
                id: 'agentEmail',
                Header: 'Email',
                filterable: false,
                width: 200,
                accessor: (n) => n.emails,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original.person ? (
                      <span>
                        {cellInfo.original.person.emails.map((email, idx) => (
                          <div key={`email-${email.address}-${idx}`}>
                            <a href={`mailto:  ${email.address}`}>
                              {email.address}
                            </a>
                          </div>
                        ))}
                      </span>
                    ) : (
                        <span />
                      )}
                  </div>
                )
              },
              {
                id: 'agentSpecialty',
                Header: 'Specialties',
                filterable: false,
                width: 300,
                accessor: (n) => n.emails,
                Cell: (cellInfo) => (
                  <div
                    key={`cellfortaglist-${cellInfo.original._id}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <TagList
                      format="default"
                      id={cellInfo.original._id}
                      name={
                        cellInfo.original.person
                          ? cellInfo.original.person.fullName
                          : ''
                      }
                      title="Edit Specialties"
                      readonly={!this.props.session.isAdmin}
                      type="agents"
                    />
                  </div>
                )
              },
              // {
              //   id: 'agentAccess',
              //   Header: 'Status',
              //   filterable: false,
              //   width: 100,
              //   show: AppSettings.isAdmin,
              //   accessor: (n) => n.emails,
              //   Cell: (cellInfo) => (
              //     <div
              //       key={`cellforinvites-${cellInfo.original._id}`}
              //       style={{
              //         display: 'flex',
              //         alignItems: 'center',
              //         justifyContent: 'center',
              //         flexWrap: 'wrap'
              //       }}
              //     >
              //       {this.handleAccountStatus(cellInfo.original)}
              //     </div>
              //   )
              // },
              {
                id: 'agentUploads',
                Header: '# Clients / Uploads',
                filterable: false,
                show: this.props.session.isAdmin,
                accessor: (n) => n.emails,
                Cell: (cellInfo) => (
                  <div style={{ textAlign: 'center' }}>
                    {this.returnClientCount(cellInfo.original)}
                  </div>
                )
              }
            ]
          }
        ]}
        SubComponent={
          !this.props.session.isAgent
            ? (row) => this.renderEditPanel(row)
            : undefined
        }
      />
    );
  };

  render() {
    const { isMobile, isMobileOnly } = this.props.application;
    const { brokerages, isAdmin, isConceirge } = this.props.session;
    const { getFilterParams } = this.state;
    const tagMessage =
      getFilterParams.tags.length > 1
        ? `You are filtering on ${getFilterParams.tags.length} tags`
        : 'You are filtering on 1 tag';
    const showPagination = this.state.totalRecords > getFilterParams.limit;
    return (
      <div className="page-with-title">
        <PageTitle
          addPlus={!isMobile && isAdmin}
          handleClick={this.toggleNewAgentDialog}
          title="Agents"
        />
        <div
          className="realm--filters"
          style={{
            opacity: this.state.isLoading ? '.5' : '1',
            pointerEvents: this.state.isLoading ? 'none' : 'auto'
          }}
        >
          {!isMobileOnly && (
            <div className="realm--filter toggle">
              <ViewToggle clickHandler={this.toggleView} gridView={false} />
            </div>
          )}
            {this.props.session.isAdmin && (
            <div className="realm--filter switch">
            <Switch
              checked={this.state.getFilterParams.showArchived}
              onChange={this.handleShowArchived}
              color="secondary"
            />
            <span className="realm--property-labels">
              Show Archived
            </span>
          </div>
          )}
          <div className="realm--filter">
            <div>
              <TagDialog
                existingFilterTags={getFilterParams.tags}
                filterable
                key={this.state.tagKey}
                onFilter={this.setTagFilter}
                onFilterDone={this.applyTagFilter}
                title="Filter by specialties"
                type="agentClients"
              />
              {getFilterParams.tags.length !== 0 && (
                <Tag
                  format="default"
                  label={tagMessage}
                  onDelete={this.clearTagFilter}
                />
              )}
            </div>
          </div>
          <div className="realm--filter ie">
            <TextField
              id="nameSearch"
              label="Search by name"
              onKeyDown={this.applyNameFilter}
              onChange={this.setNameFilter}
              value={getFilterParams.searchName}
              InputProps={
                getFilterParams.searchName
                  ? {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ position: 'relative' }}
                      >
                        <IconButton
                          aria-label="Clear input field"
                          onClick={this.clearNameFilter}
                          style={{
                            position: 'absolute',
                            top: -10,
                            right: -15
                          }}
                        >
                          <i
                            className="fa fa-close"
                            style={{ fontSize: 12 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                  : {}
              }
            />
          </div>
          <div className="realm--filter ie">
            <Sorter handleSorterPicked={this.handleSort} sortList={sortList} />
          </div>
          <div className="realm--filter ie">
            <BrokeragePicker
              brokerages={brokerages}
              handleBrokeragePicked={this.applyBrokerageFilter}
            />
          </div>
          {showPagination && (
            <div className="realm--filter">
              <Pagination
                currentPageColor="primary"
                limit={this.state.getFilterParams.limit}
                offset={this.state.offset}
                total={this.state.totalRecords}
                onClick={(e, offset) => this.nextPage(offset)}
                otherPageColor="default"
              />
            </div>
          )}
        </div>
        {this.state.isLoading ? (
          <div className="realm--loading-container">
            <LoadingIndicator />
          </div>
        ) : (
            this.renderView()
          )}
        {showPagination && (
          <Pagination
            currentPageColor="primary"
            disabled={this.state.isLoading}
            style={{ marginTop: 30, marginBottom: 30, textAlign: 'center' }}
            limit={this.state.getFilterParams.limit}
            offset={this.state.offset}
            total={this.state.totalRecords}
            onClick={(e, offset) => this.nextPage(offset)}
            otherPageColor="default"
          />
        )}
        {this.props.session.isAdmin && (
          <AddAgentDialog
            handleCreate={this.createNewAgent}
            handleClose={this.toggleNewAgentDialog}
            open={this.state.isAddingAgent}
          />
        )}
        {(isAdmin || isConceirge) && (
          <AddListingDialog
            agentId={this.state.activeAgentId}
            brokerage={this.state.activeAgentBrokerage}
            handleClose={this.createListingClose}
            isMobile={this.props.application.isMobile}
            language={this.props.application.locale}
            open={this.state.openListingDialog}
          />
        )}
        <SuccessDialog
          message="Congratulations! You are one step closer to finding a match for your property."
          open={this.state.showSaveSuccessful}
        />
        <ErrorDialog
          handleClose={this.handleClose}
          message={this.state.errorMessage}
          open={this.state.isError}
        />
      </div>
    );
  }
}

Agents.propTypes = {
  application: PropTypes.object,
  location: PropTypes.object,
  onRefresh: PropTypes.func,
  session: PropTypes.object
};

export default Agents;
