import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core';

import api from '../../api';
import FormValidator from '../../helpers/form-validator';

class RegisterAdmin extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      user: {},
      inviteType: 'realmAdmin'
    };
    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.',
      },
    ]);
  }

  onRegister = async () => {
    this.setState({ loading: true });
    await api.post('/admin/register', {
      ...this.state.user,
      roles: [this.state.inviteType],
      isAdmin: true // TODO: This will go away
    }).then(() => {
      // nothing here
    }).catch((ex) => {
      alert(ex.response.data.msg);
    });
    this.props.onRefresh();
    this.handleClose();
  }

  handleClose = () => {
    this.props.handleClose();
  };

  validationClass = (validation) => ((validation && !validation.isValid) ? 'input-error' : '');

  handleChange = (event) => {
    const { user } = Object.assign({}, this.state);
    user[event.target.id] = event.target.value;
    this.setState(user);
  }

  handleSelection = (event) => {
    this.setState({ inviteType: event.target.value });
  };

  renderTextFieldWithValidator = (validator) => (label, id, type = 'text') => (
    <TextField
      autoFocus
      className={this.validationClass(validator[id])}
      fullWidth
      id={id}
      label={label}
      margin="dense"
      onChange={this.handleChange}
      type={type}
      value={this.state.user[id]}
    />
  );

  renderDialog() {
    const { isVisible } = this.props;
    const validator = this.validator.validate(this.state);
    const renderTextField = this.renderTextFieldWithValidator(validator);

    return (
      <div>
        <Dialog
          aria-describedby="delete-dialog"
          aria-labelledby="delete-dialog-title"
          onClose={this.handleClose}
          open={isVisible}
        >
          <DialogTitle id="delete-dialog-title">
            Add Administrator
          </DialogTitle>
          <DialogContent className="edit-modal">
            {/* {renderTextField('Username', 'username')} // Username is internal to Cognito - Realm uses emails as a login */}
            {renderTextField('First name', 'firstName')}
            {renderTextField('Last name', 'lastName')}
            {renderTextField('Email', 'email')}
            <Select
              className="selectWhite"
              value={this.state.inviteType}
              onChange={this.handleSelection}
              inputProps={{
                name: 'selectType',
                id: 'inviteType',
              }}
            >
              <MenuItem value="realmAdmin">Realm Admin</MenuItem>
              <MenuItem value="concierge">Concierge</MenuItem>
              <MenuItem value="admin">Super Admin</MenuItem>
              }


            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onRegister} color="default">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    return (
      <span>
        {this.renderDialog()}
      </span>
    );
  }
}

RegisterAdmin.propTypes = {
  handleClose: PropTypes.func,
  isVisible: PropTypes.bool,
  onRefresh: PropTypes.func,
};

export default RegisterAdmin;
