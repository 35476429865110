/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import merge from 'lodash.merge';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import LoadingIndicator from '../../../components/LoadingIndicator';
import RealmButton from '../components/RealmButton';

import api from '../../../api';
import localization from '../../../services/Localization';

class Agreements extends Component {
  state = {
    content: null,
    contentLoading: false,
    showDialog: false,
    termsAccepted: false,
    termsDeclined: false
  };

  componentDidMount() {
    this.props.updateStep(0);
    const { currentPerson: person } = this.props.session;
    if (person.realmData && person.realmData.userAccount) {
      if (person.realmData.userAccount.acceptedTermsAt) {
        this.props.history.push('/your-account');
      }
    }
    const el = document.getElementById('onboarding-container');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }

  handleChange = (e) => {
    if (e.target.name === 'accept') {
      this.setState({
        termsDeclined: !this.state.termsAccepted
          ? false
          : this.state.termsDeclined,
        termsAccepted: !this.state.termsAccepted
      });
    }
    if (e.target.name === 'decline') {
      this.setState({
        termsAccepted: !this.state.termsDeclined
          ? false
          : this.state.termsAccepted,
        termsDeclined: !this.state.termsDeclined
      });
    }
  };

  loadContent = async (endpoint) => {
    const { locale: lang } = this.props.application;
    this.setState({ contentLoading: true });
    try {
      const response = await api.get('/cms/full-post', {
        params: {
          slug: `${endpoint}-${lang}`,
          language: lang
        }
      });
      const data = response.data.data.data[0];
      const {
        content: { rendered: htmlContent },
        title: { rendered: htmlTitle }
      } = data;
      this.setState({
        content: {
          htmlContent,
          htmlTitle
        },
        contentLoading: false
      });
    } catch (err) {
      alert(err);
      this.setState({
        contentLoading: false,
        showDialog: false
      });
    }
  };

  showDialog = (e) => {
    e.preventDefault();
    this.setState({ showDialog: true });
    this.loadContent(e.target.name);
  };

  hideDialog = () => {
    this.setState({
      content: null,
      showDialog: false
    });
  };

  updatePerson = async () => {
    const person = Object.assign({}, this.props.session.currentPerson);
    const updatedDate = new Date().toISOString();
    const updatedPerson = merge(person.realmData, {
      userAccount: {
        acceptedTermsAt: updatedDate
      }
    });
    await this.props.actions.updatePerson({
      realmData: updatedPerson
    });
    this.props.history.push('/your-account');
  };

  render() {
    const { name } = this.props.session.currentPerson;
    const { onboarding: lang } = localization;
    return (
      <Fragment>
        <h1
          className="realm-onboarding--title centered"
          dangerouslySetInnerHTML={{
            __html: `${lang.agreements_title}, ${name.firstName}`
          }}
        />
        <div className="realm-onboarding--paragraph-group">
          <p className="realm-onboarding--welcome">{lang.agreements_p1}</p>
          <p className="realm-onboarding--welcome">{lang.agreements_p2}</p>
          <p className="realm-onboarding--welcome">{lang.agreements_p3}</p>
          <p className="realm-onboarding--welcome">{lang.agreements_p4}</p>
        </div>
        <div className="realm-onboarding--form centered">
          <div className="realm-onboarding--input-group checkbox wide">
            <Checkbox
              checked={this.state.termsAccepted}
              color="primary"
              name="accept"
              onChange={this.handleChange}
              value="checkedB"
            />
            {lang.agreements_accept}
            <br />
          </div>
          <p>
            <a
              name="marketing-company-terms-and-conditions"
              onClick={this.showDialog}
              role="dialog"
            >
              {lang.agreements_terms}
            </a>
            <span className="divider">|</span>
              <a
                name="marketing-company-privacy-policy"
                onClick={this.showDialog}
                role="dialog"
              >
                {lang.agreements_privacy}
              </a>
              <span className="divider">|</span>
            <a
              name="marketing-company-confidentiality-agreement"
              onClick={this.showDialog}
              role="dialog"
            >
              {lang.agreements_confidentiality}
            </a>
            <span className="divider">|</span>
            <a
              name="marketing-company-gdpr"
              onClick={this.showDialog}
              role="dialog"
            >
              {lang.agreements_cookies}
            </a>
          </p>
        </div>
        <div className="realm-onboarding--actions">
          <RealmButton
            disabled={!this.state.termsAccepted && !this.state.termsDeclined}
            onClick={this.updatePerson}
            nextPage="YourAccount"
            title={lang.next}
          />
        </div>

        <Dialog
          className="realm-onboarding--dialog"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="md"
          open={this.state.showDialog}
        >
          <DialogTitle>
            {this.state.contentLoading
              ? 'Loading ...'
              : this.state.content && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.state.content.htmlTitle
                  }}
                />
              )}
          </DialogTitle>
          <DialogContent>
            <div className="realm--white-text">
              {this.state.contentLoading ? (
                <LoadingIndicator />
              ) : (
                this.state.content && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.content.htmlContent
                    }}
                  />
                )
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.hideDialog}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

Agreements.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  history: PropTypes.object,
  session: PropTypes.object,
  updateStep: PropTypes.func
};

export default Agreements;
