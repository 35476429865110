import React, { Component, Fragment } from 'react';
import {
  IconButton,
  Popover,
  Step,
  Stepper
} from '@material-ui/core';

import localization from '../../services/Localization';
import earthImage from '../../images/img/earth-grid.png';

import Footer from './components/Footer';
import StepLabel from './components/RealmStepLabel';
import Localizer from '../../components/Localizer';
import LoadingIndicator from '../../components/LoadingIndicator';
import OnboardingRoutes from './OnboardingRoutes';

class Onboarding extends Component {
  state = {
    activeStep: 0,
    anchorEl: null,
    mstatus: null
  };

  steps = [
    localization.onboarding.step1,
    localization.onboarding.step2,
    localization.onboarding.step3,
    localization.onboarding.step4,
    localization.onboarding.step5
  ];

  componentDidMount() {
    const { actions, session } = this.props;
    !session.agentData && actions.getAgentData();
  }

  updateStep = (activeStep) => {
    this.setState({ activeStep });
  }

  handleLanguageMenu = (event, param) => {
    this.setState({ anchorEl: event.currentTarget, mstatus: param });
  };

  handleLanguageMenuClose = () => {
    this.setState({ anchorEl: null, mstatus: null });
  };

  render() {
    const { session } = this.props;
    if (!session.agentData || !session.currentPerson) {
      return (
        <div className="realm--loading-container">
          <LoadingIndicator />
        </div>
      );
    }
    const { activeStep } = this.state;
    const { onboarding: lang } = localization;

    return (
      <div className="realm-onboarding" id="onboarding-container">
        <div className="realm-onboarding--wrapper">
          <IconButton
            aria-haspopup="true"
            color="inherit"
            onClick={(e) => this.handleLanguageMenu(e, 'lang')}
            style={{ position: 'absolute', top: 10, right: 20 }}
          >
            <img
              src={earthImage}
              style={{ width: '26px', marginRight: 6, height: 'auto' }}
              alt={'select language'}
            />
            <i
              className="fa fa-caret-down submenuicon"
              style={{
                fontSize: 16,
                marginRight: 8
              }}
            />
          </IconButton>
          <Popover
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            id="menu-language"
            open={this.state.mstatus === 'lang'}
            onClose={this.handleLanguageMenuClose}
            style={{ maxWidth: 320, width: 320 }}
          >
            <Localizer
              actions={this.props.actions}
              application={this.props.application}
            />
          </Popover>

          <Fragment>
            <p className="realm-onboarding--label stepper">{lang.label}</p>
            <Stepper
              activeStep={activeStep}
              style={{
                marginBottom: '2em',
                backgroundColor: 'transparent',
                color: '#c5c3bb'
              }}
            >
              {this.steps.map((label) => {
                const props = {};
                const labelProps = {};
                return (
                  <Step key={label} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Fragment>
          <OnboardingRoutes {...this.props} updateStep={this.updateStep} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Onboarding;
