import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import { Link } from 'react-router-dom';
import { PieChart, Pie } from 'recharts';
import {
  Button,
  Grid,
  Hidden,
  withStyles
} from '@material-ui/core';

import PageTitle from '../../components/PageTitle';
import LoadingIndicator from '../../components/LoadingIndicator';
import localstring from '../../services/Localization';

import DataCard from '../../components/Dashboard/DataCard';
import DashPost from '../../components/Dashboard/DashPost';
import ProfilePicture from '../../components/ProfilePicture';
import ProfileProgress from '../../components/ProfileProgress';

import api from '../../api';
import styles from './styles';

class Dashboard extends Component {
  state = {
    alerts: 0,
    clients: 0,
    dataPropertyMatches: [],
    loadingData: true,
    loadingBlogPosts: true,
    listings: 0,
    matchingTotals: { high: 0 },
    profileViews: 0,
    wallData: []
  };

  _mounted = false;

  componentDidMount() {
    this._mounted = true;
    const { actions, session } = this.props;
    if (session.isAuthenticated && session.isAgent) {
      session.agentData === null &&
        actions.getAgentData(session.currentUser.person._id);
      session.agentData && this.getAgentInfo();
    }
    this.getBlogPosts();
  }

  componentDidUpdate(prevProps) {
    const { actions, session } = this.props;
    // insurance against agent data not uploading on mount
    if (session.isAuthenticated) {
      if (!shallowequal(session.currentUser, prevProps.session.currentUser)) {
        if (
          prevProps.session.currentUser.person._id !==
          session.currentUser.person._id
        ) {
          if (session.isAgent) {
            session.agentData === null &&
              actions.getAgentData(
                session.currentUser.person._id,
                session.token
              );
          }
        }
      }
      if (!shallowequal(session.agentData, prevProps.session.agentData)) {
        this.getAgentInfo();
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getAgentInfo = async () => {
    const { agentData } = this.props.session;
    try {
      const response = await api.get(`/agents/data/${agentData._id}`);
      this.updateData(response);
    } catch (e) {
      this.setState({ loadingData: false });
    }
    return null;
  };

  updateData = (response) => {
    if (!this._mounted) {
      return null;
    }
    const { currentPerson } = this.props.session;
    const { alerts, clients, listings } = response.data.data.data;
    const matchingTotals = response.data.data.data.matching[0];
    const profileViews = currentPerson.realmData.profileViews
      ? currentPerson.realmData.profileViews.viewedBy.length
      : 0;
    const dataPropertyMatches = matchingTotals
      ? [
        {
          name: 'High',
          value: matchingTotals.high
        },
        {
          name: 'Other Matches',
          value: matchingTotals.med + matchingTotals.low
        }
      ]
      : [];
    this.setState({
      alerts,
      clients,
      dataPropertyMatches,
      listings,
      loadingData: false,
      matchingTotals,
      profileViews
    });
    return null;
  };

  getBlogPosts = async () => {
    this.setState({ loadingBlogPosts: true });
    // get "cards" for list of blogs in specific categories
    // note: categories must be an array even it has only one element
    const response = await api.get('/cms/by-category', {
      params: {
        categories: [24], // featured
        view: 'list', // if 'card' then returns all the extra data for each list itme,
        count: 4,
        language: this.props.application.locale // must use language "short code"
      }
    });
    this.updateBlogPosts(response);
  };

  updateBlogPosts = (response) => {
    if (!this._mounted) {
      return null;
    }
    this.setState({
      loadingBlogPosts: false,
      wallData: response.data.data.data
    });
    return null;
  };

  displayRoles = () => {
    const { currentUser } = this.props.session;
    if (!currentUser) {
      return '';
    }
    const roles = currentUser.roles
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' / ');
    return <span>{roles}</span>;
  };

  render() {
    const { classes, session } = this.props;
    const { agentData, currentUser, loading } = session;
    const { dataPropertyMatches } = this.state;
    if (!currentUser) {
      return (
        <div className="realm--loading-container">
          <LoadingIndicator />
        </div>
      );
    }
    const fullName = `${currentUser.firstName} ${currentUser.lastName}`;

    return (
      <div className={classes.container}>
        <div className="page-with-title">
          <PageTitle title={localstring.dash_page_name} />
          {loading ? <LoadingIndicator /> : <span />}

          <div className="realm--dashboard">

            <h1 className={classes.welcome}>
              {localstring.dash_welcome}
              &nbsp;
              {currentUser.firstName}
            </h1>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <div className="realm--dashboard-profile">
                  <ProfilePicture
                    centered
                    fullName={fullName}
                    photoURL={session.avatar}
                    style={{ marginBottom: 12 }}
                  />
                  {fullName && fullName}
                  <div className={classes.subhead}>{this.displayRoles()}</div>
                  <br />
                  <ProfileProgress
                    data={agentData}
                    realmData={agentData && agentData.realmData}
                    type="agents"
                  />
                  {agentData && (
                    <a
                      className="realm--complete-profile-link"
                      href={`agent-profile?id=${agentData._id}`}
                    >
                      Complete Your Profile
                    </a>
                  )}
                </div>
                {session.isAgent && agentData && (
                  <div className="realm--dashboard-data">
                    <DataCard
                      title={localstring.dash_your_realm}
                      value1={this.state.clients}
                      caption1={localstring.clients}
                      value2={this.state.listings}
                      caption2={localstring.properties}
                      value3={this.state.profileViews}
                      caption3={localstring.dash_profile_views}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                {session.isAgent && agentData && (
                  <div className="realm--dashboard-extra">
                    {this.state.loadingData ? (
                      <LoadingIndicator />
                    ) : (
                        <Fragment>
                          {localstring.dash_activity}
                          <br />
                          <br />
                          <Grid container spacing={0}>
                            <Grid item xs={4}>
                              <Link
                                to={`/matching/?agent=${agentData._id}`}
                                className={classes.dataButton}
                              >
                                My {localstring.properties}
                              </Link>
                              {dataPropertyMatches.length > 0 ? (
                                <PieChart
                                  width={100}
                                  height={100}
                                  style={{ margin: '0 auto' }}
                                >
                                  <Pie
                                    stroke="transparent"
                                    data={dataPropertyMatches}
                                    dataKey="value"
                                    innerRadius={20}
                                    outerRadius={40}
                                    fill="#666"
                                  />
                                  <Pie
                                    stroke="transparent"
                                    startAngle={270}
                                    endAngle={110}
                                    data={dataPropertyMatches}
                                    dataKey="value"
                                    innerRadius={20}
                                    outerRadius={40}
                                    fill="#ac5f0f"
                                  />
                                </PieChart>
                              ) : (
                                  <div className="realm--dashboard-big-number">
                                    0
                              </div>
                                )}

                              <Link
                                to={`/matching/?agent=${agentData._id}`}
                                className={classes.dataButton}
                              >
                                {' '}
                                {this.state.matchingTotals &&
                                  `${this.state.matchingTotals.high} ${
                                  localstring.high_matches
                                  }`}
                              </Link>
                            </Grid>
                            <Grid item xs={4}>
                              <Link
                                to="/match-alerts"
                                className={classes.dataButton}
                              >
                                {' '}
                                My Clients
                            </Link>
                              <div className="realm--dashboard-big-number">
                                {this.state.alerts}
                              </div>
                              <Link
                                to="/match-alerts"
                                className={classes.dataButton}
                              >
                                Matching Alerts
                            </Link>
                            </Grid>
                            <Grid item xs={4}>
                              <Link to="/agents" className={classes.dataButton}>
                                {' '}
                                My Profile
                            </Link>
                              <div className="realm--dashboard-big-number">
                                {this.state.profileViews}
                              </div>
                              <Link
                                to={`/matching/?agent=${agentData._id}`}
                                className={classes.dataButton}
                              >
                                Views
                            </Link>
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                  </div>
                )}
                <div className="realm--dashboard-blog">
                  {localstring.dash_latest_word}
                  <br />
                  {session.isAgent && (
                    <div className={classes.messageArea}>
                      <div className={classes.message}>
                        Be sure to fine tune the MatchTags for your clients for
                        better matching
                      </div>
                      <Button className={classes.messageCTA}>
                        {localstring.dash_fine_tune}
                      </Button>
                      <br />
                    </div>
                  )}
                  {this.state.loadingBlogPosts ? (
                    <LoadingIndicator />
                  ) : (
                      <div style={{ height: 12 }} />
                    )}
                  <div>
                    {this.state.wallData.map((entry) => (
                      <div key={entry.slug}>
                        <DashPost
                          post={entry}
                          author={localstring.dash_realm_team}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>

              {/* ******************** AGENT ACTION BUTTONS ***************** */}
              {session.isAgent && agentData && (
                <Hidden smDown>
                  <Grid item md={2}>
                    <div className="realm--dashboard-actions">
                      <Link
                        role="button"
                        to="/match-alerts"
                        className="realm--dashboard-actions-button"
                      >
                        {localstring.dash_matching_alerts}
                      </Link>
                      <Link
                        role="button"
                        to={`/matching/?agent=${agentData._id}`}
                        className="realm--dashboard-actions-button"
                      >
                        {localstring.dash_property_matching}
                      </Link>
                      <Link
                        role="button"
                        to="/my-clients"
                        className="realm--dashboard-actions-button"
                      >
                        {localstring.my_clients}
                      </Link>
                      <Link
                        role="button"
                        to="/properties/list"
                        className="realm--dashboard-actions-button"
                      >
                        {localstring.my_properties}
                      </Link>
                      <Link
                        role="button"
                        to="/agents"
                        className="realm--dashboard-actions-button"
                      >
                        {localstring.dash_agent_connect}
                      </Link>
                    </div>
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  classes: PropTypes.object,
  session: PropTypes.object
};

export default withStyles(styles)(Dashboard);
