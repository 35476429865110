const styles = () => ({
  root: {},
  content: {},
  dataButton: {
    marginBottom: 8,
    padding: 0,
    width: 150,
    marginLeft: 8,
    color: '#fff',
    fontSize: 16,
    fontWeight: 300,
    letterSpacing: 0.5,
    paddingTop: 4,
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#ddf',
      textDecoration: 'none'
    }
  },
  container: {
    paddingBottom: 20
  },
  welcome: {
    fontFamily: '"Cormorant", serif',
    fontSize: 32,
    letterSpacing: '0.05em'
  },
  face: {
    borderRadius: 50,
    height: 124
  },
  complete: {
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: 18
  },
  subhead: {
    fontSize: 10
  },
  messageArea: {
    padding: 24,
    fontSize: 12,
    textAlign: 'left'
  },
  message: {
    display: 'inline-block',
    width: '100%',
    fontSize: 14
  },
  messageCTA: {
    float: 'right',
    color: '#9999b8'
  }
});

export default styles;
