/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import merge from 'lodash.merge';

import monitorDashboard from '../assets/monitor-button1.png';
import monitorMatches from '../assets/monitor-button3.png';

import localization from '../../../services/Localization';

class OnboardingComplete extends Component {
  componentDidMount() {
    this.props.updateStep(4);
    this.completeOnboarding();
    const el = document.getElementById('onboarding-container');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }

  completeOnboarding = async () => {
    const person = Object.assign({}, this.props.session.currentPerson);
    const updatedDate = new Date().toISOString();
    const updatedUserAccount = merge(person.realmData.userAccount, {
      onBoardedAgentAt: updatedDate
    });
    const updatedPerson = merge(person, {
      realmData: {
        userAccount: updatedUserAccount
      }
    });
    await this.props.actions.updatePerson({
      realmData: updatedPerson.realmData
    });
  }

  goToAdminSite = (e) => {
    e.preventDefault();
    this.props.actions.completeOnboarding();
    this.props.history.push('/');
  };

  render() {
    const { onboarding: lang } = localization;
    const { name } = this.props.session.currentPerson;
    return (
      <Fragment>
        <h1 className="realm-onboarding--title centered">
          {name.firstName},{' '}
          <div style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: lang.complete_title }} />
        </h1>
        <p
          className="realm-onboarding--welcome centered"
          dangerouslySetInnerHTML={{ __html: lang.complete_welcome }}
        />
        <div className="flex-container flex-spaceAround">
          <a
            className="realm-onboarding--monitor-button"
            name="dashboard"
            onClick={this.goToAdminSite}
            role="navigation"
          >
            <img alt="Go to Dashboard" src={monitorDashboard} />
            <h4>{lang.complete_dash}</h4>
          </a>
          <a
            className="realm-onboarding--monitor-button"
            name="matching"
            onClick={this.goToAdminSite}
            role="navigation"
          >
            <img alt="Go to Dashboard" src={monitorMatches} />
            <h4>{lang.complete_match}</h4>
          </a>
        </div>
      </Fragment>
    );
  }
}

OnboardingComplete.propTypes = {
  actions: PropTypes.object,
  session: PropTypes.object,
  updateStep: PropTypes.func
};

export default OnboardingComplete;
