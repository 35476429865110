module.exports = {
  list: [
    {
      Short_Name: 'ar',
      Full_Name: 'Arabic',
      Native_Name: 'عربى',
      CMS_ID: 30,
      Reading: 'RTL',
      Images: 'Flipped horizontally'
    },
    {
      Short_Name: 'de',
      Full_Name: 'German',
      Native_Name: 'Deutsche',
      CMS_ID: 34,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'en',
      Full_Name: 'English',
      Native_Name: 'English ',
      CMS_ID: 4,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'es',
      Full_Name: 'Spanish (Europe)',
      Native_Name: 'Castellano/Español',
      CMS_ID: 43,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'es_xl',
      Full_Name: 'Spanish (Latin Am)',
      Native_Name: 'Español',
      CMS_ID: 44,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'fr',
      Full_Name: 'French (France)',
      Native_Name: 'Français',
      CMS_ID: 6,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'fr_ca',
      Full_Name: 'French (Canadian)',
      Native_Name: 'Français Québécois',
      CMS_ID: 33,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'hi',
      Full_Name: 'Hindi',
      Native_Name: 'हिंदी',
      CMS_ID: 36,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'it',
      Full_Name: 'Italian',
      Native_Name: 'Italiano',
      CMS_ID: 37,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'iw',
      Full_Name: 'Hebrew',
      Native_Name: 'עִברִית',
      CMS_ID: 35,
      Reading: 'RTL',
      Images: 'Flipped horizontally'
    },
    {
      Short_Name: 'ja',
      Full_Name: 'Japanese',
      Native_Name: '日本語',
      CMS_ID: 38,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'ko',
      Full_Name: 'Korean',
      Native_Name: '한국어',
      CMS_ID: 39,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'nl',
      Full_Name: 'Dutch',
      Native_Name: 'Nederlands',
      CMS_ID: 32,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'pl',
      Full_Name: 'Polish',
      Native_Name: 'Polskie',
      CMS_ID: 40,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'pt',
      Full_Name: 'Portuguese',
      Native_Name: 'Português',
      CMS_ID: 23,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'pt_br',
      Full_Name: 'Portuguese (Brazilian)',
      Native_Name: 'Portuguese',
      CMS_ID: 41,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'ru',
      Full_Name: 'Russian',
      Native_Name: 'русский',
      CMS_ID: 42,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'tr',
      Full_Name: 'Turkish',
      Native_Name: 'Türkçe',
      CMS_ID: 46,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'vi',
      Full_Name: 'Vietnamese',
      Native_Name: 'Tiếng Việt Nam',
      CMS_ID: 47,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'zh_hans',
      Full_Name: 'Simplified Chinese',
      Native_Name: '中文 -简体',
      CMS_ID: 29,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'zh_hant',
      Full_Name: 'Traditional Chinese',
      Native_Name: '中文 -繁體',
      CMS_ID: 31,
      Reading: 'LTR',
      Images: 'not flipped'
    },
    {
      Short_Name: 'zh_tw',
      Full_Name: 'Mandarin',
      Native_Name: '國語',
      CMS_ID: 45,
      Reading: 'LTR',
      Images: 'not flipped'
    }
  ]
};
