/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';

import Languages from '../../services/LanguageList';
import currencies from './currencies';
import localstring from '../../services/Localization';

class Localizer extends PureComponent {
  setCurrency = (e) => {
    e.preventDefault();
    this.props.actions.changeCurrency(e.target.value);
  };

  setLanguage = (e) => {
    e.preventDefault();
    const languageObj = JSON.parse(e.target.name);
    this.props.actions.changeLocale(languageObj.Short_Name);
    this.props.actions.changeDirection(languageObj.Reading === 'RTL');
  };

  setMeasure = (e) => {
    e.preventDefault();
    this.props.actions.changeMeasure(e.currentTarget.id);
  };

  render() {
    const languages = Languages.list;
    const { application } = this.props;
    return (
      <div className="realm--localizer">
        <div className="realm--localizer-container">
          {this.props.showUnitSelector && (
            <span>
              <Grid container className="realm--pop-row">
                <Grid xs={12} sm={4}>
                  <span className="realm--localizer-pop-label">{localstring.units}</span>
                </Grid>
                <Grid xs={12} sm={8}>
                  <div>
                    <Button
                      id="metric"
                      size="small"
                      className={`realm--localizer-pop-btn ${application.measure === 'metric' ? 'realm--selected-measure' : ''}`}
                      onClick={this.setMeasure}
                    >
                      Metric
                    </Button>
                    <Button
                      id="us"
                      size="small"
                      className={`realm--localizer-pop-btn ${application.measure === 'us' ? 'realm--selected-measure' : ''}`}
                      onClick={this.setMeasure}
                    >
                      U.S.
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <div className="realm--localizer-seperate-line" />
            </span>
          )}

          {this.props.showCurrencySelector && (
            <Grid container className="realm--localizer-pop-row">
              <Grid item xs={12} sm={4}>
                <span className="realm--localizer-pop-label mr">
                  {localstring.currency}
                </span>
              </Grid>
              <Grid item xs={12} sm={8}>
                <select
                  className="realm--localizer-currency-selector"
                  onChange={this.setCurrency}
                  value={application.currency}
                >
                  {currencies.map((currency) => (
                    <option key={`${currency.name}-${currency.currency}`} value={currency.currency}>{currency.name}</option>
                  ))}
                </select>
              </Grid>
            </Grid>
          )}
        </div>
        <div className="realm--localizer-lang-area">
          <h6 className="realm--localizer-lang-title">{localstring.language}</h6>
          <Grid container>
            {languages.map((lang) => (
              <Grid item xs={12} sm={6} key={lang.Short_Name}>
                <a
                  href=""
                  role="button"
                  name={JSON.stringify(lang)}
                  className={`realm--localizer-lang-text ${application.locale === lang.Short_Name ? 'realm--selected-lang' : ''}`}
                  onClick={this.setLanguage}
                >
                  {lang.Native_Name}
                </a>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}

Localizer.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  showCurrencySelector: PropTypes.bool,
  showUnitSelector: PropTypes.bool
};

export default Localizer;
