import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField
} from '@material-ui/core';
import localstring from '../../services/Localization';
import LoadingIndicator from '../LoadingIndicator';

class ResetPasswordRequest extends Component {
  state = {
    emailAddress: '',
    isLoading: false,
    isValid: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      if (this.state.isLoading) {
        this.delay = setTimeout(() => {
          this.setState({ isLoading: false });
        }, 750);
      }
    }
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
      this.delay = null;
    }
  }

  onInput = (e) => {
    this.setState(
      {
        emailAddress: e.target.value
      },
      () => {
        this.setState({
          isValid: this.validateEmail()
        });
      }
    );
    return null;
  };

  delay = null;

  // for now -- need better validation
  validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.emailAddress);
  };

  confimReset = () => {
    this.setState({ isLoading: true });
    this.props.handleReset(this.state.emailAddress);
  };

  render() {
    const { forgot, forName, showButton } = this.props;
    const { emailAddress } = this.state;
    return (
      <Fragment>
        {showButton ? (
          <div style={{ display: this.props.block ? 'block' : 'inline-block' }}>
            <Button
              style={
                this.props.block
                  ? {
                    display: 'block',
                    margin: '0 auto'
                  }
                  : {}
              }
              color={forgot ? 'primary' : 'default'}
              onClick={this.props.handleOpen}
              variant={this.props.block ? 'contained' : undefined}
            >
              {forgot ? (
                <span>{localstring.onboarding.forgot_password}</span>
              ) : (
                <span>
                  {localstring.onboarding.account_form_change_password}
                </span>
              )}
            </Button>
          </div>
        ) : null}
        <Dialog
          aria-describedby="delete-dialog"
          aria-labelledby="delete-dialog-title"
          onClose={this.props.handleClose}
          open={this.props.open || false}
        >
          <DialogTitle id="delete-dialog-title">
            {forgot ? (
              <span>{localstring.onboarding.reset_password}</span>
            ) : (
              <span>
                {localstring.onboarding.account_form_change_password}
              </span>
            )}
          </DialogTitle>
          <div
            style={{
              color: '#fff',
              fontSize: 18,
              paddingLeft: 24,
              marginTop: -20
            }}
          >
            {forName ? <span>for {forName}</span> : null}
          </div>
          <div className="edit-modal" style={{ position: 'relative' }}>
            {this.state.isLoading ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <p style={{ color: '#fff' }}>Sending verfication code ...</p>
                <div style={{ width: 32, margin: '0 auto' }}>
                  <LoadingIndicator style={{ marginLeft: -16 }} />
                </div>
              </div>
            ) :
              (
                <Fragment>
                  <TextField
                    style={{
                      minWidth: 250,
                      background: 'transparent'
                    }}
                    id="email-address"
                    type="email"
                    label="Your email address:"
                    helperText="Please use the email address you sign in with."
                    margin="dense"
                    onChange={(e) => this.onInput(e, 'emailAddress')}
                    value={emailAddress}
                  />
                  <br />
                  <br />
                  {localstring.onboarding.account_form_request_change_password}
                </Fragment>
              )
            }
          </div>

          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              {localstring.cancel}
            </Button>
            <Button
              onClick={this.confimReset}
              color="primary"
              variant="contained"
              disabled={!this.state.isValid}
            >
              {localstring.onboarding.continue}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ResetPasswordRequest.propTypes = {
  forgot: PropTypes.bool,
  forName: PropTypes.string,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  handleReset: PropTypes.func,
  showButton: PropTypes.bool
};

export default ResetPasswordRequest;
