/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import isFunction from 'lodash.isfunction';
import localstring from '../../services/Localization';
import LoadingIndicator from '../LoadingIndicator';

class ChangePassword extends Component {
  state = {
    isLoading: false,
    isValid: false,
    password: '',
    passwordConfirm: '',
    showPassword: false,
    visibleFromButton: false,
    verificationCode: '',
    isPasswordDirty: false,
    isConfirmPasswordDirty: false,
    isVerificationCodeDirty: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.success !== this.props.success || nextProps.open !== this.props.open) {
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
    }
  }

  onInput = (e) => {
    const { name } = e.target;
    this.setState(
      {
        [name]: e.target.value
      },
      (name) => {
        this.setState({ isValid: this.validateForm() });
        if (name === 'password' && !this.state.isPasswordDirty) {
          this.setState({ isPasswordDirty: true });
        }
        if (name === 'passwordConfirm' && !this.state.isConfirmPasswordDirty) {
          this.setState({ isConfirmPasswordDirty: true });
        }
        if (name === 'verificationCode' && !this.state.isVerificationCodeDirty) {
          this.setState({ isVerificationCodeDirty: true });
        }
      }
    );
    return null;
  };

  onSuccess = () => {
    this.setState({
      isLoading: false,
      isValid: false,
      password: '',
      passwordConfirm: '',
      showPassword: false,
      visibleFromButton: false,
      verificationCode: ''
    }, () => {
      if (isFunction(this.props.onSuccess)) {
        this.props.onSuccess();
      }
    });
  }

  passwordIsStrong = () => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
    return strongRegex.test(this.state.password);
  }

  validateForm = () =>
    this.state.verificationCode.length > 0 && this.validatePasswordMatch();

  validatePasswordMatch = () =>
    this.state.password === this.state.passwordConfirm &&
    this.passwordIsStrong();

  selectChangeButton = () => {
    this.setState({ visibleFromButton: true });
  };

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  submitChangedPassword = () => {
    this.setState({ isLoading: true });
    this.props.handleReset({
      password: this.state.password,
      verificationCode: this.state.verificationCode
    });
  };

  renderSuccessDialog = () => (
    <Dialog
      aria-describedby="password-change-success-dialog"
      aria-labelledby="password-change-success-dialog-title"
      open={this.props.open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="password-change-success-dialog-title">
        {localstring.onboarding.account_form_password_change_successful}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            color: '#fff',
            fontSize: 14,
            textAlign: 'center'
          }}
        >
          {localstring.onboarding.account_form_go_to_login_new_password}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={this.onSuccess}
          color="primary"
          variant="contained"
        >
          {localstring.login}
        </Button>
      </DialogActions>
    </Dialog>
  );

  render() {
    const { forName, isSuccess, showButton } = this.props;
    const {
      // isPasswordDirty,
      // isConfirmPasswordDirty,
      // isVerificationCodeDirty,
      password,
      passwordConfirm,
      verificationCode
    } = this.state;
    if (isSuccess) {
      return this.renderSuccessDialog();
    }
    const passwordIsStrong = this.passwordIsStrong();
    const passwordsMatch = this.state.password === this.state.passwordConfirm;
    const verificationCodeIsValid = verificationCode.length === 6;
    return (
      <Fragment>
        {showButton ? (
          <div style={{ display: 'inline-block' }}>
            <Button color="primary" onClick={this.selectChangeButton}>
              {localstring.onboarding.account_form_change_password}
            </Button>
          </div>
        ) : null}
        <Dialog
          aria-describedby="change-password-dialog"
          aria-labelledby="change-password-dialog-title"
          open={this.props.open || this.state.visibleFromButton}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="change-password-dialog-title">
            {localstring.onboarding.account_form_change_password}
          </DialogTitle>
          <div
            style={{
              color: '#fff',
              fontSize: 18,
              paddingLeft: 24,
              marginTop: -20
            }}
          >
            {forName ? <span>for {forName}</span> : null}
          </div>
          <DialogContent className="edit-modal">
            {this.state.isLoading ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <p style={{ color: '#fff' }}>{localstring.onboarding.account_form_changing_password}</p>
                <div style={{ width: 32, margin: '0 auto' }}>
                  <LoadingIndicator style={{ marginLeft: -16 }} />
                </div>
              </div>
            ) : (
              <Fragment>
                <TextField
                  error={!verificationCodeIsValid}
                  style={{ width: '100%' }}
                  id="code"
                  helperText={localstring.onboarding.account_form_verification_code_email}
                  name="verificationCode"
                  type="email"
                  margin="dense"
                  label={localstring.onboarding.account_form_confirmation_code}
                  onChange={this.onInput}
                  value={verificationCode}
                />
                <TextField
                  error={!passwordIsStrong}
                  style={{ width: '100%' }}
                  id="new"
                  label={localstring.onboarding.account_form_new_password}
                  helperText={
                    !passwordIsStrong
                      ? localstring.onboarding.account_form_ensure_passwords_length
                      : localstring.onboarding.account_form_password_correct_length
                  }
                  margin="dense"
                  name="password"
                  onChange={this.onInput}
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={password}
                  InputProps={
                    password
                      ? {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ position: 'relative' }}
                          >
                            <IconButton
                              aria-label="Clear input field"
                              onClick={this.toggleShowPassword}
                              style={{
                                position: 'absolute',
                                top: -10,
                                right: -15
                              }}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      : {}
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fa fa-close" style={{ fontSize: 12 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <TextField
                  error={!passwordsMatch}
                  style={{ width: '100%' }}
                  id="confirm"
                  helperText={
                    passwordsMatch
                      ? localstring.onboarding.account_form_passwords_match
                      : localstring.onboarding.account_form_ensure_passwords_match
                  }
                  label={localstring.onboarding.account_form_confirm_password}
                  margin="dense"
                  name="passwordConfirm"
                  onChange={this.onInput}
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={passwordConfirm}
                  InputProps={
                    passwordConfirm
                      ? {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ position: 'relative' }}
                          >
                            <IconButton
                              aria-label="Clear input field"
                              onClick={this.toggleShowPassword}
                              style={{
                                position: 'absolute',
                                top: -10,
                                right: -15
                              }}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      : {}
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fa fa-close" style={{ fontSize: 12 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={!this.state.isValid || this.state.isLoading}
              onClick={this.submitChangedPassword}
              variant="contained"
            >
              {localstring.onboarding.account_form_change_password}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ChangePassword.propTypes = {
  forName: PropTypes.string,
  handleReset: PropTypes.func,
  isSuccess: PropTypes.bool,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  showButton: PropTypes.bool
};

export default ChangePassword;
