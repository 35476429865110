import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactWithMatches from '../../components/Matching/ContactWithMatches';
import MatchChart from '../../components/Matching/MatchChart';

const genericAvatar = require('../../images/img/avatar.png');

class MatchedAgentCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      agent: props.agent,
      property: props.property,
      title: props.title,
      photoURL: genericAvatar,
      high: props.agent.clientMatching.high,
      med: props.agent.clientMatching.med,
      low: props.agent.clientMatching.low,
      total:
        props.agent.clientMatching.high +
        props.agent.clientMatching.med +
        props.agent.clientMatching.low
    };
  }

  componentWillMount() {
    // const p = this.props;
    const { agent } = this.props;

    if (agent.photoURL && agent.photoURL !== null) {
      this.setState({ photoURL: agent.photoURL });
    }
  }

  render() {
    const { agent, property, title } = this.props;
    return (
      <div className="realm--agent-card">
        <div>
          <img
            src={this.state.photoURL}
            style={{
              height: 50,
              borderRadius: 60,
              marginRight: 12,
              background: '#888'
            }}
            alt="avatar"
          />
          <div className="realm--agent-details">
            {agent.fullName}
            <br />
            {agent.brokerages &&
            agent.brokerages[0] &&
            agent.brokerages[0].name ? (
                <span className="realm--agent-brokerage">
                  {agent.brokerages[0].name}
                </span>
              ) : null}
          </div>
        </div>

        <div className="realm--card-content">
          <ContactWithMatches agent={agent} property={property} title={title} />
          <div className="realm--chart-block">
            {this.state.total} Matches:
            <MatchChart
              high={this.state.high}
              med={this.state.med}
              low={this.state.low}
              small="true"
            />
          </div>
        </div>
      </div>
    );
  }
}

MatchedAgentCard.propTypes = {
  agent: PropTypes.object,
  property: PropTypes.object,
  title: PropTypes.string
};

export default MatchedAgentCard;
