import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2,
    background: 'red'
  },
  chip: {
    margin: theme.spacing.unit / 2,
    background: '#bbe',
    borderRadius: '12px'
  },
  realmQuality: {
    margin: theme.spacing.unit / 2,
    background: '#ac5f0f',
    borderRadius: '12px'
  },
  realmLifestyle: {
    margin: theme.spacing.unit / 2,
    background: '#72799a',
    borderRadius: '12px'
  },
  chip_compact: {
    display: 'inline-block',
    maxWidth: 100,
    height: 18,
    marginLeft: 4,
    padding: '4px 2px',
    fontSize: 10,
    lineHeight: 1,
    textAlign: 'center',
    background: '#bbe',
    borderRadius: 8
  }
});

class Tag extends Component {
  onDelete = () => {
    this.props.onDelete(this.props.tagName);
  }

  render() {
    const { classes, format } = this.props;
    const isCompact = format === 'compact';
    let chipClass = isCompact ? classes.chip_compact : classes.chip;
    if (format === 'realmQuality') {
      chipClass = classes.realmQuality;
    }
    if (format === 'realmLifestyle') {
      chipClass = classes.realmLifestyle;
    }
    const isReadOnly = this.props.readonly;
    return (
      <Chip
        className={chipClass}
        key={this.props.id}
        label={this.props.label}
        onClick={isCompact || isReadOnly ? undefined : this.props.onClick}
        onDelete={isCompact || isReadOnly ? undefined : this.onDelete}
      />
    );
  }
}

Tag.propTypes = {
  classes: PropTypes.object.isRequired,
  format: PropTypes.oneOf(['default', 'compact', 'realmQuality', 'realmLifestyle']),
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  readonly: PropTypes.bool,
  tagName: PropTypes.string
};

export default withStyles(styles)(Tag);
