const styles = {
  root: {
    display: 'inline-block',
    flexWrap: 'wrap',
    marginLeft: 20,
  },
  'control-label': {
    color: 'red'
  },
  buttonBlock: {
    width: '100%'
  },
  page: {
    width: '100%',
    height: '100vh',
    background: '#efefe8',
    paddingTop: 100
  },
  loginPanel: {
    margin: 'auto',
    maxWidth: 320
  },
  loadingContainer: {
    position: 'absolute',
    left: '50%',
    width: 320,
    marginLeft: -160
  },
  appBar: {
    position: 'relative',
    height: 80,
    padding: '20px 30px',
    fontSize: 30,
    background: '#ac5f0f'
  }
};

export default styles;
