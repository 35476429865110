import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import api from '../../api';

class Delete extends Component {
  constructor() {
    super();
    this.state = {
      openEditDialog: false
    };
  }

  onUpdate = async () => {
    const { user } = this.props;
    await api.post('/admin/delete', {
      ...user
    });
    this.props.onRefresh();
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ openEditDialog: false });
  };

  handleOpen = () => {
    this.setState({ openEditDialog: true });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  renderDialog = () => (
    <div>
      <Dialog
        aria-describedby="delete-dialog"
        aria-labelledby="delete-dialog-title"
        onClose={this.handleClose}
        open={this.state.openEditDialog}
      >
        <DialogTitle id="delete-dialog-title">Delete user</DialogTitle>
        <DialogContent className="edit-modal">Are you sure?</DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.onUpdate} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  render() {
    return (
      <span>
        <IconButton aria-label="Delete" onClick={this.handleOpen} class="edit">
          <DeleteIcon />
        </IconButton>
        {this.state.openEditDialog ? this.renderDialog() : null}
      </span>
    );
  }
}

Delete.propTypes = {
  onRefresh: PropTypes.func,
  user: PropTypes.object
};

export default Delete;
