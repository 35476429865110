import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

class ContactForm extends Component {
  state = {
    emailSent: false
  };

  onSend = () => {
    this.formRef.current.submit();
    this.setState({ emailSent: true });
  };

  tryAgain = (e) => {
    e.preventDefault();
    this.setState({ emailSent: false });
  };

  formRef = createRef();

  render() {
    if (this.state.emailSent) {
      return (
        <div className="realm-onboarding--email-sent">
          <h4>
            If your email was sucessfully sent, go ahead and click the OK box to
            close this dialog, otherwise{' '}
            <a href="/" onClick={this.tryAgain}>
              {' '}
              please try again
            </a>
            .
          </h4>
        </div>
      );
    }
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        open={this.props.open}
      >
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <div className="realm--white-text">
            <div className="realm-onboarding--contact-form">
              <form
                action="mailto:concierge.realm@gmail.com"
                method="GET"
                encType="text/plain"
                id="realmContactForm"
                ref={this.formRef}
              >
                <input
                  name="subject"
                  type="hidden"
                  value="REALM Onboarding Assistance Requested"
                />
                <label htmlFor="body" style={{ color: '#ccc' }}>
                  Describe in as much detail as you can the problem you are
                  having
                  <textarea
                    placeholder="Enter your message here"
                    name="body"
                    id="body"
                  />
                </label>
              </form>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSend} color="primary" variant="contained">
            Send
          </Button>
          <Button
            onClick={this.props.handleClose}
            color="default"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ContactForm.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default ContactForm;
