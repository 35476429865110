import * as storage from './storage';
import getRoles from '../utils/get-roles';
import {
  ACCEPT_COOKIES,
  AUTHENTICATE_SUCCESS,
  COMPLETE_ONBOARDING,
  FETCH_AGENT_SUCCESS,
  FETCH_BROKERAGES_SUCCESS,
  FETCH_PERSON_SUCCESS,
  SWITCH_CURRENCY,
  SWITCH_DIRECTION,
  SWITCH_LOCALE,
  SWITCH_MEASURE,
  UPDATE_AGENT_SUCCESS,
  UPDATE_PERSON_SUCCESS
} from '../constants';

export default function persistenceHandler(next) {
  return (reducer, initialState) => {
    const store = next(reducer, initialState);

    return Object.assign({}, store, {
      dispatch(action) {
        store.dispatch(action);
        const wrappedAction = action.action;
        if (wrappedAction) {
          if (wrappedAction.type === ACCEPT_COOKIES) {
            storage.put('acceptCookies', wrappedAction.payload);
          }
          if (wrappedAction.type === COMPLETE_ONBOARDING) {
            storage.remove('isOnboarding');
          }
          if (wrappedAction.type === AUTHENTICATE_SUCCESS) {
            storage.put('token', wrappedAction.payload.token);
            storage.put('me', JSON.stringify(wrappedAction.payload.user));
            storage.put('isAdmin', getRoles(wrappedAction.payload, 'admin'));
            storage.put('isAgent', getRoles(wrappedAction.payload, 'agent'));
            storage.put(
              'isConcierge',
              getRoles(wrappedAction.payload, 'concierge')
            );
            storage.put('isDemo', getRoles(wrappedAction.payload, 'demo'));
            storage.put(
              'isRealmAdmin',
              getRoles(wrappedAction.payload, 'realmAdmin')
            );
          }
          if (wrappedAction.type === FETCH_AGENT_SUCCESS) {
            storage.put('agentData', JSON.stringify(wrappedAction.payload));
            if (!storage.get('avatar') || storage.get('avatar') === 'string') {
              storage.put('avatar', wrappedAction.payload.photoURL);
            }
          }
          if (wrappedAction.type === FETCH_BROKERAGES_SUCCESS) {
            storage.put('brokerages', JSON.stringify(wrappedAction.payload.brokerages));
            storage.put('totalBrokerages', JSON.stringify(wrappedAction.payload.totalBrokerages));
          }
          if (wrappedAction.type === FETCH_PERSON_SUCCESS) {
            storage.put('me', JSON.stringify(wrappedAction.payload));
            storage.put('person', JSON.stringify(wrappedAction.payload.person));
            const photoURL =
              wrappedAction.payload.person.realmData &&
              wrappedAction.payload.person.realmData.photoUrl
                ? wrappedAction.payload.person.realmData.photoUrl
                : '';
            storage.put('avatar', photoURL || '');
          }
          if (wrappedAction.type === SWITCH_CURRENCY) {
            storage.put('currency', wrappedAction.payload);
          }
          if (wrappedAction.type === SWITCH_DIRECTION) {
            storage.put('isRTL', wrappedAction.payload);
          }
          if (wrappedAction.type === SWITCH_LOCALE) {
            storage.put('locale', wrappedAction.payload);
          }
          if (wrappedAction.type === SWITCH_MEASURE) {
            storage.put('measure', wrappedAction.payload);
          }
          if (wrappedAction.type === UPDATE_AGENT_SUCCESS) {
            storage.put('agentData', JSON.stringify(wrappedAction.payload));
          }
          if (wrappedAction.type === UPDATE_PERSON_SUCCESS) {
            storage.put('person', JSON.stringify(wrappedAction.payload));
          }
        }
        return wrappedAction;
      }
    });
  };
}
