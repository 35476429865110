/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash.merge';
import isFunction from 'lodash.isfunction';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import api from '../../api';
import * as storage from '../../persistence/storage';
import localstring from '../../services/Localization';
import LoadingIndicator from '../LoadingIndicator';

class AddAgentDialog extends Component {
  state = {
    brokerages: []
  };

  delay = null;

  componentWillMount() {
    this.setInitialState();
    this.getBrokerages();
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
      this.delay = null;
    }
  }

  setInitialState() {
    this.setState({
      agentBrokerage: '',
      agentFirstName: '',
      agentLastName: '',
      agentMiddleName: '',
      agentPrefix: '',
      agentSuffix: '',
      agentEmail: '',
      agentEmailType: 'Professional',
      agentPhone: '',
      agentPhoneType: 'Work',
      attemptedAgentCreation: false,
      agentError: false,
      agentSuccessful: false,
      agentCreateMessage: '',
      emailTypes: ['Personal', 'Professional', 'Other'],
      phoneTypes: ['Home', 'Work', 'Mobile'],
      prefixTypes: ['', 'Mr.', 'Ms.', 'Miss', 'Mrs.'],
      suffixTypes: ['', 'Jr.', 'Sr.'],
      isDirty: false,
      isLoading: false,
      isValidForm: false
    });
  }

  getBrokerages = async () => {
    let brokerageData = JSON.parse(storage.get('brokerages')) || null;
    if (!brokerageData) {
      const response = await api.post('/brokerages/filter', {
        page: 1,
        limit: 500
      });
      brokerageData = response.data.data.data;
      this.setState({
        brokerages: brokerageData.filter((record) => !record.deletedAt),
        isLoading: false
      });
    } else {
      this.setState({
        brokerages: brokerageData.filter((record) => !record.deletedAt),
        isLoading: false
      });
    }
  };

  isValidForm = () => {
    const {
      agentBrokerage,
      agentFirstName,
      agentLastName,
      agentEmail,
      agentPhone
    } = this.state;
    const hasValidRequired =
      agentBrokerage !== '' &&
      agentFirstName !== '' &&
      agentLastName !== '' &&
      this.isValidEmail(agentEmail);
    const hasValidOptional = agentPhone === '' || this.isValidPhone(agentPhone);
    return hasValidRequired && hasValidOptional;
  };

  isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  isValidPhone = (phone) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone);

  onInput = (e) => {
    const newValue = {};
    newValue[e.target.name] = e.target.value;
    const newState = merge({}, this.state, newValue);
    this.setState(newState, () => {
      this.setState({
        isDirty: true,
        isValidForm: this.isValidForm()
      });
    });
  };

  createAgent = () => {
    const phone = this.state.agentPhone
      ? {
          phones: [
            {
              phoneType: this.state.agentPhoneType,
              number: this.state.agentPhone
            }
          ]
        }
      : null;
    const newAgent = {
      name: {
        prefix: this.state.agentPrefix,
        firstName: this.state.agentFirstName,
        middleName: this.state.agentMiddleName,
        lastName: this.state.agentLastName,
        suffix: this.state.agentSuffix,
        fullName: this.state.agentMiddleName
          ? `${this.state.agentFirstName} ${this.state.agentMiddleName} ${
              this.state.agentLastName
            }`
          : `${this.state.agentFirstName} ${this.state.agentLastName}`
      },
      primaryEmail: this.state.agentEmail,
      emails: [
        {
          address: this.state.agentEmail,
          emailType: this.state.agentEmailType
        }
      ]
    };
    const agentRecord = Object.assign({}, newAgent, phone);
    if (this.props.handleCreate) {
      this.props.handleCreate(
        agentRecord,
        this.state.agentBrokerage,
        this.onSuccess,
        this.onFailure
      );
      this.setState({
        isLoading: true
      });
    }
  };

  onSuccess = () => {
    this.setState({
      isLoading: false,
      attemptedAgentCreation: true,
      agentError: false,
      agentSuccessful: true,
      agentCreateMessage:
        'Congratulations! You have successfully created a new agent.'
    });
  };

  onFailure = (err) => {
    this.setState({
      isLoading: false,
      attemptedAgentCreation: true,
      agentSuccessful: false,
      agentError: true,
      agentCreateMessage: err.response.data.msg
    });
  };

  onRetry = () => {
    this.setState({
      isLoading: false,
      attemptedAgentCreation: false,
      agentSuccessful: false,
      agentError: false,
      agentCreateMessage: ''
    });
  };

  onClose = () => {
    setTimeout(() => {
      this.setInitialState();
    }, 1000);
    if (isFunction(this.props.handleClose)) {
      this.props.handleClose();
    }
  };

  continue = () => {
    this.state.attemptedAgentCreation ? this.onRetry() : this.createAgent();
  }

  render() {
    return (
      <Dialog
        aria-describedby="add-agent-dialog"
        aria-labelledby="add-agent-dialog-title"
        open={this.props.open || false}
        disableBackdropClick
        disableEscapeKeyDown
        style={{ minWidth: 500 }}
      >
        <DialogTitle id="add-agent-dialog-title">
          {localstring.create_new_agent}{' '}{this.state.agentError && 'Error'}
        </DialogTitle>
        <DialogContent className="edit-modal">
          {this.state.isLoading ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div style={{ width: 32, margin: '0 auto' }}>
                <LoadingIndicator style={{ marginLeft: -16 }} />
              </div>
            </div>
          ) : (
            this.renderDialogContent()
          )}
        </DialogContent>
        <DialogActions>
          {!this.state.agentSuccessful && (
            <Fragment>
              <Button
                onClick={this.onClose}
                color="default"
                variant="contained"
                style={{ marginRight: 12 }}
              >
                {localstring.cancel}
              </Button>
              <Button
                onClick={this.continue}
                color="primary"
                variant="contained"
                disabled={!this.state.isValidForm}
              >
                {localstring.onboarding.continue}
              </Button>
            </Fragment>
          )}
          {this.state.agentSuccessful && (
            <Button
              onClick={this.onClose}
              color="primary"
              variant="contained"
              disabled={!this.state.isValidForm}
            >
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  renderDialogContent = () => {
    if (this.state.attemptedAgentCreation) {
      return this.renderResult();
    }
    return this.renderForm();
  };

  renderResult = () => (
    <div>
      <p>{this.state.agentCreateMessage}</p>
      {this.state.agentError && <p>Do you wish to try again?</p>}
    </div>
  );

  renderForm = () => {
    return (
      <Fragment>
        <div style={{ padding: '10px 0', marginBottom: 20 }}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel className="muiInputLabel" htmlFor="agentBrokerage">
              {localstring.select_brokerage}
            </InputLabel>
            <Select
              value={this.state.agentBrokerage}
              onChange={this.onInput}
              inputProps={{
                name: 'agentBrokerage',
                id: 'selected-brokerage'
              }}
            >
              {this.state.brokerages.map((brokerage) => (
                <MenuItem key={brokerage._id} value={brokerage._id}>
                  {brokerage.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div>
          <FormControl style={{ width: '50%' }}>
            <InputLabel className="muiInputLabel" htmlFor="agentPrefix">
              {localstring.prefix}
            </InputLabel>
            <Select
              value={this.state.agentPrefix}
              onChange={this.onInput}
              inputProps={{
                name: 'agentPrefix',
                id: 'selected-prefix'
              }}
            >
              {this.state.prefixTypes.map((prefix) => (
                <MenuItem key={prefix} value={prefix}>
                  {prefix}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="agentFirstName"
            fullWidth
            helperText=""
            label={localstring.first_name}
            margin="dense"
            name="agentFirstName"
            onChange={this.onInput}
            type="text"
            value={this.state.agentFirstName}
          />
          <TextField
            id="agentMiddleName"
            fullWidth
            helperText="Middle name is optional"
            label={localstring.middle_name}
            margin="dense"
            name="agentMiddleName"
            onChange={this.onInput}
            type="text"
            value={this.state.agentMiddleName}
          />
          <TextField
            id="agentLastName"
            fullWidth
            helperText=""
            label={localstring.last_name}
            margin="dense"
            name="agentLastName"
            onChange={this.onInput}
            type="text"
            value={this.state.agentLastName}
          />
        </div>
        <div>
          <FormControl style={{ width: '50%' }}>
            <InputLabel className="muiInputLabel" htmlFor="agentSuffix">
              {localstring.suffix}
            </InputLabel>
            <Select
              value={this.state.agentSuffix}
              onChange={this.onInput}
              inputProps={{
                name: 'agentSuffix',
                id: 'selected-suffix'
              }}
            >
              {this.state.suffixTypes.map((suffix) => (
                <MenuItem key={suffix} value={suffix}>
                  {suffix}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: 30, padding: '10px 0' }}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel className="muiInputLabel" htmlFor="agentEmailType">
              {localstring.email_type}
            </InputLabel>
            <Select
              label={localstring.email_type}
              value={this.state.agentEmailType}
              onChange={this.onInput}
              inputProps={{
                name: 'agentEmailType',
                id: 'selected-emailType'
              }}
            >
              {this.state.emailTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="agentEmail"
            fullWidth
            helperText={localstring.valid_email_required}
            label={localstring.onboarding.email}
            margin="dense"
            name="agentEmail"
            onChange={this.onInput}
            type="text"
            value={this.state.agentEmail}
          />
        </div>
        <div style={{ marginTop: 20, padding: '10px 0' }}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel className="muiInputLabel" htmlFor="agentPhoneType">
              {localstring.phone_type}
            </InputLabel>
            <Select
              label={localstring.phone_type}
              value={this.state.agentPhoneType}
              onChange={this.onInput}
              inputProps={{
                name: 'agentPhoneType',
                id: 'selected-phoneType'
              }}
            >
              {this.state.phoneTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id="agentPhone"
              fullWidth
              helperText={localstring.phone_optional}
              label={localstring.phone_number}
              margin="dense"
              name="agentPhone"
              onChange={this.onInput}
              type="text"
              value={this.state.agentPhone}
            />
          </FormControl>
        </div>
      </Fragment>
    );
  };
}

AddAgentDialog.propTypes = {
  handleCreate: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default AddAgentDialog;
