import axios from 'axios';
import * as constants from '../constants';
import store from '../store';

export const authenticate = (authData) => (dispatch) => {
  dispatch({ type: constants.AUTHENTICATE_PENDING, payload: {} });
  axios
    .post(`${constants.API_ROOT}/user/authenticate`, {
      ...authData
    })
    .then((response) => {
      dispatch({
        type: constants.AUTHENTICATE_SUCCESS,
        payload: response.data
      });
    })
    .catch((err) => {
      dispatch({ type: constants.AUTHENTICATE_REJECTED, payload: err });
    });
};

export const getAgentData = () => (dispatch) => {
  dispatch({ type: constants.FETCH_AGENT_PENDING, payload: {} });
  const { token, currentUser: { person: { _id: id } } } = store.getState().session;
  if (!token) {
    dispatch({
      type: constants.FETCH_AGENT_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios
      .get(`${constants.API_ROOT}/agents/person/${id}`)
      .then((response) => {
        dispatch({
          type: constants.FETCH_AGENT_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_AGENT_REJECTED, payload: err });
      });
  }
};

export const getBrokerageData = ( page = 1, limit = 100, showArchived = false ) => (dispatch) => {
  dispatch({ type: constants.FETCH_BROKERAGES_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: constants.FETCH_BROKERAGES_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios
      .post(`${constants.API_ROOT}/brokerages/filter/`, { page, limit, showArchived })
      .then((response) => {
        const brokerageData = response.data.data.data;
        const filteredData = brokerageData.filter((record) => !record.deletedAt);
        dispatch({
          type: constants.FETCH_BROKERAGES_SUCCESS,
          payload: { brokerages: filteredData, totalBrokerages: filteredData.length }
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_BROKERAGES_REJECTED, payload: err });
      });
  }
};

export const getPersonData = () => (dispatch) => {
  dispatch({ type: constants.FETCH_PERSON_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: constants.FETCH_PERSON_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .get(`${constants.API_ROOT}/user/me/`)
      .then((response) => {
        dispatch({
          type: constants.FETCH_PERSON_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_PERSON_REJECTED, payload: err });
      });
  }
};

export const updateAgent = (data) => (dispatch) => {
  dispatch({ type: constants.UPDATE_AGENT_PENDING, payload: {} });
  const { token, agentData: { _id: id } } = store.getState().session;
  if (!token) {
    dispatch({
      type: constants.UPDATE_AGENT_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .put(`${constants.API_ROOT}/agents/${id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_AGENT_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch((err) => {
        dispatch({ type: constants.UPDATE_AGENT_REJECTED, payload: err });
      });
  }
};

export const updateBrokerage = (id, data) => (dispatch) => {
  dispatch({ type: constants.UPDATE_BROKERAGE_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: constants.UPDATE_BROKERAGE_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .put(`${constants.API_ROOT}/brokerages/${id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_BROKERAGE_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch((err) => {
        dispatch({ type: constants.UPDATE_BROKERAGE_REJECTED, payload: err });
      });
  }
};

export const updatePerson = (data) => (dispatch) => {
  dispatch({ type: constants.UPDATE_PERSON_PENDING, payload: {} });
  const { token, currentPerson: { _id: id } } = store.getState().session;
  if (!token) {
    dispatch({
      type: constants.UPDATE_PERSON_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .put(`${constants.API_ROOT}/people/${id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_PERSON_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch((err) => {
        dispatch({ type: constants.UPDATE_PERSON_REJECTED, payload: err });
      });
  }
};

export const retryAuthentication = () => ({
  type: constants.AUTHENTICATE_RETRY,
  payload: {}
});

export function clearSession() {
  return { type: constants.CLEAR_SESSION, payload: null };
}

export function completeOnboarding() {
  return { type: constants.COMPLETE_ONBOARDING, payload: null };
}

export function acceptCookies() {
  return { type: constants.ACCEPT_COOKIES, payload: true };
}
