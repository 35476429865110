import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import localstring from '../../services/Localization';

const ErrorDialog = (props) => (
  <Dialog
    open={props.open || false}
    onClose={props.handleClose}
    aria-labelledby="delete-dialog-title"
    aria-describedby="delete-dialog"
  >
    <DialogTitle id="delete-dialog-title">{localstring.error}</DialogTitle>
    <DialogContent className="edit-modal">
      <DialogContentText className="error-msg">
        {props.message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {props.handleRetry ? (
        <Fragment>
          <Button onClick={props.handleClose} color="primary">
            {localstring.cancel}
          </Button>
          <Button onClick={props.handleRetry} color="primary">
            {props.okBtnOverride ? props.okBtnOverride : localstring.retry}
          </Button>
        </Fragment>
      ) : (
        <Button onClick={props.handleClose} color="primary">
          {props.okBtnOverride ? props.okBtnOverride : localstring.close}
        </Button>
      )}

    </DialogActions>
  </Dialog>
);

ErrorDialog.propTypes = {
  handleClose: PropTypes.func,
  handleRetry: PropTypes.func,
  message: PropTypes.string,
  okBtnOverride: PropTypes.string,
  open: PropTypes.bool
};

export default ErrorDialog;
