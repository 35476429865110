import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <div className="realm--page404">
    404 Error
    <br />
    <div className="realm--message404">
      Realm is timeless but the page you requested is not. Start over below.
    </div>
    <Link to="/dashboard" className="realm--button404">
      To the Realm
    </Link>
  </div>
);

export default NotFoundPage;
