const genericHouse = require('../../images/img/estateblur2.jpg');

const matchingStyles = () => ({
  root: {},
  content: {},
  aboveFold: {
    background: `url(${genericHouse}) center center`,
    backgroundSize: '100% auto !important',
    backgroundRepeat: 'no-repeat !important',
  },
  caLabel: {
    fontSize: 18,
    fontWeight: 400
  },
  matchedFrom: {
    fontSize: 12,
    fontWeight: 400
  }
});

export default matchingStyles;
