import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import api from '../../api';

class Edit extends Component {
  state = {
    editUser: {},
    openEditDialog: false
  };
  // WARNING - Do not change username.
  // WARNING - Username is permenant in Cognito. Only email can be updated there.
  // WARNING - a new user account would have to be created in order to implement username changes

  onUpdate = async () => {
    await api.post('/admin/update', {
      ...JSON.parse(this.state.editUser)
    });
    this.props.onRefresh();
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ openEditDialog: false });
  };

  handleOpen = () => {
    const { user } = this.props;
    this.setState({
      editUser: JSON.stringify(user._original, null, 2),
      openEditDialog: true
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  renderDialog() {
    const { editUser } = this.state;

    return (
      <div>
        <Dialog
          aria-describedby="delete-dialog"
          aria-labelledby="delete-dialog-title"
          onClose={this.handleClose}
          open={this.state.openEditDialog}
        >
          <DialogTitle id="delete-dialog-title">Edit user</DialogTitle>
          <DialogContent className="edit-modal">
            <textarea
              className="json-input"
              id="editUser"
              onChange={this.handleChange}
              value={editUser}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onUpdate} color="default">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    return (
      <span>
        <IconButton aria-label="Edit" class="edit" onClick={this.handleOpen}>
          <EditIcon />
        </IconButton>
        {this.state.openEditDialog ? this.renderDialog() : null}
      </span>
    );
  }
}

Edit.propTypes = {
  onRefresh: PropTypes.func,
  user: PropTypes.object
};

export default Edit;
