import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

import localstring from '../../services/Localization';

class Sorter extends Component {
  state = {
    selected: '',
    selectedSort: ''
  };

  componentWillMount() {
    const { selected } = this.props;
    if (selected && selected !== '' && selected !== 'All') {
      this.setState({ selected });
    }
  }

  handleSelection = (event) => {
    if (typeof this.props.handleSorterPicked !== 'function') {
      return null;
    }
    this.setState({ [event.target.name]: event.target.value });
    this.props.handleSorterPicked(event);
    return null;
  };

  render() {
    const { sortList } = this.props;
    return (
      <Fragment>
        <span>{this.state.selected}</span>
        <form autoComplete="off">
          <FormControl
            style={{ minWidth: 170, fontSize: 30 }}
            className="realm--sorter-control"
          >
            <InputLabel className="muiInputLabel">
              {localstring.sort}
            </InputLabel>
            <Select
              className="selectWhite"
              fullWidth
              value={this.state.selectedSort}
              onChange={this.handleSelection}
              inputProps={{
                name: 'selectedSort',
                id: 'sort-simple'
              }}
            >
              {sortList.map((sortItem) => (
                <MenuItem key={sortItem.name} value={sortItem.index}>
                  {sortItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </Fragment>
    );
  }
}

Sorter.propTypes = {
  handleSorterPicked: PropTypes.func,
  sortList: PropTypes.array.isRequired,
  selected: PropTypes.bool
};

export default Sorter;
