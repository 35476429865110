import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import merge from 'lodash.merge';
import Pagination from 'material-ui-flat-pagination';

import {
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField
} from '@material-ui/core';

import api from '../../api';
import genericHouse from '../../images/img/estateblur2.jpg';
import getParameterByName from '../../utils/get-parameter-by-name';
import localstring from '../../services/Localization';
import numberWithCommas from '../../utils/number-with-commas';

import AddListingDialog from '../../components/Dialogs/AddListingDialog';
import BrokeragePicker from '../../components/Brokerages/BrokeragePicker';
import ErrorDialog from '../../components/Dialogs/ErrorDialog';
import HouseOfCard from '../../components/Matching/HouseOfCard';
import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';
import Sorter from '../../components/Sorter';
import Tag from '../../components/Tags/Tag';
import TagDialog from '../../components/Tags/TagDialog';
import TagList from '../../components/Tags/TagList';
import ViewToggle from '../../components/PageControls/ViewToggle';

class Properties extends Component {
  state = {
    data: [],
    offset: 0,
    totalRecords: 0,
    getFilterParams: {
      page: 1, // from 1 to n
      limit: 25, // max number of results to return
      filter: null, // in case we want to call a specific {<field>:value} as if we're creating the mongo query
      sortBy: 'price.amount', // column to sort by
      sortDir: -1, // direction: 1 ascending, -1 descending
      brokerage: null, // brokerageID to filter by
      tags: [], // tags array to filter by
      searchAddress: '',
      searchName: '',
      showArchived: this.props.session.isAdmin
    },
    errorMessage: '',
    gridView: false,
    isLoading: false,
    isError: false,
    noRecordsFound: false,
    selectedAgent: null,
    showMine: false,
    showToggle: this.props.session.isAgent,
    tagKey: Date.now(),
    openListingDialog: false
  };

  _mounted = false;

  componentWillMount() {
    const { session } = this.props;
    const { getFilterParams } = this.state;
    const agentIdFromQS = getParameterByName('agent');
    const currentAgentId = session.agentData && session.agentData._id;
    const agentID = agentIdFromQS || currentAgentId;
    const newState = agentID
      ? merge({}, this.state.getFilterParams, {
        filter: {
          listingAgents: [agentID]
        }
      })
      : getFilterParams;
    this.setState({
      getFilterParams: newState,
      selectedAgent: agentID,
      showMine: agentIdFromQS
        ? agentIdFromQS === currentAgentId
        : session.isAgent
    });
  }

  componentDidMount() {
    this._mounted = true;
    this.getProperties();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getProperties = async () => {
    const { getFilterParams } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await api.post('/listings/filter', getFilterParams);
      const { data } = response.data.data;
      if (this._mounted) {
        this.setState({
          data,
          isLoading: false,
          totalRecords: response.data.data.total,
          noRecordsFound: response.data.data.total === 0
        });
      }
    } catch (err) {
      this.setState({
        errorMessage: `There was an problem with the request: ${err}. Please contact REALM support if you continue to have problems.`,
        isError: true
      });
    }
  };

  reload = () => {
    this.getProperties();
  };

  toggleView = (isGridView) => {
    this.setState({ isLoading: true, gridView: isGridView });
    this.getProperties();
  };

  // PAGINATION
  nextPage = (offset) => {
    const { limit } = this.state.getFilterParams;
    const newState = merge({}, this.state.getFilterParams, {
      page: offset / limit + 1
    });
    this.setState({ offset, getFilterParams: newState }, () => {
      this.getProperties();
    });
  };

  // FILTERS
  handleShowArchived = () => {
    const { getFilterParams } = this.state;
    const newState = merge({}, this.state.getFilterParams, {
        showArchived: !this.state.getFilterParams.showArchived,
        page: 0
      });

    this.setState(
      {
        getFilterParams: newState,
      },
      () => {
        this.getProperties();
        }
    )
  }


  handleSwitch = () => {
    this.setState(
      {
        showMine: !this.state.showMine
      },
      () => {
        const newState = this.state.showMine
          ? Object.assign({}, this.state.getFilterParams, {
            filter: { listingAgents: [this.state.selectedAgent] },
            searchAddress: '',
            searchName: '',
            tags: [],
            tagKey: Date.now()
          })
          : Object.assign({}, this.state.getFilterParams, {
            filter: null,
            searchAddress: '',
            searchName: '',
            tags: [],
            tagKey: Date.now()
          });

        this.setState(
          {
            getFilterParams: newState,
            offset: 0
          },
          () => {
            this.getProperties();
          }
        );
      }
    );
  };

  setBrokerageFilter = (e) => {
    const { value } = e.target;
    const newState =
      value === 'All'
        ? Object.assign({}, this.state.getFilterParams, { filter: null })
        : merge({}, this.state.getFilterParams, {
          filter: { listingBrokerage: value },
          page: 1
        });
    this.setState(
      {
        getFilterParams: newState,
        offset: 0
      },
      () => {
        this.getProperties();
      }
    );
  };

  applyAddressFilter = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      this.getProperties();
    }
    return null;
  };

  applyAgentNameFilter = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      this.getProperties();
    }
    return null;
  };

  applyTagFilter = () => {
    this.getProperties();
  };

  clearAddressFilter = () => {
    const newState = merge({}, this.state.getFilterParams, {
      searchAddress: '',
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 }, () => {
      this.getProperties();
    });
  };

  clearAgentNameFilter = () => {
    const newState = merge({}, this.state.getFilterParams, {
      searchName: '',
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 }, () => {
      this.getProperties();
    });
  };

  clearTagFilter = () => {
    const newState = Object.assign({}, this.state.getFilterParams, {
      tags: [],
      page: 1
    });
    this.setState(
      {
        getFilterParams: newState,
        offset: 0
      },
      () => {
        this.setState({
          tagKey: Date.now()
        });
        this.getProperties();
      }
    );
  };

  setAddressFilter = (e) => {
    const searchAddress = e.target.value.toLowerCase();
    const newState = merge({}, this.state.getFilterParams, {
      searchAddress,
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 });
  };

  setAgentNameFilter = (e) => {
    const searchName = e.target.value.toLowerCase();
    const newState = merge({}, this.state.getFilterParams, {
      searchName,
      page: 1
    });
    this.setState({ getFilterParams: newState, offset: 0 });
  };

  setTagFilter = (tags = []) => {
    const newState = Object.assign({}, this.state.getFilterParams, {
      tags,
      page: 1
    });
    this.setState({
      getFilterParams: newState,
      offset: 0
    });
  };

  // HANDLE SORT
  sortList = [
    { index: 0, name: 'Highest Price', field: 'price.amount', order: -1 },
    { index: 1, name: 'Lowest Price', field: 'price.amount', order: 1 },
    { index: 2, name: 'Address (A-Z)', field: 'fullAddress', order: 1 },
    { index: 3, name: 'Address (Z-A)', field: 'fullAddress', order: -1 }
  ];

  handleSort = (event) => {
    const index = event.target.value;
    const newSort = this.sortList[index].order;
    const newSortField = this.sortList[index].field;

    const newState = Object.assign({}, this.state.getFilterParams, {
      page: 1,
      sortBy: newSortField,
      sortDir: newSort
    });
    this.setState({ getFilterParams: newState, offset: 0 }, () => {
      this.getProperties();
    });
  };

  // DIALOGS
  closeErrorDialog = () => {
    this.setState({
      errorMessage: '',
      isError: false
    });
  };

  // ************************************
  //
  // RENDER
  //
  // ************************************
  renderCards = (data) => data.map((card) => <HouseOfCard property={card} />);

  renderView = () => {
    const {
      data,
      gridView,
      getFilterParams,
      noRecordsFound
    } = this.state;
    if (noRecordsFound) {
      return (
        <div className="realm--no-records-found">
          <div className="realm--no-records-found-message">
            <p>
              <strong>No records were found.</strong>
            </p>
          </div>
        </div>
      );
    }
    if (gridView || this.props.application.isMobileOnly) {
      return (
        <div className="realm--grid-container">
          <Grid container spacing={24}>
            {data && this.renderCards(data)}
          </Grid>
        </div>
      );
    }
    return (
      <ReactTable
        className="-striped -highlight"
        data={data}
        defaultPageSize={getFilterParams.limit}
        filterable={false}
        showPagination={false}
        sortable={false}
        columns={[
          {
            columns: [
              {
                id: 'image',
                Header: '',
                accessor: '',
                maxWidth: 200,
                Cell: (cellinfo) => (
                  <span>
                    {cellinfo.original.pictures &&
                    cellinfo.original.pictures.length > 0 ?
                      (
                        <img
                          src={cellinfo.original.pictures[0].pictureUrl || genericHouse}
                          style={{ width: 150 }}
                          alt="property"
                        />
                      ) : (
                        <img
                          src={genericHouse}
                          style={{ width: 150 }}
                          alt="property"
                        />
                      )}
                  </span>
                )
              },
              {
                id: 'address',
                Header: 'Address',
                accessor: '',
                maxWidth: 250,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original.property &&
                      cellInfo.original.property.streetAddress &&
                      cellInfo.original.property.streetAddress}
                    {cellInfo.original.property && (
                      <div>
                        {cellInfo.original.property.city}{' '}
                        {cellInfo.original.property.state},{' '}
                        {cellInfo.original.property.zipcode}
                      </div>
                    )}
                    <br />
                    <br />
                    <a href={cellInfo.original.listingUrl}>
                      {localstring.link_to_full_listing}
                    </a>
                  </div>
                )
              },
              {
                id: 'propertyStatus',
                Header: 'Status',
                accessor: 'status',
                maxWidth: 100
              },
              {
                id: 'listingType',
                Header: 'Listing Type',
                accessor: 'listingType',
                maxWidth: 100
              },
              {
                id: 'price',
                Header: 'Price',
                accessor: 'price.amount',
                maxWidth: 150,
                Cell: (cellInfo) => (
                  <div>
                    {cellInfo.original.price &&
                      cellInfo.original.price.amount > 0 && (
                      <span>
                        $ {numberWithCommas(cellInfo.original.price.amount)}
                      </span>
                    )}
                  </div>
                )
              },
              {
                id: 'brokerage',
                Header: 'Brokerage',
                maxWidth: 250,
                show: !this.state.showMine,
                accessor: (n) => n.listingBrokerage,
                Cell: (cellInfo) => (
                  <div style={{ whiteSpace: 'normal' }}>
                    {cellInfo.original.listingBrokerage &&
                      cellInfo.original.listingBrokerage.name &&
                      cellInfo.original.listingBrokerage.name}
                  </div>
                )
              },
              {
                id: 'agent',
                Header: 'Agent',
                maxWidth: 200,
                accessor: (n) => n.listingAgents,
                show: !this.state.showMine,
                Cell: (cellInfo) => (
                  <div style={{ whiteSpace: 'normal' }}>
                    {cellInfo.original.listingAgents &&
                      cellInfo.original.listingAgents[0] &&
                      cellInfo.original.listingAgents[0].person &&
                      cellInfo.original.listingAgents[0].person.name &&
                      cellInfo.original.listingAgents[0].person.name.firstName}
                    &nbsp;
                    {cellInfo.original.listingAgents &&
                      cellInfo.original.listingAgents[0] &&
                      cellInfo.original.listingAgents[0].person &&
                      cellInfo.original.listingAgents[0].person.name &&
                      cellInfo.original.listingAgents[0].person.name.lastName &&
                      cellInfo.original.listingAgents[0].person.name.lastName}
                    {cellInfo.original.listingEmail && (
                      <p>
                        <a href={`mailto:${cellInfo.original.listingEmail}`}>
                          {localstring.email_agent}
                        </a>
                      </p>
                    )}
                  </div>
                )
              },
              {
                id: 'propertyTags',
                Header: 'Matching Qualities',
                accessor: '',
                Cell: (cellInfo) => (
                  <div
                    key={`cellfortaglist-${cellInfo.original._id}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <TagList
                      format="default"
                      id={cellInfo.original._id}
                      name={cellInfo.original.property.streetAddress}
                      type="listings"
                    />
                  </div>
                )
              },
              {
                id: 'agentEmail',
                Header: 'Agent Email',
                show: false,
                accessor: (n) => n.listingEmail,
                Cell: (cellInfo) => (
                  <div>
                    <a href={`mailto:${cellInfo.original.listingEmail}`}>
                      {cellInfo.original.listingEmail}
                    </a>
                  </div>
                )
              }
            ]
          }
        ]}
      />
    );
  };

  // ADD LISTING
  createListing = () => {
    this.setState({
      openListingDialog: true
    });
  };

  createListingClose = () => {
    this.setState({
      openListingDialog: false
    }, () => {
      this.getProperties();
    });
  };

  render() {
    const { isMobileOnly, locale } = this.props.application;
    const { agentData, isAgent } = this.props.session;
    const { getFilterParams, showMine, showToggle } = this.state;
    const tagMessage =
      getFilterParams.tags.length > 1
        ? `You are filtering on ${getFilterParams.tags.length} tags`
        : 'You are filtering on 1 tag';
    const showPagination = this.state.totalRecords > getFilterParams.limit;
    return (
      <div className="page-with-title">
        <PageTitle
          title="Properties"
          addPlus={isAgent}
          handleClick={this.createListing}
        />
        <div
          className="realm--filters"
          style={{
            opacity: this.state.isLoading ? '.5' : '1',
            pointerEvents: this.state.isLoading ? 'none' : 'auto'
          }}
        >
          {!isMobileOnly && (
            <div className="realm--filter toggle">
              <ViewToggle clickHandler={this.toggleView} gridView={false} />
            </div>
          )}
           {this.props.session.isAdmin && (
            <div className="realm--filter switch">
            <Switch
              checked={this.state.getFilterParams.showArchived}
              onChange={this.handleShowArchived}
              color="primary"
            />
            <span className="realm--property-labels">
              Show Archived
            </span>
          </div>
          )}

          <div className="realm--filter">
            <TagDialog
              existingFilterTags={getFilterParams.tags}
              filterable
              key={this.state.tagKey}
              onFilter={this.setTagFilter}
              onFilterDone={this.applyTagFilter}
              title="Filter by tags"
              type="listings"
            />
          </div>
          {showMine && (
            <div className="realm--filter">
              {getFilterParams.tags.length !== 0 && (
                <Tag
                  format="default"
                  label={tagMessage}
                  onDelete={this.clearTagFilter}
                />
              )}
            </div>
          )}
          {showToggle && (
            <div className="realm--filter switch">
              <Switch
                checked={this.state.showMine}
                onChange={this.handleSwitch}
                color="primary"
              />
              <span className="realm--property-labels">
                {localstring.my_properties}
              </span>
            </div>
          )}
          {!showMine && this.props.session.brokerages && (
            <div className="realm--filter ie">
              <BrokeragePicker
                brokerages={this.props.session.brokerages}
                handleBrokeragePicked={this.setBrokerageFilter}
              />
            </div>
          )}
          <div className="realm--filter ie">
            <Sorter
              handleSorterPicked={this.handleSort}
              sortList={this.sortList}
            />
          </div>

          <div className="realm--filter ie">
            <TextField
              id="addressSearch"
              label="Search by Address"
              name="address"
              onKeyDown={this.applyAddressFilter}
              onChange={this.setAddressFilter}
              value={getFilterParams.searchAddress}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ position: 'relative' }}
                  >
                    <IconButton
                      aria-label="Clear input field"
                      name="addressClose"
                      onClick={this.clearAddressFilter}
                      style={{ position: 'absolute', top: -10, right: -15 }}
                    >
                      <i className="fa fa-close" style={{ fontSize: 12 }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          {showPagination && (
            <div className="realm--filter">
              <Pagination
                currentPageColor="primary"
                limit={this.state.getFilterParams.limit}
                offset={this.state.offset}
                total={this.state.totalRecords}
                onClick={(e, offset) => this.nextPage(offset)}
                otherPageColor="default"
              />
            </div>
          )}
        </div>
        {this.state.isLoading ? (
          <div className="realm--loading-container">
            <LoadingIndicator />
          </div>
        ) : (
          this.renderView()
        )}
        {showPagination && (
          <Pagination
            currentPageColor="primary"
            disabled={this.state.isLoading}
            style={{ marginTop: 30, marginBottom: 30, textAlign: 'center' }}
            limit={this.state.getFilterParams.limit}
            offset={this.state.offset}
            total={this.state.totalRecords}
            onClick={(e, offset) => this.nextPage(offset)}
            otherPageColor="default"
          />
        )}
        <ErrorDialog
          handleClose={this.closeErrorDialog}
          message={this.state.errorMessage}
          open={this.state.isError}
        />
        {isAgent && (
          <AddListingDialog
            agentId={agentData._id}
            brokerage={agentData.currentBrokerage || null}
            handleClose={this.createListingClose}
            isMobile={this.props.application.isMobile}
            language={locale}
            open={this.state.openListingDialog}
          />
        )}
      </div>
    );
  }
}

Properties.propTypes = {
  application: PropTypes.object,
  session: PropTypes.object
};

export default Properties;
