import React from 'react';

const LoadingIndicator = (props) => (
  <div>
    <div style={{ margin: '2em auto', width: 200, textAlign: 'center' }}>{props.message ? <span className={props.classes}> {props.message} </span> : null}
    </div>
    <div
      className="realm--loading-indicator"
      style={props.style || undefined}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default LoadingIndicator;
