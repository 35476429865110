import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import isFunction from 'lodash.isfunction';
import AppData from '../../services/AppData';
import Tag from './Tag';
import AddTags from './AddTags';
import localstrings from '../../services/Localization';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2,
    background: 'red'
  },
  chip: {
    margin: theme.spacing.unit / 2,
    background: '#bbe',
    borderRadius: 12
  },
  medium: {
    margin: theme.spacing.unit / 2,
    borderRadius: 0,
    width: 'auto',
    height: 150,
    overflow: 'auto',
    display: 'block'
  },
  compact: {
    margin: theme.spacing.unit / 2,
    borderRadius: 0,
    width: 'auto',
    display: 'block'
  }
});

class TagList extends Component {
  state = {
    initLoad: false,
    openSelector: false,
    selectedTags: [],
    tagList: null
  };

  componentDidMount() {
    this.loadTags('onmount');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.initLoad && (prevState.selectedTags.length !== this.state.selectedTags.length)) {
      if (isFunction(this.props.callbackOnUpdate)) {
        this.props.callbackOnUpdate();
      }
    }
  }

  onCallAddTagsOpen = () => {
    this.setState({ openSelector: true });
  };

  getTag = (tagName) => {
    const { tagList } = this.state;
    let returnItem = null;
    if (tagList.tagDefinitions) {
      const tagDefinitions = Object.keys(tagList.tagDefinitions);
      tagDefinitions.map((tagCategory) => {
        tagList.tagDefinitions[tagCategory].map((item) => {
          if (item.tagName === tagName) {
            returnItem = item;
          }
          return null;
        });
        return null;
      });
    }
    return returnItem || { id: 0, label: 'na', tagName: 'na' };
  };

  async loadTags(justMounted) {
    await AppData.loadTags(this.props.type);
    await AppData.loadSelectedTags(this.props.type, this.props.id);
    const { selectedTags, tagCategories } = AppData;
    const Tags = { tagDefinitions: tagCategories };
    this.Tags = Tags;
    this.SelectedTags = selectedTags;
    this.setState({
      selectedTags,
      tagList: Tags
    }, () => {
      this.setState({ initLoad: true });
    });
    if (!justMounted && this.props.callback) {
      this.props.callback();
    }
  }

  handleDelete = (tagId) => {
    const { selectedTags } = this.state;
    const i = selectedTags.indexOf(tagId);
    if (tagId) {
      selectedTags.splice(i, 1);
    }
    AppData.deleteUserTag(this.props.type, this.props.id, tagId);
    this.setState({ selectedTags }, () => {
      this.loadTags();
      if (isFunction(this.props.callbackOnUpdate)) {
        this.props.callbackOnUpdate();
      }
    });
  };

  render() {
    const {
      format,
      id,
      name,
      type,
      maxHeight
    } = this.props;
    let { title } = this.props;
    const { selectedTags } = this.state;
    const isCompact = format === 'compact';
    const isReadOnly = this.props.readonly || false;
    const listStyle = { textAlign: 'center', whiteSpace: 'normal' };
    if (maxHeight) {
      listStyle.maxHeight = maxHeight;
      listStyle.overflow = 'auto';
    }

    // first deal with a no-tags situation
    if (selectedTags === null || selectedTags.length === 0) {
      if (isReadOnly) {
        return (
          <Fragment>
            <p
              style={{
                color: 'rgba(255,255,255,.5)',
                textTransform: 'uppercase'
              }}
            >
              No tags selected
            </p>
            <span>
              {title !== 'tune' ? null : (
                <span>
                  <AddTags
                    id={id}
                    name={name}
                    title={title}
                    type={type}
                    parent={this}
                  />
                  <br />
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={this.onCallAddTagsOpen}
                  >
                    {localstrings.tune_tags}
                  </Button>
                </span>
              )}
            </span>
          </Fragment>
        );
      }
      return (
        <span>
          <AddTags
            id={id}
            name={name}
            title={title}
            type={type}
            parent={this}
          />
          {title !== 'tune' ? null : (
            <span>
              <Button
                variant="contained"
                size="medium"
                onClick={this.onCallAddTagsOpen}
              >
                {localstrings.tune_tags}
              </Button>
            </span>
          )}
        </span>
      );
    }

    // get here if there is at least one tag...
    let showAdd = false;
    if (!isReadOnly || (isReadOnly && title === 'tune')) {
      showAdd = true;
      if (!title && !isReadOnly) {
        title = 'Add / Edit';
      }
    }
    return (
      <Fragment>
        <div style={listStyle}>
          {showAdd && (
            <AddTags
              id={id}
              name={name}
              title={title}
              type={type}
              parent={this}
            />
          )}
          {selectedTags &&
            selectedTags.map((tagId) => {
              const tag = this.getTag(tagId);
              if (tag.tagName === 'na') {
                return null;
              }
              return (
                <Tag
                  format={format}
                  id={tag.id}
                  key={tag.id}
                  label={tag.label}
                  onDelete={
                    isCompact || isReadOnly ? undefined : this.handleDelete
                  }
                  readonly={isReadOnly}
                  tagName={tag.tagName}
                />
              );
            })}
        </div>
        {title !== 'tune' ? null : (
          <span>
            <br />
            <Button
              variant="contained"
              size="medium"
              onClick={this.onCallAddTagsOpen}
            >
              Tune Your Tags
            </Button>
          </span>
        )}
      </Fragment>
    );
  }
}

TagList.propTypes = {
  callback: PropTypes.func,
  callbackOnUpdate: PropTypes.func,
  format: PropTypes.oneOf(['default', 'compact']),
  id: PropTypes.string,
  maxHeight: PropTypes.number,
  name: PropTypes.string,
  readonly: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(['agents', 'agentClients', 'brokerages', 'listings', 'properties'])
};

export default withStyles(styles)(TagList);
