import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Snackbar,
  IconButton
} from '@material-ui/core';

import api from '../../api';
import localstring from '../../services/Localization';
import * as storage from '../../persistence/storage';

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class ContactAboutMatches extends Component {
  constructor(props) {
    super(props);
    const meString = storage.get('me');
    let meJSON = null;
    let fullName = 'Realm Agent';
    let firstName = 'The Realm';
    if (meString) {
      meJSON = JSON.parse(meString);
      fullName = meJSON.firstName + ' ' + meJSON.lastName;
      firstName = meJSON.firstName;
    }
    const currentAgentString = storage.get('agentData');
    const currentAgent = currentAgentString
      ? JSON.parse(currentAgentString)
      : null;
    const { agent, title } = this.props;
    const agentName = `${agent.person.name.firstName} ${
      agent.person.name.lastName
    }`;
    this.state = {
      openContactDialog: false,
      openConfirm: false,
      vertical: 'top',
      horizontal: 'center',
      me: meJSON,
      fullName: fullName,
      firstName: firstName,
      title,
      agentName,
      agentId: agent._id,
      currentAgent
    };
  }

  handleClose = () => {
    this.setState({
      openContactDialog: false
    });
  };

  handleOpen = () => {
    this.setState({
      openContactDialog: true
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCloseSnack = () => {
    this.setState({ openConfirm: false });
  };

  handleSend = async () => {
    this.handleClose();
    const fromAgent = {
      name: this.state.fullName,
      agent: this.state.currentAgent._id
    };
    const toAgent = { name: this.state.agentName, agent: this.state.agentId };
    const propTags = this.props.property.realmData ? this.props.property.realmData.tagNames : [];
    const listing = {
      title: this.state.title,
      listing: this.props.property._id,
      tagNames: propTags
    };
    const clients = this.props.agent.clientMatching.clientList;

    let message = `${localstring.message_sent} to ${this.state.agentName}`;
    try {
      await api.post('/match/contactWithMatches', {
        fromAgent,
        toAgent,
        listing,
        clients
      });
    } catch (error) {
      message = localstring.message_error;
    }
    this.setState({ confirmMessage: message, openConfirm: true });
  };

  renderDialog() {
    const { agent, title } = this.props;
    const matchCount = agent.clientMatching.clientList.length;

    return (
      <div>
        <Dialog
          aria-labelledby="contact-matches-dialog-title"
          aria-describedby="contact-matches-dialog"
          onClose={this.handleClose}
          open={this.state.openContactDialog}
          TransitionComponent={Transition}
        >
          <DialogTitle id="contact-matches-dialog-title">
            Contact Agent
          </DialogTitle>
          <DialogContent className="realm--white-text">
            <div>
              <p>
                <strong>To: </strong> {this.state.agentName}
              </p>
              <p>
                <strong>From: </strong> {this.state.fullName}
              </p>
              <p>
                <strong>Subject:</strong> Realm Intelligence Matches{' '}
                {title ? <span> for {title}</span> : null}
              </p>
              <div>
                <p>Hi {agent.person.name.firstName},</p>
                <p>
                  I have been notified by Realm that you have {matchCount}{' '}
                  clients that are a good match for my listing: {title}.
                </p>
                <p>
                  Let me know when a good time to connect would be and we can
                  discuss this opportunity.
                </p>
                <p>Thanks,</p>
                <p>{this.state.firstName}</p>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={this.handleClose}
              variant="contained"
            >
              {localstring.cancel}
            </Button>
            <Button
              onClick={this.handleSend}
              variant="contained"
              color="primary"
            >
              {localstring.contact_send}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    const { vertical, horizontal, openConfirm } = this.state;

    return (
      <span>
        <Snackbar
          style={{ marginTop: 150 }}
          key="sentMessage"
          anchorOrigin={{ vertical, horizontal }}
          open={openConfirm}
          autoHideDuration={3000}
          onClose={this.handleCloseSnack}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{this.state.confirmMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="primary"
              className="snackX"
              onClick={this.handleCloseSnack}
            >
              X
            </IconButton>
          ]}
        />
        <Button
          className="realm--contact-link"
          color="default"
          onClick={this.handleOpen}
          size="small"
          variant="contained"
        >
          {localstring.contact}
        </Button>
        {this.state.openContactDialog ? this.renderDialog() : null}
      </span>
    );
  }
}

ContactAboutMatches.propTypes = {
  agent: PropTypes.object,
  property: PropTypes.object
};

export default ContactAboutMatches;
