import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontSize: 24
  },
  palette: {
    type: 'dark',
    default: {
      main: '#eef',
      light: '#f0f',
      dark: '#888'
    },
    primary: {
      light: '#fff',
      main: '#ac5f0f',
      dark: '#75400a'
    },
    bert: {
      light: '#f00',
      main: '#0f0',
      dark: '#0ff'
    },
    secondary: {
      light: '#0f0',
      main: '#f00',
      dark: '#00f',
      contrastText: '#f0f'
    },
    appBar: {
      color: 'primary'
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#ccf',
          fontWeight: 400,
          fontSize: 16
        }
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '#fff'
          },
          '&:hover:not($disabled):before': {
            borderBottom: '#f00'
          }
        }
      }
    }
  }
});

export default theme;
