/**
 * Title case
 *
 * Returns string with capital letter
 *
 * @param {str} incoming string
 * @returns {string}
 */
export default function toTitleCase(str) {
  if (!str || typeof str !== 'string') {
    return '';
  }
  return str.replace(/\w\S*/g, (txt, idx) => {
    const isCapitilizedWord = (txt !== 'the' && txt !== 'and' && txt !== 'a' && txt !== 'an' && txt !== 'in' && txt !== 'or' && txt !== 'to');
    if (idx === 0 || isCapitilizedWord) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
    return txt;
  });
}
