/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from 'lodash.isfunction';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  FABPlus: {
    float: 'right',
    marginRight: 40
  },
  icon: {
    float: 'right',
    color: '#fff',
    fontSize: 32,
    marginRight: 70
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      fontsize: 34
    }
  }
});

class PageTitle extends PureComponent {
  handleOpen = (e) => {
    e.preventDefault();
    if (isFunction(this.props.handleClick)) {
      this.props.handleClick();
    }
  };

  render() {
    return (
      <div className="realm--page-title-bar">
        {this.props.backTo && (
          <Link to={this.props.backTo}>
            <i className="fa fa-caret-left fa-fw" />
            {this.props.title}
          </Link>
        )}
        {this.props.goBack && this.props.history && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
          >
            <i className="fa fa-caret-left fa-fw" />
            Go Back
          </a>
        )}
        {!this.props.backTo && !this.props.goBack && (
          <span>{this.props.title}</span>
        )}
        {this.props.addPlus && (
          <a
            className="realm--add-plus"
            href=""
            onClick={this.handleOpen}
            role="button"
            title="Add Item"
          >
            <i
              aria-hidden="true"
              className={classNames(
                this.props.classes.icon,
                'fa fa-plus-circle'
              )}
            />
          </a>
        )}
      </div>
    );
  }
}

PageTitle.propTypes = {
  addPlus: PropTypes.bool,
  backTo: PropTypes.string,
  classes: PropTypes.object.isRequired,
  goBack: PropTypes.bool,
  handleClick: PropTypes.func,
  history: PropTypes.object,
  title: PropTypes.string
};

export default withStyles(styles)(PageTitle);
