import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import Checkbox from './Checkbox';
import AppData from '../../services/AppData';

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        margin: '0 auto',
        padding: 24,
        textAlign: 'left'
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#f00'
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1
    },
    '&$tabSelected': {
      color: '#f0f',
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      color: '#00ff00'
    }
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3
  }
});

const tabStyle = {
  default_tab: {
    color: '#fff',
    minHeight: 35
  },
  active_tab: {
    textColor: '#fff',
    minHeight: 25
  }
};

class TagSelect extends Component {
  static _this;

  constructor(props) {
    super(props);
    TagSelect._this = this;
    this.state = {
      selectedTab: 0,
      selectedTags: [],
      value: 0
    };
  }

  async componentWillMount() {
    await AppData.loadTags(this.props.type);
    await AppData.loadSelectedTags(this.props.type, this.props.id);
    this.setState({
      selectedTags: AppData.selectedTags,
      tagDefinitions: AppData.tagCategories,
      value: 0
    });
  }

  onChange = (id, value) => {
    const { type, id: idp } = this.props;
    const { selectedTags } = this.state;
    selectedTags.push(id);
    if (value) {
      AppData.addUserTag(type, idp, id);
    } else {
      AppData.deleteUserTag(type, idp, id);
    }
  };

  onSearchChanged = (event) => {
    const selectedTab = this.numCategories - 1;
    if (event.target.value.length > 0) {
      this.setState({
        searchCriteria: event.target.value,
        selectedTab,
        value: selectedTab
      });
    } else {
      this.setState({
        searchCriteria: event.target.value,
        selectedTab: null
      });
    }
  };

  getStyle = (isActive) =>
    isActive ? tabStyle.active_tab : tabStyle.default_tab;

  getIndex = (el) => {
    const labels = Object.keys(this.state.tagDefinitions);
    return labels.filter((label) => label === el);
  };

  getTag = (tagId) => {
    let returnItem = null;
    const tagDefinitions = Object.keys(this.state.tagList.tagDefinitions);
    tagDefinitions.map((tagCategory) => {
      this.state.tagList.tagDefinitions[tagCategory].map((item) => {
        if (item.id === tagId) {
          returnItem = item;
        }
        return null;
      });
      return null;
    });
    return returnItem || { id: 0, label: 'na', tagName: 'na' };
  };

  setCheckedTags = () => {
    let numCategories = 0;
    const { tagDefinitions, selectedTags } = this.state;
    for (const tagCategory in tagDefinitions) {
      const list = tagDefinitions[tagCategory];
      for (let i = 0; i < list.length; i++) {
        if (selectedTags.includes(list[i].tagName)) {
          list[i].checked = true;
        } else {
          list[i].checked = false;
        }
      }
      numCategories++;
    }
    this.numCategories = numCategories;
  };

  handleTabChange = (event, value) => {
    TagSelect._this.setState({ selectedTab: value, value });
  };

  render() {
    const { value, tagDefinitions } = this.state;
    if (!tagDefinitions) {
      return null;
    }
    const { classes } = this.props;
    const labels = Object.keys(tagDefinitions);
    this.setCheckedTags();
    return (
      <div className={classes.root}>
        {!isEmpty(tagDefinitions) && (
          <AppBar position="static" color="default">
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator
              }}
              indicatorColor="primary"
              onChange={this.handleTabChange}
              scrollable={labels.length > 4}
              centered={labels.length < 5}
              scrollButtons="auto"
              textColor="primary"
              value={this.state.selectedTab}
            >
              {labels.map((el) => {
                const id = this.getIndex(el);
                return (
                  <Tab
                    key={`tab-${id}`}
                    label={el}
                    style={this.getStyle(value === id)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        )}

        {!isEmpty(tagDefinitions) ? (
          <TabContainer>
            {tagDefinitions[labels[value]].map((el) => {
              if (
                this.state.searchCriteria &&
                this.state.searchCriteria.length > 0
              ) {
                if (
                  el.label
                    .toUpperCase()
                    .includes(this.state.searchCriteria.toUpperCase())
                ) {
                  return (
                    <div className="realm--checkbox-container" key={el.id}>
                      <Checkbox
                        label={el.label}
                        name={el.tagName}
                        onChange={this.onChange}
                        value={el.checked}
                      />
                    </div>
                  );
                }
              } else {
                return (
                  <div className="realm--checkbox-container" key={el.id}>
                    <Checkbox
                      label={el.label}
                      name={el.tagName}
                      onChange={this.onChange}
                      value={el.checked}
                    />
                  </div>
                );
              }
              return null;
            })}
          </TabContainer>
        ) : (
            <div className="realm--white-text">
              <h2>Error loading tags</h2>
              <p>
                No tags are avaiable to select. Please contact the REALM support
                team to fix this issue.
            </p>
            </div>
          )}
      </div>
    );
  }
}

TagSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  type: PropTypes.oneOf(['agents', 'agentClients', 'brokerages', 'listings', 'properties'])
};

export default withStyles(styles)(TagSelect);
