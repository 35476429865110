import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const AppData = { formValues: {} };

class RLMCheckbox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      formValues: props.formValues || AppData.formValues,
      value: props.value
    };
    this.state.formValues[props.name] = props.value;
  }

  onChange = () => {
    const value = !this.state.value;
    this.setState({ value });
    if (this.props.onChange) this.props.onChange(this.props.name, value);
  }

  render() {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.value}
            onChange={this.onChange}
            value="checkedB"
            color="primary"
          />
        }
        label={this.props.label}
      />
    );
  }
}

export default RLMCheckbox;
