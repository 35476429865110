/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

import localstring from '../../services/Localization';

class ContactUs extends Component {
  state = {
    // from: null,
    to: 'Concierge',
    // subject: null,
    topic: 'Feedback',
    message: '',
    showConfirm: false,
    // isVisible: false,
    visibleFromButton: false
  };

  selectSendButton = () => {
    this.setState({ visibleFromButton: true });
  };

  selectCancelButton = () => {
    this.setState({ visibleFromButton: false });
  };

  openFromButton = (e) => {
    e.preventDefault();
    this.setState({ visibleFromButton: true });
  };

  messageInput = (e) => {
    const v = e.target.value;
    this.setState({ message: v });
  };

  renderDialog() {
    const {
      isVisible,
      showButton,
      showText,
      // recipient,
    } = this.props;
    return (
      <span>
        {showButton ? (
          <div style={{ display: 'inline-block' }}>
            <Button color="primary" onClick={this.openFromButton}>
              {localstring.contact_us}
            </Button>
          </div>
        ) : null}
        {showText ? (
          <div style={{ display: 'inline-block' }}>
            <a role="button" href="" onClick={this.openFromButton}>
              {localstring.contact_us}
            </a>
          </div>
        ) : null}
        <Dialog
          aria-describedby="delete-dialog"
          aria-labelledby="delete-dialog-title"
          onClose={this.handleClose}
          open={isVisible || this.state.visibleFromButton}
        >
          <DialogTitle id="delete-dialog-title">
            {localstring.contact_us_title}
          </DialogTitle>

          <div className="edit-modal">
            {/* **** Who this is for... ***** */}
            <FormControl className="input250">
              <InputLabel className="muiInputLabel" htmlFor="recipient">
                {localstring.contact_to}
              </InputLabel>
              <Select
                className="selectWhite"
                value={this.state.to}
                // onChange={this.handleSelection}
              >
                <MenuItem value="Concierge">
                  {localstring.nav_menu_concierge}
                </MenuItem>
                <MenuItem value="Help">{localstring.contact_to_help}</MenuItem>
                <MenuItem value="Feedback">
                  {localstring.contact_to_suggestions}
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            {/* **** What's it about... ***** */}
            <FormControl className="input250">
              <InputLabel className="muiInputLabel" htmlFor="purpose">
                {localstring.contact_re}
              </InputLabel>
              <Select
                className="selectWhite"
                value={this.state.topic}
                // onChange={this.handleSelection}
              >
                <MenuItem value="Feedback">
                  {localstring.contact_re_feedback}
                </MenuItem>
                <MenuItem value="Account">
                  {localstring.contact_re_account}
                </MenuItem>
                <MenuItem value="Tech">{localstring.contact_re_tech}</MenuItem>
                <MenuItem value="Other">
                  {localstring.contact_re_other}
                </MenuItem>
              </Select>
            </FormControl>
            <div style={{ marginTop: 18 }}>
              <p>{localstring.contact_message}</p>
              <textarea
                rows="12"
                style={{ width: '100%', padding: 12 }}
                onInput={this.messageInput}
                value={this.state.message}
                placeholder="Please type your message here..."
              />
            </div>
          </div>

          <DialogActions>
            <Button onClick={this.selectCancelButton} color="primary">
              {localstring.cancel}
            </Button>
            <Button
              onClick={this.handleUploadFromDialog}
              color="primary"
              variant="contained"
              disabled={this.state.message.length === 0}
            >
              {localstring.contact_send}
            </Button>
          </DialogActions>
        </Dialog>

        {/* email sent confirm dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showConfirm}
        >
          <DialogActions>
            <Button
              onClick={this.uploadFile}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }

  render() {
    return <span>{this.renderDialog()}</span>;
  }
}

ContactUs.propTypes = {
  isVisible: PropTypes.bool,
  showButton: PropTypes.bool,
  showText: PropTypes.bool
};

ContactUs.defaultProps = {
  isVisible: false,
  showButton: false,
  showText: false
};

export default ContactUs;
