import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core';

import localstring from '../../services/Localization';
import styles from './styles';

class Navigation extends Component {
  state = {
    admin: false,
    agents: true,
    brokerages: true,
    concierge: false,
    clients: true,
    isOpen: false,
    matching: false,
    properties: true,
  }

  handleDrawerOpen = () => {
    this.setState({ isOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ isOpen: false });
  };

  handleClick = (prop, e) => {
    e.preventDefault();
    this.setState({ [prop]: !this.state[prop] });
  };

  render() {
    const { classes, isRTL } = this.props;
    const { isOpen } = this.state;
    return (
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classNames(
              classes.drawerPaper,
              isRTL && classes.drawerPaperisRTL,
              !isRTL && classes.drawerPaperisNotRTL,
              !isOpen && classes.drawerPaperClose
            )
          }}
          anchor="top"
          id="realmDrawer"
          onBlur={this.handleDrawerClose}
          onFocus={this.handleDrawerOpen}
          onMouseOver={this.handleDrawerOpen}
          onMouseOut={this.handleDrawerClose}
          open={isOpen}
          style={{
            left: isRTL ? 'auto' : 0,
            right: isRTL ? 0 : 'auto'
          }}
          variant="permanent"
        >
          <Divider />
          <List>
            {/* ************* DASHBOARD ******************** */}
            <ListItem button>
              <Link to="/dashboard">
                <div aria-hidden="true" className="fa fa-home sidebaricon" />
                <span className="sidebarMainLink">
                  {localstring.nav_menu_dashboard}
                </span>
              </Link>
            </ListItem>

            {/* ************* MATCHING ******************** */}
            {this.props.oneClickMatching ? (
              <ListItem button>
                <Link to="/matching">
                  <div
                    aria-hidden="true"
                    className="fa fa-bullseye sidebaricon"
                  />
                  <span className="sidebarMainLink">
                    {localstring.nav_menu_matching}
                  </span>
                </Link>
              </ListItem>
            ) : (
              <span>
                <ListItem
                  button
                  onClick={(e) => this.handleClick('matching', e)}
                >
                  <ListItemIcon>
                    <div
                      className="fa fa-bullseye sidebaricon"
                      aria-hidden="true"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={localstring.nav_menu_matching}
                    style={{ padding: 0 }}
                  />
                  {this.state.matching ? (
                    <i className="fa fa-caret-down fa-fw" />
                  ) : (
                    <i className="fa fa-caret-right fa-fw" />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.matching && isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.subMenu}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-circle subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/matching">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.my_properties}
                        />
                      </Link>
                    </ListItem>
                    <ListItem className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-bell subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/match-alerts">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.my_matching_alerts}
                        />
                      </Link>
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {/* ************* BROKERAGES ******************** */}
            {this.props.oneClickBrokerage ? (
              <ListItem button>
                <Link to="/brokerages/list">
                  <div
                    aria-hidden="true"
                    className="fa fa-list-ul sidebaricon"
                  />
                  <span className="sidebarMainLink">
                    {localstring.nav_menu_brokerages}
                  </span>
                </Link>
              </ListItem>
            ) : (
              <span>
                <ListItem
                  button
                  onClick={(e) => this.handleClick('brokerages', e)}
                >
                  <ListItemIcon>
                    <div
                      aria-hidden="true"
                      className="fa fa-list-ul sidebaricon"
                    />
                  </ListItemIcon>
                  <ListItemText primary={localstring.nav_menu_brokerages} />
                </ListItem>
                <Collapse
                  in={this.state.brokerages && isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.subMenu}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-list-ul subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/brokerages/list">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_brokerages_item1}
                        />
                      </Link>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={(e) => this.handleClick('goto-/brokerages', e)}
                    >
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-plus subsidebaricon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.submenutext}
                        inset
                        primary={localstring.nav_menu_brokerages_item2}
                      />
                    </ListItem>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={(e) => this.handleClick('goto-/brokerages', e)}
                    >
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-arrow-circle-o-up subsidebaricon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.submenutext}
                        inset
                        primary={localstring.nav_menu_brokerages_item3}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {/* ************* AGENTS ******************** */}
            {this.props.oneClickAgents ? (
              <ListItem button>
                <Link to="/agents">
                  <div aria-hidden="true" className="fa fa-users sidebaricon" />
                  <span className="sidebarMainLink">
                    {localstring.nav_menu_agents}
                  </span>
                </Link>
              </ListItem>
            ) : (
              <span>
                <ListItem button onClick={(e) => this.handleClick('agents', e)}>
                  <ListItemIcon>
                    <div
                      aria-hidden="true"
                      className="fa fa-users sidebaricon"
                    />
                  </ListItemIcon>
                  <ListItemText primary={localstring.nav_menu_agents} />
                </ListItem>
                <Collapse
                  in={this.state.agents && isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.subMenu}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-list-ul subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/agents">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_agents_item1}
                        />
                      </Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-arrow-circle-o-up subsidebaricon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        inset
                        primary={localstring.nav_menu_agents_item3}
                        className={classes.submenutext}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}
            {/* ************* CLIENTS ******************** */}
            {this.props.oneClickClients ? (
              <span>
                {this.props.isAdmin || this.props.isConcierge || this.props.isAgent ? (
                  <ListItem button>
                    <Link to="/my-clients/list">
                      <div
                        aria-hidden="true"
                        className="fa fa-address-book sidebaricon"
                      />
                      <span className="sidebarMainLink">
                        {localstring.nav_menu_clients}
                      </span>
                    </Link>
                  </ListItem>
                ) : null}
              </span>
            ) : (
              <span>
                <ListItem
                  button
                  onClick={(e) => this.handleClick('clients', e)}
                >
                  <ListItemIcon>
                    <div
                      aria-hidden="true"
                      className="fa fa-address-book sidebaricon"
                    />
                  </ListItemIcon>
                  <ListItemText primary={localstring.nav_menu_clients} />
                </ListItem>
                <Collapse
                  in={this.state.clients && isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.subMenu}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-list-ul subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/my-clients">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_clients_item1}
                        />
                      </Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-arrow-circle-o-up subsidebaricon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.submenutext}
                        inset
                        primary={localstring.nav_menu_clients_item3}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}
            {/* ************* PROPERTIES ******************** */}
            {this.props.oneClickProperties ? (
              <ListItem button>
                <Link to="/properties/list">
                  <div
                    aria-hidden="true"
                    className="fa fa-picture-o sidebaricon"
                  />
                  <span className="sidebarMainLink">
                    {localstring.nav_menu_properties}
                  </span>
                </Link>
              </ListItem>
            ) : (
              <span>
                <ListItem
                  button
                  onClick={(e) => this.handleClick('properties', e)}
                >
                  <ListItemIcon>
                    <div
                      aria-hidden="true"
                      className="fa fa-picture-o sidebaricon"
                    />
                  </ListItemIcon>
                  <ListItemText primary={localstring.nav_menu_properties} />
                </ListItem>
                <Collapse
                  in={this.state.properties && isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.subMenu}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-list-ul subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="properties/list">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_properties_item1}
                        />
                      </Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-arrow-circle-o-up subsidebaricon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.submenutext}
                        inset
                        primary={localstring.nav_menu_properties_item3}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}
            {/* ************************ BLOG ******************* */}
            <ListItem button>
              <Link to="/blog">
                <div
                  aria-hidden="true"
                  className="fa fa-newspaper-o sidebaricon"
                />
                <span className="sidebarMainLink">
                  {localstring.nav_menu_blog}
                </span>
              </Link>
            </ListItem>

            {/* ************* CONCIERGE ******************** */}
            {this.props.hideConcierge ? (
              <span />
            ) : (
              <span>
                <ListItem
                  button
                  onClick={(e) => this.handleClick('concierge', e)}
                >
                  <ListItemIcon>
                    <div
                      aria-hidden="true"
                      className="fa fa-bell sidebaricon"
                    />
                  </ListItemIcon>
                  <ListItemText primary={localstring.nav_menu_concierge} />
                  <div
                    className={classNames(
                      classes.menucount,
                      !isOpen && classes.closedmenucount
                    )}
                  >
                    23
                  </div>
                  {this.state.concierge ? (
                    <i className="fa fa-caret-down fa-fw" />
                  ) : (
                    <i className="fa fa-caret-right fa-fw" />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.concierge && isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.subMenu}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-user subsidebaricon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.submenutext}
                        inset
                        primary={localstring.nav_menu_concierge_item1}
                      />
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-users subsidebaricon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.submenutext}
                        inset
                        primary={localstring.nav_menu_concierge_item2}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {/* ************* ADMIN ******************** */}
            {this.props.isAdmin || this.props.isRealmAdmin ? (
              <span>
                <ListItem button onClick={(e) => this.handleClick('admin', e)}>
                  <div
                    aria-hidden="true"
                    className="fa fa-wrench sidebaricon"
                  />
                  <span className="sidebarMainLink">
                    {localstring.nav_menu_administration}
                  </span>
                  {this.state.admin ? (
                    <i className="fa fa-caret-down fa-fw" />
                  ) : (
                    <i className="fa fa-caret-right fa-fw" />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.admin && isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.subMenu}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          className="fa fa-users subsidebaricon"
                          aria-hidden="true"
                        />
                      </ListItemIcon>
                      <Link to="/manage-admins">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_administration_item1}
                        />
                      </Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-cloud-upload subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/manage-users">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_administration_item2}
                        />
                      </Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-th-list subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/upload">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_administration_item3}
                        />
                      </Link>
                    </ListItem>
                    {this.props.isAdmin ? (
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <div
                            aria-hidden="true"
                            className="fa fa-hand-paper-o subsidebaricon"
                          />
                        </ListItemIcon>
                        <Link to="/admin/tools">
                          <ListItemText
                            className={classes.submenutext}
                            inset
                            primary="Tools"
                          />
                        </Link>
                      </ListItem>
                    ) : null}
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <div
                          aria-hidden="true"
                          className="fa fa-hand-paper-o subsidebaricon"
                        />
                      </ListItemIcon>
                      <Link to="/logs/auto">
                        <ListItemText
                          className={classes.submenutext}
                          inset
                          primary={localstring.nav_menu_administration_item4}
                        />
                      </Link>
                    </ListItem>
                    <ListItem button style={{ height: 48 }} />
                  </List>
                </Collapse>
              </span>
            ) : null}
          </List>
        </Drawer>
      </Hidden>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object,
  hideConcierge: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isAgent: PropTypes.bool,
  isConcierge: PropTypes.bool,
  isOpen: PropTypes.bool,
  isRealmAdmin: PropTypes.bool,
  isRTL: PropTypes.bool,
  oneClickBrokerage: PropTypes.bool,
  oneClickAgents: PropTypes.bool,
  oneClickClients: PropTypes.bool,
  oneClickProperties: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(Navigation);
