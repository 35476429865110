import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { withStyles } from '@material-ui/core';

import AppRoutes from './AppRoutes';
import Onboarding from '../Onboarding';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import LoadingIndicator from '../../components/LoadingIndicator';

import styles from './styles';

const { body } = document;

class App extends Component {
  wrapperRef = createRef();

  contentRef = createRef();

  componentDidMount() {
    const { actions, session } = this.props;
    !session.currentPerson && actions.getPersonData();
    !session.brokerages && actions.getBrokerageData();
    window.addEventListener('resize', this.checkForScrolling);
    this.checkForScrolling();
  }

  componentDidUpdate() {
    this.checkForScrolling();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkForScrolling);
  }

  checkForScrolling = () => {
    if (this.props.session.isOnboarding) {
      return null;
    }
    const wrapperDiv = this.wrapperRef.current;
    const contentDiv = this.contentRef.current;
    const wrapperTopPadding = 53;
    if (wrapperDiv && contentDiv) {
      const wrapperRect = wrapperDiv.getBoundingClientRect();
      const contentRect = contentDiv.getBoundingClientRect();
      if (wrapperTopPadding + contentRect.height > wrapperRect.height) {
        body.classList.add('show-scrollbar');
      } else {
        body.classList.remove('show-scrollbar');
      }
    }
    return null;
  };

  renderBackOffice = () => {
    const {
      actions,
      application,
      classes,
      session,
      theme,
      ...rest
    } = this.props;
    return (
      <Fragment>
        <Header
          actions={actions}
          application={application}
          session={session}
          {...rest}
        />
        <Navigation
          hideConcierge
          isAdmin={session.isAdmin}
          isAgent={session.isAgent}
          isConcierge={session.isConcierge}
          isRealmAdmin={session.isRealmAdmin}
          isRTL={application.isRTL}
          oneClickBrokerage
          oneClickAgents
          oneClickClients
          oneClickProperties
        />
        <div className={classes.content}>
          <div
            id="page-wrapper"
            className={this.props.application.isMobile ? 'mobile' : 'desktop'}
            ref={this.wrapperRef}
          >
            <div className="page-content" ref={this.contentRef}>
              <AppRoutes
                actions={actions}
                application={application}
                session={session}
                {...rest}
              />
            </div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  };

  renderOnboarding = () => <Onboarding {...this.props} />;

  render() {
    const { application, classes, session } = this.props;
    if (isEmpty(session.currentPerson)) {
      return (
        <div className="realm--loading-container">
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <div id="wrapper">
        <div className={classes.root} dir={application.isRTL ? 'rtl' : 'ltr'}>
          {session.isOnboarding
            ? this.renderOnboarding()
            : this.renderBackOffice()}
        </div>
      </div>
    );
  }
}

App.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  classes: PropTypes.object,
  session: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(App);
