import validator from 'validator';

export default class FormValidator {
  constructor(validations) {
    this.validations = validations;
    this.isActive = false;
    this.forceError = false;
    this.errorValidation = this.valid();
  }

  // Only works if you set the validate to isActive = true
  validate(state) {
    let validation = this.valid();

    if (!this.forceError) {
      this.validations.forEach((rule) => {
        if (!validation[rule.field].isInvalid) {
          const fieldValue = state[rule.field] ? state[rule.field].toString() : '';
          const args = rule.args || [];
          const validationMethod = typeof rule.method === 'string' ? validator[rule.method] : rule.method;
          if (this.isActive) {
            if (validationMethod(fieldValue, ...args, state) !== rule.validWhen) {
              validation[rule.field] = { isValid: false, message: rule.message };
              validation.isValid = false;
            }
          }
        }
      });
    } else {
      validation = this.errorValidation;
    }
    return validation;
  }

  // Make everything valid, same as a reset
  valid() {
    const validation = {};

    this.validations.forEach((rule) => {
      validation[rule.field] = { isValid: true, message: '' };
    });

    return { isValid: true, ...validation };
  }

  // Force server side errors to populate in the client UIX
  setError(errorValidation) {
    this.forceError = true;
    this.errorValidation = errorValidation;
  }

  // Run native validator methods
  // https://www.npmjs.com/package/validator
  isValid(rule) {
    return this.validator[rule.method](rule.value, rule.args);
  }
}
