/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import isFunction from 'lodash.isfunction';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import ContactForm from '../components/ContactForm';
import NavButtons from '../components/NavButtons';
import TagList from '../../../components/Tags/TagList';

import api from '../../../api';
import localization from '../../../services/Localization';
import numberWithCommas from '../../../utils/number-with-commas';

/* TODO: Jon, this might need to change to row._original._id as this should now be the listing Id not the property Id */

const columns = [
  {
    Header: 'Property Address',
    accessor: 'propertyAddress',
    minWidth: 300
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Listing Price',
    accessor: 'listingPrice'
  },
  {
    Header: 'Preview Listing',
    accessor: 'previewUrl',
    minWidth: 200,
    Cell: ({ row }) => (<a target="_blank" rel="noopener noreferrer" href={row.previewUrl}>{row.previewUrl}</a>)
  },
  {
    Header: () => 'Tag Keywords',
    Cell: ({ row }) => (<TagList format="compact" id={row._original.propertyId} name="" type="listings" className="xxx" />),
    accessor: 'tags',
    minWidth: 120
  }
];

class PropertyTags extends Component {
  state = {
    isLoading: false,
    limit: 10,
    page: 1,
    properties: [],
    propertiesForDisplay: [],
    showContactForm: false
  };

  componentDidMount() {
    this.props.updateStep(3);
    this.getProperties(1);
    const el = document.getElementById('onboarding-container');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }

  getProperties = async (page) => {
    this.setState({ isLoading: true });
    const response = await api.post('/listings/filter', {
      page,
      limit: this.state.limit,
      filter: {
        listingAgents: [this.props.session.agentData._id]
      }
    });
    const { data, total: totalRecords } = response.data.data;
    const newProperties = this.displayListings(data);
    const properties = this.state.properties.concat(newProperties);
    const propertiesForDisplay = Array.concat(properties);
    propertiesForDisplay.length = totalRecords;
    this.setState({
      isLoading: false,
      properties,
      propertiesForDisplay
    });
  };

  returnCurrencySymbol = (currency) => {
    if (!currency) {
      return '$';
    }
    switch (currency) {
      case 'USD':
        return '$';
      default:
        return '$';
    }
  };

  handlePageChange = (e) => {
    const currentPage = e + 1;
    this.setState({
      page: currentPage
    });
    if (this.state.properties.length < currentPage * this.state.limit) {
      this.getProperties(currentPage);
    }
  };

  handlePageSizeChange = (e) => {
    this.setState({ limit: e });
  };

  displayListings = (data) => {
    const outputArray = [];
    data.map((l) => {
      const listing = {};
      const { property } = l;
      const currencyMark = this.returnCurrencySymbol(l.price.currency);
      listing.propertyAddress = `${property.streetAddress}, ${property.city}, ${
        property.state
      } ${property.zipcode} `;
      listing.status = l.status;
      listing.listingPrice = `${currencyMark}${numberWithCommas(
        l.price.amount
      )}`;
      listing.previewUrl = l.listingUrl;
      listing.propertyId = property._id;
      outputArray.push(listing);
      return null;
    });
    return outputArray;
  };

  toggleContactForm = () => {
    this.setState({
      showContactForm: !this.state.showContactForm
    });
  }

  render() {
    const { onboarding: lang } = localization;
    return (
      <Fragment>
        <h1
          className="realm-onboarding--title centered sm"
          dangerouslySetInnerHTML={{ __html: lang.account_title }}
        />
        <p className="realm-onboarding--welcome centered sm">
          {lang.account_welcome1}{' '}
          <a onClick={this.toggleContactForm}>{lang.account_welcome2}</a>{' '}
          {lang.account_welcome3}
        </p>
        <div className="flex-container flex-spaceAround">
          <div className="row">
            <div className="col col-1">
              <h4 dangerouslySetInnerHTML={{ __html: lang.property_welcome }} />
              <p dangerouslySetInnerHTML={{ __html: lang.property_p1 }} />
              <div className="realm-onboarding--table">
                <ReactTable
                  columns={columns}
                  data={this.state.propertiesForDisplay}
                  defaultPageSize={this.state.limit}
                  loading={this.state.isLoading}
                  noDataText={lang.no_data}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                  showPageSizeOptions={false}
                  style={{ minHeight: 820 }}
                  page={this.state.page - 1}
                />
              </div>
            </div>
          </div>
        </div>
        <NavButtons
          history={this.props.history}
          nextPage="/complete"
          previousPage="/clients"
        />
        {/* contact form */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showContactForm}
        >
          <DialogTitle>Contact Us</DialogTitle>
          <DialogContent>
            <ContactForm />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.toggleContactForm}
              color="default"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

PropertyTags.propTypes = {
  history: PropTypes.object,
  session: PropTypes.object,
  updateStep: PropTypes.func
};

export default PropertyTags;
