import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isURL from 'is-url';
import merge from 'lodash.merge';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import UploadIcon from '@material-ui/icons/CloudUpload';

import api from '../../api';
import actions from '../../actions';
import { API_IMAGE } from '../../constants';
import localstring from '../../services/Localization';
import LoadingIndicator from '../LoadingIndicator';


class UploadPhoto extends Component {
  state = {
    fileToUpload: null,
    itemDropped: false,
    photoURL: '',
    showUploadDialog: false,
    showUploadStateDialog: false,
    uploadSuccessful: '',
    urlIsEntered: false,
    urlIsValid: false,
    visibleFromButton: false
  };

  delay = null;

  onInput = (e) => {
    const { name } = e.target;
    this.setState(
      {
        [name]: e.target.value
      },
      () => {
        this.setState({
          urlIsValid: isURL(this.state.photoURL),
          urlIsEntered: this.state.photoURL.length > 0
        });
      }
    );
    return null;
  };

  uploadFile = async () => {
    this.setState({ showUploadDialog: false });
    const data = new FormData();
    data.append('file', this.state.fileToUpload);
    data.append('personId', this.props.session.currentPerson._id);
    api
      .post(`${API_IMAGE}/uploads/image/profile-pic`, data)
      .then(() => {
        this.setState({
          itemDropped: false,
          showUploadStateDialog: true,
          uploadSuccessful: true
        });
      })
      .catch(() => {
        this.setState({
          itemDropped: false,
          showUploadStateDialog: true,
          uploadSuccessful: false
        });
      });
  };

  uploadURL = async () => {
    this.setState({ showUploadDialog: false }, () => {
      const person = Object.assign({}, this.props.session.currentPerson);
      const data = merge(person.realmData, { photoUrl: this.state.photoURL });
      this.updateUser({ realmData: data });
    });
  };

  updateUser = async (data) => {
    try {
      await this.props.actions.updatePerson(data);
      if (this.props.session.isAgent) {
        this.updateAgent({ photoURL: data.realmData.photoUrl });
      } else {
        this.setState({
          itemDropped: false,
          photoURL: '',
          showUploadStateDialog: true,
          uploadSuccessful: true
        });
      }
    } catch (e) {
      this.setState({
        itemDropped: false,
        showUploadStateDialog: true,
        uploadSuccessful: false
      });
    }
  }

  updateAgent = async (data) => {
    try {
      await this.props.actions.updateAgent(data);
      this.setState({
        itemDropped: false,
        photoURL: '',
        showUploadStateDialog: true,
        uploadSuccessful: true
      });
    } catch (e) {
      this.setState({
        itemDropped: false,
        showUploadStateDialog: true,
        uploadSuccessful: false
      });
    }
  }

  handleDrop = (files) => {
    this.setState({
      itemDropped: true,
      fileToUpload: files[0],
      showUploadDialog: true
    });
  };

  endUpload = () => {
    this.setState({
      showUploadDialog: false,
      showUploadStateDialog: false,
      itemDropped: false,
      visibleFromButton: false
    });
    if (this.state.uploadSuccessful) {
      this.props.handleClose(this.state.uploadSuccessful === true);
    }
    // below avoids blinking in modal as it fades out
    this.delay = setTimeout(() => {
      this.setState({
        fileToUpload: null
      });
    }, 350);
  };

  handleClose = () => {
    this.props.handleClose(this.state.uploadSuccessful === true);
    this.setState({ visibleFromButton: false });
  };

  handleUploadFromDialog = () => {
    this.props.handleClose();
    this.setState({ visibleFromButton: false });
    this.state.photoURL ? this.uploadURL() : this.uploadFile();
  };

  selectedUploadButton = () => {
    this.setState({ visibleFromButton: true });
  };

  renderDialog() {
    const {
      forName,
      isVisible,
      showButton,
      showWords
    } = this.props;
    const readyForUpload = this.state.fileToUpload || this.state.urlIsValid;
    return (
      <Fragment>
        {showButton ? (
          <div style={{ display: 'inline-block' }}>
            <Button color="primary" onClick={this.selectedUploadButton}>
              <UploadIcon style={{ color: '#ac5f0f' }} />
              {showWords ? (
                <div>
                  <br />
                  {localstring.onboarding.account_form_upload_photo}
                </div>
              ) : null}
            </Button>
          </div>
        ) : (
          <Button
            style={
              this.props.block
                ? {
                  display: 'block',
                  margin: '0 auto'
                }
                : {}
            }
            color="default"
            onClick={this.selectedUploadButton}
            variant="contained"
          >
            {this.props.hasPhoto ? 'Change Picture' : 'Upload Picture'}
          </Button>
        )}
        <Dialog
          aria-describedby="upload-photo-dialog"
          aria-labelledby="upload-photo-dialog-title"
          onClose={this.handleClose}
          open={isVisible || this.state.visibleFromButton}
        >
          <DialogTitle id="delete-dialog-title">
            {localstring.onboarding.account_form_upload_photo} for {forName}
          </DialogTitle>
          <DialogContent className="edit-modal">
            <Dropzone
              className="realm-onboarding--dropzone"
              onDrop={(files) => this.handleDrop(files)}
            >
              {this.state.itemDropped ? (
                <LoadingIndicator />
              ) : (
                <div>
                  <p>{localstring.drop_photo}</p>
                  <small>{localstring.photo_limit}</small>
                </div>
              )}
            </Dropzone>
            <TextField
              error={this.state.urlIsEntered && !this.state.urlIsValid}
              style={{ marginTop: 10, width: '100%' }}
              id="photoURL"
              helperText=""
              name="photoURL"
              type="text"
              margin="dense"
              label={localstring.or_enter_url}
              onChange={this.onInput}
              value={this.state.photoURL}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              variant="contained"
            >
              {localstring.cancel}
            </Button>
            <Button
              onClick={this.handleUploadFromDialog}
              color="primary"
              variant="contained"
              disabled={!readyForUpload}
            >
              {localstring.upload_now}
            </Button>
          </DialogActions>
        </Dialog>

        {/* upload confirm dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showUploadDialog}
        >
          <DialogTitle>Uploading a new photo</DialogTitle>
          <DialogContent className="realm--white-text">
            {localstring.you_are_uploading}{' '}
            {this.state.fileToUpload && this.state.fileToUpload.name}?
            <br />
            {localstring.wish_to_proceed}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.endUpload}
              color="default"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={this.uploadFile}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* upload state dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showUploadStateDialog}
        >
          <DialogTitle>
            {typeof this.state.uploadSuccessful !== 'string' &&
            this.state.uploadSuccessful
              ? 'Success'
              : 'Upload failed'}
          </DialogTitle>
          <DialogContent style={{ color: '#fff' }}>
            {typeof this.state.uploadSuccessful !== 'string' &&
            this.state.uploadSuccessful
              ? 'The file was successfully uploaded.'
              : 'The upload failed. Please contact support if you continue to have problems.'}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.endUpload}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  render() {
    return this.renderDialog();
  }
}

UploadPhoto.propTypes = {
  actions: PropTypes.object,
  forName: PropTypes.string,
  handleClose: PropTypes.func,
  hasPhoto: PropTypes.bool,
  isVisible: PropTypes.bool,
  session: PropTypes.object
};

export default connect(
  (state) => ({
    session: state.session
  }),
  (dispatch) => ({ actions: bindActionCreators(actions, dispatch) })
)(UploadPhoto);
