import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  AppBar,
  ClickAwayListener,
  Grow,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Popover,
  Toolbar,
  Typography,
  withStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Localizer from '../Localizer';
import localstring from '../../services/Localization';
import styles from './styles';

import logoImage from '../../images/logos/REALM_white_TM.png';
import earthImage from '../../images/img/earth-grid.png';

class Header extends Component {
  state = {
    anchorEl: null,
    isOpen: false
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.application.isResizing !== this.props.application.isResizing
    ) {
      this.closeMobileMenu();
    }
  }

  closeMobileMenu = () => {
    this.setState({
      isOpen: false
    });
  };

  toggleMobileMenu = (e) => {
    if (e.type === 'click') {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  };

  handleMenu = (event, param) => {
    this.setState({ anchorEl: event.currentTarget, mstatus: param });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, mstatus: null });
  };

  render() {
    const { classes, session } = this.props;
    const { anchorEl, isOpen } = this.state;
    return (
      <AppBar position="fixed" className={classNames(classes.appBar)}>
        <Toolbar className={classes.toolbarBG}>
          <Typography
            color="inherit"
            noWrap
            style={{ flex: 1 }}
            variant="title"
          >
            <div className={classes.logoBox}>
              <Hidden mdUp>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  buttonRef={(node) => {
                    this.anchorEl = node;
                  }}
                >
                  <MenuIcon onClick={this.toggleMobileMenu} />
                </IconButton>
                <Popper
                  open={isOpen}
                  anchorEl={this.anchorEl}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom'
                      }}
                    >
                      <Paper
                        style={{
                          background: '#333',
                          borderRadius: 0,
                          color: '#fff',
                          marginLeft: '-5px',
                          paddingLeft: 17,
                          width: 200
                        }}
                      >
                        <ClickAwayListener onClickAway={this.closeMobileMenu}>
                          <MenuList>
                            <MenuItem
                              onClick={this.toggleMobileMenu}
                              className={classes.mobileMenuItem}
                            >
                              <Link to="/dashboard">
                                {localstring.nav_menu_dashboard}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              onClick={this.toggleMobileMenu}
                              className={classes.mobileMenuItem}
                            >
                              <Link to="/matching">
                                {localstring.nav_menu_matching}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              onClick={this.toggleMobileMenu}
                              className={classes.mobileMenuItem}
                            >
                              <Link to="/match-alerts">
                                {localstring.nav_menu_matching_alerts}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              onClick={this.toggleMobileMenu}
                              className={classes.mobileMenuItem}
                            >
                              <Link to="/brokerages/list">
                                {localstring.nav_menu_brokerages}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              onClick={this.toggleMobileMenu}
                              className={classes.mobileMenuItem}
                            >
                              <Link to="/agents">
                                {localstring.nav_menu_agents}
                              </Link>
                            </MenuItem>
                            {session.isAdmin ||
                            session.isConcierge ||
                            session.isAgent ? (
                              <MenuItem
                                onClick={this.toggleMobileMenu}
                                className={classes.mobileMenuItem}
                              >
                                <Link to="/my-clients/list">
                                  {!session.isAdmin ? <span>My </span> : null}{' '}
                                  {localstring.nav_menu_clients}
                                </Link>
                              </MenuItem>
                            ) : null}
                            <MenuItem
                              onClick={this.toggleMobileMenu}
                              className={classes.mobileMenuItem}
                            >
                              <Link to="/properties/list">
                                {localstring.nav_menu_properties}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              onClick={this.toggleMobileMenu}
                              className={classes.mobileMenuItem}
                            >
                              <Link to="/blog">
                                {localstring.nav_menu_blog}
                              </Link>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Hidden>
              <img src={logoImage} style={{ width: 156 }} alt="Realm logo" />{' '}
              {!this.props.application.isMobile && (
                <span
                  style={{
                    textTransform: 'uppercase',
                    fontSize: 12,
                    marginTop: 22,
                    marginRight: 12,
                    marginLeft: -10
                  }}
                >
                  beta
                </span>
              )}
              <Hidden smDown>
                <div className={classes.appName}>
                  {session.isAdmin ? (
                    <span>{localstring.logo_back_office}</span>
                  ) : null}
                </div>
              </Hidden>
            </div>
          </Typography>
          <div>
            {session.currentPerson ? (
              <span>
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  onClick={(e) => this.handleMenu(e, 'account')}
                  style={{ marginRight: 24 }}
                >
                  <div className="tinyAvatarCircle">
                    <img src={session.avatar} alt={'account / sign out'} />
                  </div>
                  <i
                    className="fa fa-caret-down"
                    style={{
                      fontSize: 16
                    }}
                  />
                </IconButton>
                <Menu
                  id="menu-account"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  className={classNames(classes.menu)}
                  open={this.state.mstatus === 'account'}
                  onClose={this.handleClose}
                >
                  {session.agentData && (
                    <MenuItem onClick={this.handleClose}>
                      <Link to={`/agent-profile?id=${session.agentData._id}`}>
                        <i className="fa fa-user fa-fw" />
                        {localstring.my_profile}
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem onClick={this.handleClose}>
                    <Link to={'/my-account'}>
                      <i className="fa fa-gear fa-fw" />
                      {localstring.menu1_user_account}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={this.handleClose}>
                    <Link to="/logout">
                      <i className="fa fa-sign-out fa-fw" />
                      {localstring.menu1_user_logout}
                    </Link>
                  </MenuItem>
                </Menu>
              </span>
            ) : null}
            <IconButton
              aria-haspopup="true"
              color="inherit"
              onClick={(e) => this.handleMenu(e, 'lang')}
            >
              <img
                src={earthImage}
                style={{
                  width: 28,
                  height: 'auto',
                  marginRight: 10
                }}
                alt="select language"
              />
              <i
                className="fa fa-caret-down submenuicon"
                style={{
                  fontSize: 16,
                  marginRight: 8
                }}
              />
            </IconButton>

            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              className={classNames(classes.menu)}
              id="menu-language"
              open={this.state.mstatus === 'lang'}
              onClose={this.handleClose}
              style={{ maxWidth: 320, width: 320 }}
            >
              <Localizer
                actions={this.props.actions}
                application={this.props.application}
              />
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  classes: PropTypes.object,
  session: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(Header);
