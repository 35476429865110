import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import TagDialog from '../Tags/TagDialog';
import ProfilePicture from '../ProfilePicture';
import ProfileProgress from '../ProfileProgress';

class AgentClientCard extends Component {
  getBrokerage = () => {
    if (this.props.brokerages) {
      if (this.props.brokerages.length) {
        return this.props.brokerages[0].name;
      }
    }
    return null;
  };

  render() {
    const { data, id, realmData } = this.props;
    const photoURL = this.props.photoURL || realmData.photoURL || '';
    if (!data) {
      return null;
    }
    const brokerage = this.getBrokerage();
    const fullName = `${data.name.firstName} ${data.name.lastName}`;
    return (
      <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
        <Card
          style={{ position: 'relative', height: '100%', paddingBottom: 20 }}
        >
          <CardContent>
            <Link style={{ display: 'block', margin: '0 auto' }} to={`/agent-profile?id=${id}`}>
              <ProfilePicture
                centered
                fullName={fullName}
                photoURL={photoURL}
              />
            </Link>
            <div className="realm--agentclient-card-content">
              <h3>
                <Link to={`/agent-profile?id=${id}`}>{fullName}</Link>
              </h3>
              {brokerage && (
                <p className="realm--agentclient-card-brokerage">{brokerage}</p>
              )}
            </div>
            <div className="realm--agentclient-card-info">
              <p>
                {data.emails.map((email) => {
                  if (email.address.trim() !== '') {
                    return (
                      <span
                        style={{ display: 'block' }}
                        key={`email-${email.address}-{id}`}
                      >
                        <a href={`mailto: ${email.address}`}>
                          <EmailIcon style={{ fontSize: 18, marginRight: 6 }} />
                          {`Email: ${email.address}`}
                        </a>
                      </span>
                    );
                  }
                  return null;
                })}
              </p>
              <p>
                {data.phones.map((phone) => {
                  if (phone.number && phone.number.trim() !== '') {
                    return (
                      <span
                        style={{ display: 'block' }}
                        key={`phone-${id}-${phone.phoneType}-${phone.number}`}
                      >
                        <PhoneIcon style={{ fontSize: 18, marginRight: 6 }} />{' '}
                        {phone.phoneType}: {phone.number}
                      </span>
                    );
                  }
                  return null;
                })}
              </p>
            </div>
          </CardContent>
          <div className="realm--agentclient-card-actions">
            <div style={{ marginBottom: 20 }}>
              <ProfileProgress
                data={this.props.data}
                realmData={this.props.realmData}
                type={this.props.type}
              />
            </div>
            <div
              key={`cellfortaglist-${id}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
            >
              <TagDialog
                editable={this.props.editableTags}
                id={id}
                type={this.props.type}
              />
              {this.props.type === 'agents' && (
                <Link
                  className="realm--agentclient-card-listing-btn"
                  role="button"
                  to={`/properties/list/?agent=${id}`}
                >
                  See Listings
                </Link>
              )}
            </div>
          </div>
        </Card>
      </Grid>
    );
  }
}

AgentClientCard.propTypes = {
  brokerages: PropTypes.array,
  data: PropTypes.object,
  editableTags: PropTypes.bool,
  id: PropTypes.string,
  realmData: PropTypes.object,
  photoURL: PropTypes.string,
  type: PropTypes.oneOf(['agents', 'agentClients'])
};

export default AgentClientCard;
