import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

class RealmButton extends Component {
  onClick = () => {
    if (this.props.addFunc) {
      this.props.addFunc();
    }
    this.props.history.push(this.props.nextPage);
  }

  render() {
    const onClickFunc = this.props.onClick || this.onClick;
    return (
      <Button
        className={classNames(
          this.props.className, this.props.disabled ? this.props.classes.disabled : '',
          this.props.variant === 'outlined' ? this.props.classes.outlined : ''
        )}
        color={this.props.color || 'primary'}
        onClick={this.props.disabled ? undefined : onClickFunc}
        style={{ pointerEvents: this.props.disabled ? 'none' : 'auto' }}
        variant={this.props.variant || 'contained'}
      >
        {this.props.title || this.props.children}
      </Button>
    );
  }
}

RealmButton.propTypes = {
  addFunc: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  disabled: PropTypes.bool,
  history: PropTypes.object,
  nextPage: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'contained']),
};

const styles = () => ({
  outlined: {
    border: '1px solid #c5c3bb',
    color: '#c5c3bb',
    '&:hover': {
      border: '1px solid #fff',
      color: '#fff',
      backgroundColor: 'rgba(197, 195, 187,.25)'
    },
  },
  disabled: {
    backgroundColor: '#ac5f0f',
    opacity: '0.35',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: '#ac5f0f',
      opacity: '0.35',
      cursor: 'not-allowed',
    },
  }
});

export default withStyles(styles)(RealmButton);
