import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import api from '../../api';
import getParameterByName from '../../utils/get-parameter-by-name';
import localstring from '../../services/Localization';
import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';
import ProfilePicture from '../../components/ProfilePicture';
import Tag from '../../components/Tags/Tag';
import TagList from '../../components/Tags/TagList';

import genericAvatar from '../../images/img/avatar-portrait.png';
import femaleAvatar from '../../images/img/female-avatar-portrait.png';
import maleAvatar from '../../images/img/male-avatar-portrait.png';

const styles = theme => ({
  root: {
    maxWidth: '400px',
    marginTop: 4,
    margin: '0 auto',
    overflowX: 'auto',
  },
  table: {
    minWidth: 250,
  },
});

class ClientProfile extends Component {
  constructor(props) {
    super(props);
    const cid = props.location.search.split('=')[1];
    const aid = props.location.search.split('=')[1];
    this.state = {
      isMe: false,
      loading: false,
      clientId: cid,
      clientData: null,
      agentId: aid,
      agentData: null,
      photoURL: null,
      personData: null,
      refresh: null,
      loadingExtraData: true,
      extraData: null,
      tableData: {},
      tagNames: [],
      photoURL: femaleAvatar
    };
  }

  componentDidMount() {
    const { session } = this.props;
    const agentId = getParameterByName('id');
    if (session.isAuthenticated && agentId) {
      this.loadThisClient();
      this.loadExtraData();
    }
  }

  componentDidUpdate(prevProps) {
  }

  loadThisClient = async () => {
    const { session } = this.props;
    const { clientId } = this.state;
    this.setState({ loading: true });
    try {
      const response = await api.get(`/agentClients/${clientId}`);
      if (response.data && response.data.data && response.data.data.data) {
        const clientData = response.data.data.data;
        const photoURL = clientData.client.realmData.photoUrl ? clientData.client.realmData.photoUrl : null;
        this.setState({
          clientData,
          loading: false,
          personData: clientData.client,
     //     photoURL
        });
      }
      // TODO: if is null, need USER DOESN'T EXIST
      this.setState({ loading: false });
    } catch (e) {
      // TODO: if is error, need USER DOESN'T EXIST
      this.setState({ loading: false });
    }
  };

  loadExtraData = async () => {
    const { session } = this.props;
    const { clientId } = this.state;
    this.setState({ loadingExtraData: true });
    try {
      const response = await api.get(`/agentClients/${clientId}/externalData`);
      if (response.data && response.data.data) {
        const extraData = response.data.data[0];

        const tableData = {};
        const jsonData = extraData.externalData.json;

        tableData.age = jsonData.age;
        tableData.married = jsonData.married === 'Y' ? 'Married' : 'Single';
        let photoURL = maleAvatar;
        tableData.gender = '';
        if (jsonData.gender === 'M' || jsonData.gender === 'F') {
          tableData.gender = jsonData.gender === 'M' ? 'Male' : 'Female';
           if( jsonData.gender === 'F' ) {
            photoURL = femaleAvatar;
          } else {
            photoURL = maleAvatar;
          }
        } 

        tableData.children = jsonData.numberOfChildrent > 0 ? parseInt(jsonData.numberOfChildrent, 10) : 0;
        tableData.geo = `${jsonData.city}, ${jsonData.state} ${jsonData.zipcode}`;
        tableData.income = `${jsonData.census2010IncomePercentile}%`;
        tableData.homeValue = `$${jsonData.census2010MedianHomeValue},000+`;

        const tagData = [];
        extraData.tagNames.map((tag) => {
          let nextName = tag.replace(/_/g, ' ');
          nextName = nextName.charAt(0).toUpperCase() + nextName.slice(1);
          tagData.push({ name: nextName, format: 'realmQuality' });
        })
        extraData.lifestyleNames.map((tag) => {
          let nextName = tag.replace(/_/g, ' ');
          nextName = nextName.charAt(0).toUpperCase() + nextName.slice(1);
          tagData.push({ name: nextName, format: 'realmLifestyle' });
        })
        this.setState({
          extraData,
          loadingExtraData: false,
          tableData,
          tagData,
          photoURL
        });
      }
      // TODO: if is null, need USER DOESN'T EXIST
      this.setState({ loadingExtraData: false });
    } catch (e) {
      // TODO: if is error, need USER DOESN'T EXIST
      this.setState({ loadingExtraData: false });
    }
  };

  showName = () => {
    let name = 'Client Profile';
    if (this.state.clientData && this.state.clientData.name) {
      if (this.state.clientData.name.firstName) {
        name = this.state.clientData.name.firstName;
        if (this.state.clientData.name.lastName) {
          name += ` ${this.state.clientData.name.lastName}`;
        }
      } else if (this.state.clientData.name.lastName) {
        name = this.state.clientData.name.lastName;
      } else if (this.state.clientData.name.fullName) {
        name = this.state.clientData.name.fullName;
      }
    }
    return name;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="page-with-title" key={this.state.refresh}>
        <PageTitle goBack history={this.props.history} />
        {(!this.state.clientData || this.state.loading) ? (
          <LoadingIndicator />
        ) : (
            <div className="realm--agent-profile">
              <Grid container spacing={16} alignItems="center">
                <Grid className="text-centered" item xs={12}>
                  <h1 className="realm--serified">{this.showName()}</h1>
                  <ProfilePicture
                    centered
                    size={`sm`}
                    fullName={this.state.clientData.fullName}
                    photoURL={this.state.photoURL}
                    style={{ marginBottom: 10 }}
                  />
                  <div>{this.state.clientData.client.name.fullName}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h3 className="realm--serified text-centered">Matching Qualities</h3>
                  <div className="realm--agent-profile-content-panel">
                    <TagList
                      format="default"
                      id={this.state.clientId}
                      name={this.state.clientData.fullName}
                      type="agentClients"
                      readonly={false}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h3 className="realm--serified text-centered">
                    Contact Information
                </h3>
                  <div className="realm--agent-profile-content-panel">
                    {this.state.clientData.client.emails.map((email) => (
                      <span>
                        {email.address && email.address.length > 5 ?
                          <div key={email}>
                            email:{' '}
                            <a href={`mailto:  ${email.address}`}>{email.address}</a>
                          </div>
                          : null}
                      </span>
                    ))}
                    <br />
                    {this.state.clientData.client.phones.map((phone) => (
                      <div key={phone}>
                        {phone.number && phone.number.length > 9 ?
                          <span>
                            {phone.phoneType}: {phone.number || 'No phone listed'}
                          </span> : null
                        }
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
              {(!this.state.extraData || this.state.loadingExtraData) ? (
                <LoadingIndicator
                  message={`Gathering Realm Intelligence`} />
              ) : (
                  <Grid container spacing={16}>
                    <Grid className="text-centered" item xs={12}>
                      <h2 className="realm--serified" style={{ marginTop: 64, marginBottom: 48 }}>{`Realm Intelligence for ${this.state.clientData.fullName}`}</h2>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <h3 className="realm--serified text-centered">Demographic Information</h3>
                      <div className="realm--agent-profile-content-panel">
                        <Paper className={classes.root}>
                          <Table className={classes.table}>
                            <TableBody>
                              <TableRow >
                                <TableCell component="th" scope="row">Age</TableCell>
                                <TableCell align="right">{this.state.tableData.age}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell component="th" scope="row">Gender</TableCell>
                                <TableCell align="right">{this.state.tableData.gender}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell component="th" scope="row">Location</TableCell>
                                <TableCell align="right">{this.state.tableData.geo}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell component="th" scope="row">Marital Status</TableCell>
                                <TableCell align="right">{this.state.tableData.married}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell component="th" scope="row">Children</TableCell>
                                <TableCell align="right">{this.state.tableData.children}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell component="th" scope="row">Income</TableCell>
                                <TableCell align="right">{this.state.tableData.income}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell component="th" scope="row">Home Value</TableCell>
                                <TableCell align="right">{this.state.tableData.homeValue}</TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </Paper>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <h3 className="realm--serified text-centered">Realm Intelligence's Matching Qualities</h3>
                      <div className="realm--agent-profile-content-panel">
                        {this.state.tagData && this.state.tagData.length > 0 && (
                      this.state.tagData.map((nextTag) => {
                        return(
                          <Tag
                            format={nextTag.format}
                            id={nextTag.name}
                            key={nextTag.name}
                            label={nextTag.name}
                            readonly={true}
                            tagName={nextTag.name}
                          />
                        );
                        }))}
                        </div>
                    </Grid>
                  </Grid>
                )}
            </div>
          )}
      </div>
    );
  }
}

ClientProfile.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  session: PropTypes.object
};

export default withStyles(styles)(ClientProfile);
