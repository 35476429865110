/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  createMuiTheme,
  Button,
  Grid,
  MuiThemeProvider
} from '@material-ui/core';

import api from '../../api';
import localstring from '../../services/Localization';

import HouseOfCard from '../../components/Matching/HouseOfCard';
import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';
import TableView from './TableView';
import ViewToggle from '../../components/PageControls/ViewToggle';

const theme = createMuiTheme({
  palette: {
    type: 'dark'
  },
  overrides: {
    MuiTab: {
      label: {
        fontSize: '14px !important'
      }
    },
    MuiTabs: {
      flexContainer: {
        background: '#888'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          // underline color when textfield is inactive
          // backgroundColor: 'black',
          borderBottom: 'black'
        },
        '&:hover:not($disabled):before': {
          // underline color when hovered
          //  backgroundColor: 'blue',
          borderBottom: 'red'
        }
      }
    }
  }
});

class Matching extends Component {
  state = {
    data: [],
    gridView: true,
    loading: false,
    noRecordsFound: false,
    totalRecords: 0
  };

  componentWillMount() {
    this.getMatching();
  }

  getMatching = async () => {
    const { session } = this.props;
    this.setState({ loading: true });
    // assign to this agent.. unless admin in which case look at filters
    let thisAgent = null;
    // Get Query Params for agent ... if any
    thisAgent = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).agent;
    if (session.agentData && session.agentData._id) {
      thisAgent = session.agentData._id;
    } else {
      const { agentData } = session;
      if (agentData && JSON.parse(agentData)._id) {
        thisAgent = JSON.parse(agentData)._id;
      }
    }
    const listingsFilter = {};
    if (thisAgent) {
      listingsFilter.listingAgents = thisAgent;
    }
    const response = await api.post('/listings/filter', {
      page: 1,
      limit: 50,
      filter: listingsFilter
    });
    this.setState({
      data: response.data.data.data,
      loading: false,
      noRecordsFound: response.data.data.total === 0
    });
  };

  toggleView = (isGridView) => {
    this.setState({ gridView: isGridView });
  };

  showMatchingDetail = (property) => {
    window.location.replace(`/match-detail?id=${property._id}`);
  };

  matchListings = async () => {
    const response = await api.get('/match/allListings');
    return response;
  };

  refreshMatches = () => {
    this.setState({ loading: true });
    this.getMatching();
  };

  renderGridView = () => (
    <div className="realm--grid-container">
      <Grid container spacing={24}>
        {this.state.data.map((property) => (
          <HouseOfCard key={property._id} matching property={property} />
        ))}
      </Grid>
    </div>
  );

  renderTableView = () => <TableView data={this.state.data} />;

  renderView = () => {
    const { gridView, noRecordsFound } = this.state;
    if (noRecordsFound) {
      return (
        <div className="realm--no-records-found">
          <div className="realm--no-records-found-message">
            <p><strong>No matching properties were found.</strong></p>
          </div>
        </div>
      );
    }
    return gridView || this.props.application.isMobileOnly ?
      this.renderGridView() : this.renderTableView();
  };

  render() {
    const { application } = this.props;
    return (
      <div className="page-with-title" id="container">
        <PageTitle title={localstring.matching_page_name} />
        <MuiThemeProvider theme={theme}>
          {!application.isMobileOnly && (
            <div
              className="realm--legend-container"
              style={{
                opacity: this.state.loading ? '.5' : '1',
                pointerEvents: this.state.loading ? 'none' : 'auto'
              }}
            >
              <ul className="realm--legend">
                <li>
                  {localstring.high_matches}{' '}
                  <span className="realm--legend-block high" />
                </li>
                <li>
                  {localstring.med_matches}{' '}
                  <span className="realm--legend-block md" />
                </li>
                <li>
                  {localstring.low_Matches}{' '}
                  <span className="realm--legend-block low" />
                </li>
                <li>
                  <Button
                    color="default"
                    variant="contained"
                    size="small"
                    onClick={this.refreshMatches}
                  >
                    Refresh Matches
                  </Button>
                </li>
              </ul>
              <ViewToggle clickHandler={this.toggleView} gridView />
            </div>
          )}
          {this.state.loading ? (
            <div className="realm--loading-container">
              <LoadingIndicator
                message={`Matching your properties...`}
              />
            </div>
          ) : (
            this.renderView()
          )}
        </MuiThemeProvider>
      </div>
    );
  }
}

Matching.propTypes = {
  application: PropTypes.object,
  session: PropTypes.object
};

export default Matching;
