import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

class MatchCircleGraph extends Component {
  state = {
    high: {},
    middle: {},
    low: {},
    total: 0,
    perimeter: 140,
  };

  componentWillMount() {
    this.setInitialState();
  }

  componentWillReceiveProps(nextProps) {
    const total = nextProps.high + nextProps.med + nextProps.low;
    const range = 140 - 15;
    const highTo = 15 + (range * (nextProps.high / total));
    const medTo = highTo + (range * (nextProps.med / total));
    const high = {
      from: 15,
      to: highTo,
      data: [{ name: 'active', value: 10 }, { name: 'inactive', value: 66 }]
    };
    const middle = {
      from: highTo,
      to: medTo,
      data: [{ name: 'active', value: 25 }, { name: 'inactive', value: 75 }]
    };
    const low = {
      from: medTo,
      to: 140,
      data: [{ name: 'active', value: 52 }, { name: 'inactive', value: 48 }]
    };
    this.setState({
      high,
      middle,
      low,
      total
    });
  }

  setInitialState = () => {
    const { high, med, low } = this.props;
    const total = high + med + low;
    const range = 140 - 15;
    const highTo = 15 + (range * (high / total));
    const medTo = highTo + (range * (med / total));
    this.setState({
      high: {
        from: 15,
        to: highTo,
        data: [{ name: 'active', value: 33 }, { name: 'inactive', value: 66 }]
      },
      middle: {
        from: highTo,
        to: medTo,
        data: [{ name: 'active', value: 25 }, { name: 'inactive', value: 75 }]
      },
      low: {
        from: medTo,
        to: 140,
        data: [{ name: 'active', value: 52 }, { name: 'inactive', value: 48 }]
      },
      total
    });
  }

  render() {
    if (this.state.total === 0) {
      return (
        <ResponsiveContainer width={320} height={320}>
          <PieChart>
            <Pie
              stroke="#8888ff"
              data={this.state.high.data}
              dataKey="value"
              innerRadius={this.state.perimeter - 3}
              outerRadius={this.state.perimeter}
              fill="#8888ff"
            />
          </PieChart>
        </ResponsiveContainer>
      );
    }
    return (
      <ResponsiveContainer width={320} height={320}>
        <PieChart>
          <Pie
            data={this.state.high.data}
            dataKey="value"
            innerRadius={this.state.high.from}
            outerRadius={this.state.high.to}
            fill="#8888ff"
            stroke="transparent"
          />
          <Pie
            data={this.state.middle.data}
            dataKey="value"
            innerRadius={this.state.middle.from}
            outerRadius={this.state.middle.to}
            fill="#9fa1c7"
            stroke="transparent"
          />
          <Pie
            data={this.state.low.data}
            dataKey="value"
            innerRadius={this.state.low.from}
            outerRadius={this.state.low.to}
            fill="#eeefff"
            stroke="transparent"
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

MatchCircleGraph.propTypes = {
  high: PropTypes.number,
  med: PropTypes.number,
  low: PropTypes.number
};

export default MatchCircleGraph;
