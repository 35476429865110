/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash.merge';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core';
import USStates from '../../utils/us-states';
import countries from '../../utils/countries';
import localstring from '../../services/Localization';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

class AddBrokerageDialog extends Component {
  state = {};
  delay = null;

  componentWillMount() {
    this.setInitialState();
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
      this.delay = null;
    }
  }

  setInitialState() {
    this.setState({
      brokerageName: '',
      officeCity: '',
      officeCountry: 'United States',
      officeName: '',
      officePhone: '',
      officeStreetAddress: '',
      officeState: '',
      officeZipCode: '',
      isDirty: false,
      isLoading: false,
      isValidForm: false,
      isOfficeSelected: 'no',
      showCreateOfficeLocation: false
    });
  }

  onInput = (e) => {
    const newValue = {};
    newValue[e.target.name] = e.target.value;
    const newState = merge({}, this.state, newValue);
    this.setState(newState, () => {
      this.setState({
        isDirty: true,
        isValidForm: this.isValidForm()
      });
    });
  };

  isValidForm = () => {
    const {
      brokerageName,
      officeName,
      officePhone,
      officeStreetAddress,
      officeCity,
      officeState,
      officeZipCode,
      officeCountry,
      isOfficeSelected
    } = this.state;
    const hasValidRequired = brokerageName !== '';
    const hasValidOptional =
      isOfficeSelected === 'yes'
        ? officeName !== '' &&
          officePhone !== '' &&
          this.isValidPhone(officePhone) &&
          officeStreetAddress !== '' &&
          officeCity !== '' &&
          officePhone !== '' &&
          officeState !== '' &&
          officeZipCode !== '' &&
          officeCountry !== ''
        : true;
    return hasValidRequired && hasValidOptional;
  };

  isValidPhone = (phone) =>
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone);

  createBrokerage = () => {
    const officeLocation = this.state.isOfficeSelected === 'yes'
      ? {
        offices: [
          {
            officeName: this.state.officeName,
            phone: this.state.officePhone,
            // email: '',
            // websiteURL: '',
            streetAddress: this.state.officeStreetAddress,
            // unitNumber: '',
            city: this.state.officeCity,
            state: this.state.officeState,
            zipcode: this.state.officeZipCode,
            // logoURL: '',
            country: this.state.officeCountry
          }
        ]
      }
      : null;
    const newBrokerage = {
      name: this.state.brokerageName,
    };
    const brokerageRecord = Object.assign({}, newBrokerage, officeLocation);
    if (this.props.handleCreate) {
      this.props.handleCreate(brokerageRecord, this.onSuccess, this.onFailure);
      this.setState({
        isLoading: true
      });
    }
  };

  onSuccess = () => {
    this.delay = setTimeout(() => {
      this.setInitialState();
    }, 1000);
  }

  onFailure = (e) => {
    console.log('failed', e);
  }

  handleOfficeSelector = (e) => {
    this.setState({
      isOfficeSelected: e.target.value
    }, () => {
      this.setState({
        isDirty: true,
        isValidForm: this.isValidForm()
      });
    });
  };

  getStates = () => {
    const states = Object.keys(USStates);
    return states.map((key) => (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    ));
  };

  getCountries = () =>
    countries.map((country) => (
      <MenuItem key={country} value={country}>
        {country}
      </MenuItem>
    ));

  render() {
    return (
      <Dialog
        aria-describedby="add-brokerage-dialog"
        aria-labelledby="add-brokerage-dialog-title"
        open={this.props.open || false}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="add-brokerage-dialog-title">
          {localstring.create_new_brokerage}
        </DialogTitle>
        <DialogContent className="edit-modal">
          {this.state.isLoading ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div style={{ width: 32, margin: '0 auto' }}>
                <LoadingIndicator style={{ marginLeft: -16 }} />
              </div>
            </div>
          ) : (
            <Fragment>
              <TextField
                id="brokerageName"
                fullWidth
                helperText=""
                label={localstring.brokerage_name}
                margin="dense"
                name="brokerageName"
                onChange={this.onInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.brokerageName}
              />
              <FormControl component="fieldset" style={{ margin: '20px 0 0' }}>
                <FormLabel component="legend" style={{ marginBottom: 10 }}>
                  {localstring.create_office_location}
                </FormLabel>
                <RadioGroup
                  aria-label="Create Office Location"
                  name="isOfficeSelected"
                  value={this.state.isOfficeSelected}
                  onChange={this.handleOfficeSelector}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" style={{ height: 24 }} />}
                    label={localstring.yes}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" style={{ height: 24 }} />}
                    label={localstring.no}
                  />
                </RadioGroup>
              </FormControl>
              {this.state.isOfficeSelected === 'yes' && (
                <div>
                  <TextField
                    id="officeName"
                    fullWidth
                    helperText=""
                    label={localstring.office_name}
                    margin="dense"
                    name="officeName"
                    onChange={this.onInput}
                    style={{ width: '100%' }}
                    type="text"
                    value={this.state.officeName}
                  />
                  <TextField
                    id="officePhone"
                    fullWidth
                    helperText=""
                    label={localstring.office_phone}
                    margin="dense"
                    name="officePhone"
                    onChange={this.onInput}
                    style={{ width: '100%' }}
                    type="text"
                    value={this.state.officePhone}
                  />
                  <TextField
                    id="officeStreetAddress"
                    fullWidth
                    helperText=""
                    label={localstring.address}
                    margin="dense"
                    name="officeStreetAddress"
                    onChange={this.onInput}
                    style={{ width: '100%' }}
                    type="text"
                    value={this.state.officeStreetAddress}
                  />
                  <TextField
                    id="officeCity"
                    helperText=""
                    label={localstring.city}
                    margin="dense"
                    name="officeCity"
                    onChange={this.onInput}
                    style={{ width: '45%', marginRight: '5%' }}
                    type="text"
                    value={this.state.officeCity}
                  />
                  <FormControl style={{ width: '20%', marginBottom: 10 }}>
                    <InputLabel className="muiInputLabel" htmlFor="State">
                      {localstring.state}
                    </InputLabel>
                    <Select
                      value={this.state.officeState}
                      onChange={this.onInput}
                      inputProps={{
                        name: 'officeState',
                        id: 'selected-state'
                      }}
                    >
                      {this.getStates()}
                    </Select>
                  </FormControl>
                  <TextField
                    id="officeZipCode"
                    helperText=""
                    label={localstring.zip}
                    margin="dense"
                    name="officeZipCode"
                    onChange={this.onInput}
                    style={{ width: '25%', marginLeft: '5%' }}
                    type="text"
                    value={this.state.officeZipCode}
                  />
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel
                      className="muiInputLabel"
                      htmlFor="officeCountry"
                    >
                      {localstring.country}
                    </InputLabel>
                    <Select
                      value={this.state.officeCountry}
                      onChange={this.onInput}
                      inputProps={{
                        name: 'officeCountry',
                        id: 'selected-country'
                      }}
                    >
                      {this.getCountries()}
                    </Select>
                  </FormControl>
                </div>
              )}
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            color="default"
            variant="contained"
          >
            {localstring.cancel}
          </Button>
          <Button
            onClick={this.createBrokerage}
            color="primary"
            variant="contained"
            disabled={!this.state.isValidForm}
          >
            {localstring.onboarding.continue}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddBrokerageDialog.propTypes = {
  handleCreate: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default AddBrokerageDialog;
