import api from '../api';
import store from '../store';
import toTitleCase from '../utils/to-title-case';
import * as storage from '../persistence/storage';

class AppData {
  static tagCategories;

  static userTags;

  static tagsLoaded = false;

  static formValues = {};

  static user;

  static selectedTags = [];

  static currentUser = null;

  static getCurrentUser() {
    if (this.currentUser === null) {
      this.currentUser = JSON.parse(storage.get('me'));
    }
    return this.currentUser;
  }

  static async loadSelectedTags(tagType, p1) {
    try {
      const response = await api.get(`/${tagType}/${p1}`);
      const { data } = response.data.data;
      if (data.realmData) {
        this.selectedTags = data.realmData.tagNames ? data.realmData.tagNames : [];
      }
    } catch (exception) {
      this.selectedTags = [];
    }
    return null;
  }

  static async addUserTag(tagType, userId, tagName) {
    const encodedTagName = encodeURIComponent(tagName);
    const response = await api.post(
      `/${tagType}/${userId}/tag/${encodedTagName}`
    );
    this.selectedTags = response.data.data.realmData.tagNames;
    return null;
  }

  static async deleteUserTag(tagType, userId, tagName) {
    const encodedTagName = encodeURIComponent(tagName);
    await api.delete(`/${tagType}/${userId}/tag/${encodedTagName}`);
    return null;
  }

  static async loadTags(type) {
    let section = '';
    switch (type) {
      case 'agents':
        section = 'Agent';
        break;
      case 'agentClients':
        section = 'AgentClient';
        break;
      case 'brokerage':
        section = 'Brokerage';
        break;
      case 'listings':
        section = 'Listing';
        break;
      case 'properties':
        section = 'Properties';
        break;
      case 'listings':
        section = 'Listing';
        break;
      default:
        return null;
    }

    const response = await api.post('tagCategories/filter', {
      filter: {
        appliesTo: section
      }
    });
    const language = store.getState().application.locale;
    // returns an array of brokerages
    AppData.tagCategories = {};
    const categoryData = response.data.data.data;
    for (let i = 0, len = categoryData.length; i < len; i++) {
      // Get translated name if possible
      const categoryName =
        categoryData[i].name[language] || categoryData[i].name.en;
      AppData.tagCategories[categoryName] = AppData.convertTags(
        categoryData[i].tags,
        language
      );
    }
    AppData.tagsLoaded = true;
    return null;
  }

  static convertTags(tags) {
    const newTags = [];
    for (let i = 0, len = tags.length; i < len; i++) {
      // Get translated name if possible
      const label = toTitleCase(tags[i].tagName.replace(/_/g, ' '));
      newTags.push({
        label,
        id: tags[i]._id,
        tagName: tags[i].tagName
      });
    }
    return newTags;
  }
}

export default AppData;
