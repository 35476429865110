const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: -6,
  },
  hide: {
    display: 'none',
  },
  menu: {
    marginTop: 32
  },
  menucount: {
    left: 45,
    top: 28,
    fontSize: 9,
    padding: '1px 3px',
    borderRadius: 2,
    backgroundColor: '#ac5f0f'
  },
  closedmenucount: {
    position: 'absolute'
  },
  olbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#000'
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  appName: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 300,
    paddingTop: 15
  },
  toolbarBG: {
    backgroundColor: '#000'
  },
  mobileMenuItem: {
    color: '#fff',
    fontSize: 22,
    marginBottom: 8,
    '&:hover': {
      color: '#ccf'
    }
  }
});

export default styles;
