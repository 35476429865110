import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';

import api from '../../api';
import localstring from '../../services/Localization';
import LoadingIndicator from '../LoadingIndicator';

class UploadClients extends Component {
  state = {
    dropzoneErrorMessage: '',
    fileFormat: '',
    fileToUpload: null,
    showUploadDialog: false,
    showUploadStateDialog: false,
    uploadSuccessful: '',
    visibleFromButton: false
  };

  delay = null;

  uploadFile = () => {
    this.setState({ isUploading: true });
    const data = new FormData();
    data.append('file', this.state.fileToUpload);
    data.append('agentId', this.props.agentId);
    data.append('fileFormat', this.state.fileFormat);
    api
      .post('/uploads/agents/client-csv', data)
      .then(() => {
        this.setState({
          dropzoneErrorMessage: '',
          showUploadStateDialog: true,
          uploadSuccessful: true,
          isUploading: false,
          fileToUpload: null,
        });
      })
      .catch(() => {
        this.setState({
          dropzoneErrorMessage: '',
          showUploadStateDialog: true,
          uploadSuccessful: false,
          isUploading: false,
        });
      });
  };

  handleDrop = (files) => {
    if (files[0].size > 6000000) {
      this.setState({
        dropzoneErrorMessage: `Sorry, we cannot upload ${files[0].name}. The file size must be 6MB or less.`
      });
    } else {
      this.setState({
        dropzoneErrorMessage: '',
        fileToUpload: files[0],
        showUploadDialog: true
      });
    }
  };

  updatePerson = async () => {
    this.props.actions.getAgentData();
    this.handleClose();
  };

  rejectFile = () => {
    this.setState({
      fileToUpload: null,
      showUploadDialog: false
    });
  };

  acceptFile = () => {
    this.setState({
      showUploadDialog: false
    });
  };

  endUpload = () => {
    this.setState({
      dropzoneErrorMessage: '',
      showUploadDialog: false,
      showUploadStateDialog: false,
      isUploading: false
    });
    if (this.state.uploadSuccessful) {
      this.updatePerson();
    } else {
      this.handleClose();
    }
    // below avoids blinking in modal as it fades out
    this.delay = setTimeout(() => {
      this.setState({
        fileFormat: '',
        fileToUpload: null
      });
    }, 350);
  };

  handleClose = () => {
    this.props.handleClose();
    this.setState({ visibleFromButton: false });
  };

  handleUploadFromDialog = () => {
    // this.props.handleClose();
    // this.setState({ visibleFromButton: false });
    this.uploadFile();
  };

  handleSelection = (event) => {
    this.setState({ fileFormat: event.target.value });
  };

  selectedUploadButton = () => {
    this.setState({ visibleFromButton: true });
  };

  renderDialog() {
    const {
      isVisible,
      forName,
      showButton,
      showIconButton
    } = this.props;
    const dropzoneMessage = this.state.fileToUpload ? this.state.fileToUpload.name : localstring.drop_csv;
    return (
      <span>
        {showButton && (
          <div style={{ display: 'inline-block', width: '100%' }}>
            <Button
              fullWidth={showIconButton ? undefined : true}
              variant={showIconButton ? undefined : 'outlined'}
              onClick={this.selectedUploadButton}
            >
              {showIconButton ? (
                <UploadIcon style={{ color: '#ac5f0f' }} />
              ) : (
                'Upload Clients'
              )}
            </Button>
          </div>
        )}
        <Dialog
          aria-describedby="delete-dialog"
          aria-labelledby="delete-dialog-title"
          onClose={this.handleClose}
          open={isVisible || this.state.visibleFromButton}
        >
          <DialogTitle id="delete-dialog-title">
            Add/Upload Client CSV File
          </DialogTitle>
          <div
            style={{
              color: '#fff',
              fontSize: 18,
              paddingLeft: 24,
              marginTop: -10,
              marginBottom: 20
            }}
          >
            for {forName}
          </div>
          <DialogContent className="edit-modal">
            <FormControl style={{ width: 200, marginBottom: 20 }}>
              <InputLabel htmlFor="age-simple">
                File Format
              </InputLabel>
              <Select
                className="selectWhite"
                value={this.state.fileFormat}
                onChange={this.handleSelection}
                inputProps={{
                  name: 'selectFormat',
                  id: 'inviteFormat'
                }}
                required
              >
                <MenuItem value="TopProducer">TopProducer CRM</MenuItem>
                <MenuItem value="SalesForce">SalesForce</MenuItem>
                <MenuItem value="Outlook">Outlook</MenuItem>
                <MenuItem value="MailChimp">Mail Chimp</MenuItem>
                <MenuItem value="Realm">Realm Format</MenuItem>}
              </Select>
            </FormControl>

            <Dropzone
              className="realm-onboarding--dropzone"
              onDrop={(files) => this.handleDrop(files)}
            >
              {this.state.isUploading ? (
                <LoadingIndicator />
              ) : (
                <div>{this.state.dropzoneErrorMessage || dropzoneMessage}</div>
              )}
            </Dropzone>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={this.uploadFile}
              color="primary"
              variant="contained"
              disabled={!this.state.fileFormat || !this.state.fileToUpload}
            >
              Upload Now
            </Button>
          </DialogActions>
        </Dialog>

        {/* upload confirm dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showUploadDialog}
        >
          <DialogTitle>Uploading a client CSV</DialogTitle>
          <DialogContent className="realm--white-text">
            You are uploading a file named{' '}
            {this.state.fileToUpload && this.state.fileToUpload.name}?
            <br />
            Do you wish to proceed?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.rejectFile}
              color="default"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={this.acceptFile}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* upload state dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showUploadStateDialog}
        >
          <DialogTitle>
            {typeof this.state.uploadSuccessful !== 'string' &&
            this.state.uploadSuccessful
              ? 'Success!'
              : 'Upload failed'}
          </DialogTitle>
          <DialogContent style={{ color: '#fff' }}>
            {typeof this.state.uploadSuccessful !== 'string' &&
            this.state.uploadSuccessful
              ? 'Your client file has been uploaded to the REALM. Through the REALM proprietary process we will match properties with clients. In the REALM Beta site, this process will take a handful of days. To expedite your matches, please add Matching Quality tags to both your active properties and clients.'
              : 'The upload failed. Please contact support if you continue to have problems.'}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.endUpload}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }

  render() {
    return <span>{this.renderDialog()}</span>;
  }
}

UploadClients.propTypes = {
  handleClose: PropTypes.func,
  isVisible: PropTypes.bool
};

export default UploadClients;
