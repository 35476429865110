import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import api from '../../api';

import LoadingIndicator from '../LoadingIndicator';

class BlogList extends Component {
  state = {
    list: [],
    loading: false
  };

  componentDidMount() {
    this.fetchBlogList();
  }

  fetchBlogList = async () => {
    this.setState({ loading: true });
    try {
      const response = await api.get('/cms/by-category', {
        params: {
          categories: this.props.categories,
          count: this.props.count,
          language: this.props.language,
          view: this.props.view, // if 'card' then returns all the extra data for each list itme,
        }
      });
      this.setState({
        list: response.data.data.data,
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  };

  showBlogName(entry) {
    const title = entry.title.rendered;
    const firstBrace = title.indexOf('{') + 1;
    const lastBrace = title.lastIndexOf('}');
    return title.substring(firstBrace, lastBrace);
  }

  showFeaturedImage(entry) {
    const imageURL = entry.fifu_image_url;
    const imageAlt = entry.fifu_image_alt;
    return (
      <img
        src={imageURL}
        style={{ width: '100%', height: 'auto' }}
        alt={imageAlt}
      />
    );
  }

  render() {
    return (
      <span>
        {this.props.grid ? (
          <div>
            {this.state.loading ? (
              <div className="realm--loading-container">
                <LoadingIndicator />
              </div>
            ) : (
              <Grid container spacing={24}>
                {this.state.list.map((entry) => (
                  <Grid key={entry.slug} item xs={12} sm={6} md={4} lg={3}>
                    <div className="realm--blog-card">
                      <Link to={`/blogpost?name=${entry.slug}`}>
                        {this.showFeaturedImage(entry)}
                        <div className="realm--blog-card-link">
                          {this.showBlogName(entry)}
                        </div>
                      </Link>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        ) : (
          <div className="realm--blog-list-panel">
            <div className="realm--blog-category">{this.props.title}</div>
            {this.state.loading ? (
              <div className="realm--loading-container">
                <LoadingIndicator />
              </div>
            ) : (
              <div>
                {
                  this.state.list.map((entry) => {
                    if (entry.slug === this.props.currentStory) {
                      return null;
                    }
                    return (
                      <div key={entry.slug}>
                        {this.props.view === 'card' ? (
                          <div className="realm--blog-card">
                            <Link to={`/blogpost?name=${entry.slug}`}>
                              {this.showFeaturedImage(entry)}
                              <div className="realm--blog-card-link">
                                {this.showBlogName(entry)}
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <div className="realm--blog-list">
                            <Link
                              to={`/blogpost?name=${entry.slug}`}
                              className="realm--blog-link"
                            >
                              {this.showBlogName(entry)} <br />
                              <br />
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })
                }
              </div>
            )}
          </div>
        )}
      </span>
    );
  }
}

BlogList.propTypes = {
  categories: PropTypes.array,
  count: PropTypes.number,
  currentStory: PropTypes.string,
  grid: PropTypes.bool,
  language: PropTypes.string,
  title: PropTypes.string,
  view: PropTypes.string
};

BlogList.defaultProps = {
  categories: [24],
  count: 5,
  language: 'en',
  title: 'Featured',
  view: 'list'
};

export default BlogList;
