import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import isFunction from 'lodash.isfunction';
import merge from 'lodash.merge';
import Pagination from 'material-ui-flat-pagination';
import {
  Button,
  Grid,
  TextField,
  Switch
} from '@material-ui/core';

import api from '../../api';
import * as storage from '../../persistence/storage';

import AddBrokerageDialog from '../../components/Dialogs/AddBrokerageDialog';
import BrokerageCard from '../../components/BrokerageCard';
import LoadingIndicator from '../../components/LoadingIndicator';
import OfficeCard from '../../components/Brokerages/OfficeCard';
import PageTitle from '../../components/PageTitle';
import TagList from '../../components/Tags/TagList';

class Brokerages extends Component {
  state = {
    isAddingBrokerage: false,
    filterParams: {
      page: 1,
      limit: 10
    },
    showArchived: this.props.session.isAdmin,
    offset: 0
  };

  componentDidMount() {
    const { actions, session } = this.props;
    !session.brokerages &&
      actions.getBrokerageData(
        this.state.filterParams.page,
        this.state.filterParams.limit,
        this.state.showArchived
      );
  }

  handleChange = (e) => {
    this.setState({ value: e.target.description });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  toggleNewBrokerageDialog = () => {
    this.setState({
      isAddingBrokerage: !this.state.isAddingBrokerage
    });
  };

  createNewBrokerage = async (brokerageRecord, onSuccess, onFailure) => {
    try {
      await api.post('/brokerages', brokerageRecord);
      this.setState(
        {
          isAddingBrokerage: false
        },
        () => {
          if (isFunction(onSuccess)) {
            onSuccess();
            storage.remove('brokerages');
            this.props.actions.getBrokerageData(
              this.state.filterParams.page,
              this.state.filterParams.limit,
              this.state.showArchived
            );
            // this.getBrokerages();
          }
        }
      );
    } catch (e) {
      if (isFunction(onFailure)) {
        onFailure(e);
      }
    }
  };

  // *******************************
  //
  // show/hide archived brokerages
  //
  // ********************************
  handleShowArchived = () => {
    const { filterParams } = this.state;
    const newState = merge({}, filterParams, {
      page: 0
    });
    this.setState(
      {
        filterParams: newState,
        showArchived: !this.state.showArchived
      },
      () => {
        this.props.actions.getBrokerageData(
          this.state.filterParams.page,
          this.state.filterParams.limit,
          this.state.showArchived
        );
      }
    );
  };

  // *******************************
  //
  // archive this brokerage
  //
  // ********************************
  archiveBrokerage = async (bid, onSuccess, onFailure) => {
    this.setState({ isLoading: true });
    alert(`archive ${bid}`);
    try {
      await api.post('/brokerages/archive', { brokerageId: bid });
      this.setState(
        {
          isLoading: false
        },
        () => {
          if (isFunction(onSuccess)) {
            onSuccess();
            storage.remove('brokerages');
            this.props.actions.getBrokerageData(
              this.state.filterParams.page,
              this.state.filterParams.limit,
              this.state.showArchived
            );
            // this.getBrokerages();
          }
        }
      );
    } catch (e) {
      if (isFunction(onFailure)) {
        onFailure(e);
      }
    }
  };

  // RENDER
  renderCards = (data) =>
    data.map((card) => (
      <BrokerageCard
        data={card}
        id={card._id}
        key={card.id}
      />
    ));

  renderView = () => {
    const { isMobileOnly } = this.props.application;
    const { brokerages, isAdmin } = this.props.session;
    const {
      filterParams,
      gridView,
      noRecordsFound
    } = this.state;
    const lowerRange = (filterParams.page - 1) * filterParams.limit;
    const upperRange = lowerRange + filterParams.limit;
    const visibleBrokerages = brokerages.slice(lowerRange, upperRange);
    if (noRecordsFound) {
      return (
        <div className="realm--no-records-found">
          <div className="realm--no-records-found-message">
            <p>
              <strong>No records were found.</strong>
            </p>
          </div>
        </div>
      );
    }
    if (gridView || isMobileOnly) {
      return (
        <div className="realm--grid-container">
          <Grid container spacing={24}>
            {visibleBrokerages && this.renderCards(visibleBrokerages)}
          </Grid>
        </div>
      );
    }
    return (
      <ReactTable
        className="-striped -highlight"
        data={visibleBrokerages}
        defaultPageSize={filterParams.limit}
        filterable={false}
        headerClassName="test-header"
        showPagination={false}
        sortable={false}
        SubComponent={(row) => (
          <div className="brokerage-address">
            <div className="profileSection"> {row.original.name}</div>
            {isAdmin ? (
              <Grid container spacing={24}>
                <Grid item xs>
                  Company Logo
                  <div className="profileLogoEmpty">
                    {isAdmin ? (
                      <Button className="MuiDefault" size="large">
                        Upload
                      </Button>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs>
                  Description
                  <div className="profileDescription">
                    <form onSubmit={this.handleSubmit}>
                      {isAdmin ? (
                        <span>
                          <textarea
                            cols="40"
                            rows="10"
                            type="text"
                            description={this.state.value}
                            onChange={this.handleChange}
                          />
                          <Button className="MuiDefault" size="large">
                            Update
                          </Button>
                        </span>
                      ) : (
                        <div>
                          {row.original.realmData.description &&
                          row.original.realmData.description !== '' ?
                            (
                              <span>
                                {row.original.realmData.description}
                              </span>
                            ) : (
                              <span>
                                <em>No description available.</em>
                              </span>
                            )}
                        </div>
                      )}
                    </form>
                  </div>
                </Grid>
                <Grid item xs>
                  Specialties
                  <TagList
                    id={row.original._id}
                    name={row.original.name}
                    type="brokerages"
                  />
                </Grid>
              </Grid>
            ) : null}

            {isAdmin ? (
              <span>
                <hr />
                <div className="profileSection">Brokerage Contacts</div>
                <div>
                  <TextField
                    id="contact-name"
                    label="Name"
                    placeholder="Name"
                    className="editTextField"
                    margin="normal"
                  />{' '}
                  &nbsp; &nbsp;
                  <TextField
                    id="contact-for"
                    label="Contact For"
                    placeholder="Contact For"
                    className="editTextField"
                    margin="normal"
                  />{' '}
                  &nbsp; &nbsp;
                  <TextField
                    id="contact-for"
                    label="Contact For"
                    placeholder="Contact For"
                    className="editTextField"
                    margin="normal"
                  />{' '}
                  &nbsp; &nbsp;
                  <TextField
                    id="contact-email"
                    label="Email"
                    placeholder="Email"
                    className="editTextField"
                    margin="normal"
                  />{' '}
                  &nbsp; &nbsp;
                  <TextField
                    id="contact-phone"
                    label="Phone"
                    placeholder="Phone"
                    className="editTextField"
                    margin="normal"
                  />
                  {isAdmin ? <Button className="MuiDefault">Add</Button> : null}
                </div>
              </span>
            ) : null}
            <hr />
            <div className="profileSection">Office Locations</div>
            <Grid container spacing={24}>
              {row.original.offices.map((office) => (
                <Grid item xs sm={6} md={4} lg={3} key={office.officeName}>
                  <OfficeCard isAdmin={isAdmin} office={office} />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        columns={[
          {
            columns: [
              {
                Header: 'Name',
                accessor: 'name',
                width: 250
              },
              {
                Header: '',
                Cell: (cellInfo) => (
                  <div>
                    <Button className="MuiDefault" size="small">
                      <Link to={`/agents/?brokerage=${cellInfo.original._id}`}>
                        See Agents ({cellInfo.original.agents.length})
                      </Link>
                    </Button>
                    <Button className="MuiDefault" size="small">
                      {' '}
                      Offices ({cellInfo.original.offices.length}){' '}
                    </Button>
                    <Button className="MuiDefault" size="small">
                      <Link
                        to={`/properties/list/?brokerage=${
                          cellInfo.original._id
                        }`}
                      >
                        See Listings ({cellInfo.original.agents.length})
                      </Link>
                    </Button>
                    {this.props.session.isAdmin ? (
                      <Fragment>
                        |
                        <Button className="MuiDefault" size="small">
                          {' '}
                          Upload Agents{' '}
                        </Button>
                        |
                        <Button className="MuiDefault" size="small">
                          {' '}
                          Upload Listings
                        </Button>
                        |
                        <Button
                          onClick={() => this.archiveBrokerage(cellInfo.original._id)}
                          className="MuiDefault"
                          size="small"
                        >
                          {' '}
                          Delete (Archive) This Brokerage
                        </Button>
                      </Fragment>
                    ) : null}
                  </div>
                ),
                style: { paddingTop: '0px' }
              }
            ]
          }
        ]}
      />
    );
  };

  // PAGINATION
  nextPage = (offset) => {
    const { limit } = this.state.filterParams;
    const newState = merge({}, this.state.filterParams, {
      page: offset / limit + 1
    });
    this.setState({ offset, filterParams: newState });
  };

  render() {
    const { brokerages, totalBrokerages, isAdmin } = this.props.session;
    const { isMobile } = this.props.application;
    const { filterParams, offset } = this.state;
    const showPagination = totalBrokerages > filterParams.limit;
    return (
      <div className="page-with-title">
        <PageTitle
          addPlus={!isMobile && isAdmin}
          handleClick={this.toggleNewBrokerageDialog}
          title="Brokerages"
        />
        {!brokerages ? (
          <div className="realm--loading-container">
            <LoadingIndicator />
          </div>
        ) : (
          <Fragment>
            {this.props.session.isAdmin && (
              <div className="realm--filter switch">
                <Switch
                  checked={this.state.showArchived}
                  onChange={this.handleShowArchived}
                  color="primary"
                />
                <span className="realm--property-labels">Show Archived</span>
              </div>
            )}
            {this.renderView()}
            {showPagination && (
              <div style={{ width: '100%', margin: 10, textAlign: 'center' }}>
                <Pagination
                  currentPageColor="primary"
                  limit={filterParams.limit}
                  offset={offset}
                  total={totalBrokerages}
                  onClick={(e, offset) => this.nextPage(offset)}
                  otherPageColor="default"
                  style={{ margin: '0 auto' }}
                />
              </div>
            )}
          </Fragment>
        )}
        {isAdmin && (
          <AddBrokerageDialog
            handleCreate={this.createNewBrokerage}
            handleClose={this.toggleNewBrokerageDialog}
            open={this.state.isAddingBrokerage}
          />
        )}
      </div>
    );
  }
}

Brokerages.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  session: PropTypes.object
};

export default Brokerages;
