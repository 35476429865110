import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import getParameterByName from '../../utils/get-parameter-by-name';

import api from '../../api';

import BlogList from '../../components/BlogList';
import LoadingIndicator from '../../components/LoadingIndicator';
import PageTitle from '../../components/PageTitle';

class BlogPost extends Component {
  constructor(props) {
    super(props);
    const slug = getParameterByName('name');
    this.state = {
      blogContent: [],
      loading: true,
      slug
    };
  }

  componentDidMount() {
    this.getStory();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const newSlug = getParameterByName('name');
      this.getStory(newSlug);
    }
  }

  getStory = (newSlug) => {
    this.setState(
      {
        loading: true,
        slug: newSlug || this.state.slug
      },
      () => {
        this.fetchPost();
      }
    );
  };

  fetchPost = async () => {
    // Get one blog post
    // note: slug must include language at end...
    // e.g. regenerative-home-that-enhances-your-well-being-fr
    try {
      const response = await api.get('/cms/full-post', {
        params: {
          slug: this.state.slug,
          language: this.props.application.locale
        }
      });
      window.scrollTo(0, 0);
      this.setState({
        blogContent: response.data.data.data[0],
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  showBlogName() {
    if (!this.state.blogContent.title) {
      return null;
    }
    const title = this.state.blogContent.title.rendered || '';
    const firstBrace = title.indexOf('{') + 1;
    const lastBrace = title.lastIndexOf('}');
    return title.substring(firstBrace, lastBrace);
  }

  render() {
    const { blogContent, slug } = this.state;
    const { locale } = this.props.application;
    return (
      <div className="page-with-title">
        <PageTitle title="Return to Blog" backTo="/blog" />
        <div className="realm--page-for-copy">
          {this.state.loading ? (
            <LoadingIndicator />
          ) : (
            <Grid container spacing={32}>
              <Grid item sm={8} lg={9}>
                <div className="realm--blog-post-title">
                  {this.showBlogName()}
                </div>
                <div
                  className="realm--blog-post"
                  dangerouslySetInnerHTML={{
                    __html: blogContent.content.rendered
                  }}
                />
              </Grid>
              <Grid item sm={4} lg={3}>
                <BlogList
                  categories={[24]}
                  count={5}
                  currentStory={slug}
                  language={locale}
                  title="Featured"
                  view="list"
                />
                <BlogList
                  categories={[26]}
                  count={5}
                  currentStory={slug}
                  language={locale}
                  title="Real Estate"
                  view="list"
                />
                <BlogList
                  categories={[27]}
                  count={5}
                  currentStory={slug}
                  language={locale}
                  title="Life Style"
                  view="list"
                />
                <BlogList
                  categories={[17]}
                  count={5}
                  currentStory={slug}
                  language={locale}
                  title="News"
                  view="list"
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

BlogPost.propTypes = {
  application: PropTypes.object,
  location: PropTypes.object
};

export default BlogPost;
