import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import isFunction from 'lodash.isfunction';
import { Grid, withStyles } from '@material-ui/core';

import api from '../../api';
import genericHouse from '../../images/img/estateblur2.jpg';
import numberWithCommas from '../../utils/number-with-commas';
import getParameterByName from '../../utils/get-parameter-by-name';
import * as storage from '../../persistence/storage';
import styles from './styles';

import LoadingIndicator from '../../components/LoadingIndicator';
import MatchedAgentCard from '../../components/Matching/MatchedAgentCard';
import MatchCircleGraph from '../../components/Matching/MatchCircleGraph';
import PageTitle from '../../components/PageTitle';
import TagList from '../../components/Tags/TagList';

class MatchDetail extends Component {
  constructor(props) {
    super(props);

    const lid = getParameterByName('id');
    this.state = {
      property: {},
      loading: false,
      listingId: lid,
      title: '',
      price: '',
      photoURL: null,
      total: 0,
      high: 0,
      med: 0,
      low: 0,
      totalAgents: 0,
      alist: [],
      myClients: []
    };
  }

  componentWillMount() {
    this.loadProperty();
  }

  getMatches = async () => {
    const matchStats = await api.get(
      `/match/oneListing/${this.state.listingId}`
    );
    return matchStats;
  };

  loadProperty = async () => {
    this.setState({ loading: true });
    const matchStats = await api.get(
      `/match/oneListing/${this.state.listingId}`
    );
    this.loadAgents(matchStats);
    const response = await api.get(
      `/listings/${this.state.listingId}`
    );
    const property = response.data.data.data;
    this.setState({
      photoURL:
        property.pictures && property.pictures.length > 0
          ? property.pictures[0].pictureUrl
          : genericHouse,
      property
    });
    if (property.title && property.title.en) {
      this.setState({
        title: property.title.en
      });
      if (property.price && property.price.amount) {
        this.setState({ price: `Listed at: ${property.price.amount}` });
      }
    } else {
      this.setState({ title: property.fullAddress });
      if (property.price && property.price.amount) {
        this.setState({ price: `Listed at: ${property.price.amount}` });
      }
    }
    if (property.realmData && property.realmData.matchResults) {
      this.setState({
        total: property.realmData.matchResults.total,
        high: property.realmData.matchResults.high,
        med: property.realmData.matchResults.med,
        low: property.realmData.matchResults.low,
        totalAgents: property.realmData.matchResults.totalAgents
      });
    }
    this.setState({ loading: false }, () => {
      const el = document.getElementById('page-wrapper');
      if (el && isFunction(el.scrollTo)) {
        el.scrollTo(0, 0);
      }
    });
  };

  loadAgents = async (matches) => {
    const { session } = this.props;
    const matchStats = matches;
    const { clientsByAgent } = matchStats.data.data.data;
    const agentList = [];
    const clientCount = [];
    Object.keys(clientsByAgent).map((key) => {
      agentList.push(key);
      clientCount.push(clientsByAgent[key].length);
      return null;
    });

    const response = await api.post('/agents/filter', {
      page: 1,
      limit: 12,
      filter: {
        _id: agentList
      }
    });
    const agents = response.data.data.data;
    const myClients = [];

    // now put the matching data in each agent...
    for (const nextAgent of agents) {
      const clientMatching = {
        high: 0,
        med: 0,
        low: 0,
        clientList: []
      };
      for (const nextClient of clientsByAgent[nextAgent._id]) {
        if (nextClient.score === 'high') {
          clientMatching.high++;
        } else if (nextClient.score === 'med') {
          clientMatching.med++;
        } else {
          clientMatching.low++;
        }
        clientMatching.clientList.push(nextClient.client);
        if (session.agentData && session.agentData._id === nextAgent._id) {
          // capture this user's own clients
          myClients.push({
            name: nextClient.fullName,
            score: nextClient.score,
            id: nextClient.client
          });
        }
      }
      nextAgent.clientMatching = clientMatching;
    }

    this.setState({ alist: agents, myClients });
  };

  handleTagsTuned = () => {
    this.loadProperty();
  };

  render() {
    const { classes } = this.props;
    const agentData = JSON.parse(storage.get('agentData'));
    const fullAddress = this.state.property ? this.state.property.fullAddress : '';

    return (
      <div className="page-with-title">
        <PageTitle title="Return to Matches" backTo="/matching" />
        {this.state.loading ? (
          <div className="realm--loading-container">
            <LoadingIndicator
              message={`Realm Intelligence is updating this property...`}
            />
          </div>
        ) : (
            <Fragment>
              <div className="realm--matching-details" id="container">
                <div
                  className={classNames(
                    'realm--details-background',
                    { photoLoaded: this.state.photoURL },
                    classes.aboveFold
                  )}
                  style={
                    this.state.photoURL
                      ? {
                        background: `linear-gradient(rgba(0,0,0,0), transparent, transparent), url(${
                          this.state.photoURL
                          }) top center`
                      }
                      : {}
                  }
                />
                <div className="realm--shadow" />
                <div className="realm--grid-container header">
                  <div className="realm--matching-title">
                    Matches for: {this.state.title || fullAddress}
                  </div>
                  <div className="realm--listed-at">
                    {this.state.title && fullAddress}{this.state.title && fullAddress && ' | '}{numberWithCommas(this.state.price)}
                  </div>
                </div>

                <div className="realm--grid-container">
                  <Grid container spacing={16}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className="realm--client-agent-container">
                        {this.state.total}{' '}
                        <span className={classes.caLabel}>Clients</span>
                        <div className={classes.matchedFrom}>matched from</div>
                        {this.state.totalAgents}
                        <span className={classes.caLabel}> Agents</span>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="text-centered">
                      <div className="realm--tag-container">
                        <h4>Top Matching Qualities</h4>
                        <div style={{ maxHeight: 290 }}>
                          <TagList
                            format="default"
                            id={this.state.property._id}
                            name={this.state.title}
                            type="listings"
                            title="tune"
                            readonly
                            callback={this.handleTagsTuned}
                            maxHeight={190}
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className="text-centered realm--matching-graphic"
                    >
                      <MatchCircleGraph
                        high={this.state.high}
                        med={this.state.med}
                        low={this.state.low}
                      />
                    </Grid>
                  </Grid>

                  <div className="realm--grid-container">
                    <div className="realm--details-legend">
                      <div className="realm--details-legend-box left">
                        {this.state.high} High % Matches
                    </div>
                      <div className="realm--details-legend-box middle">
                        {this.state.med} Medium % Matches
                    </div>
                      <div className="realm--details-legend-box right">
                        {this.state.low} Near Matches
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* matches by this agent's clients section below... */}
              {this.state.myClients.length > 0 && (
                <div className="realm--agents-container">
                  <h4>Matches for My Clients</h4>
                  <br />
                  <Grid container spacing={16} className="realm--details-my-matches">
                    {this.state.myClients.map((client) => (
                      <div
                        key={client.name}
                        style={{
                          fontSize: 16,
                          minWidth: 200,
                          paddingRight: 18,
                          display: 'inline-block'
                        }}
                      >
                        <Link to={`/client-profile?id=${client.id}`} className="realm--my-matches">
                          {client.name}: {client.score}
                        </Link>
                      </div>
                    ))}
                  </Grid>
                </div>
              )}

              {/* matches by agents section below... */}
              {this.state.total === 0 ? null : (
                <div className="realm--agents-container">
                  <h4>Matches by Agent</h4>
                  <Grid container spacing={24}>
                    {this.state.alist.map((agent) => {
                      if (agentData && agentData._id !== agent._id) {
                        return (
                          <Grid item xs key={this.state.property}>
                            <MatchedAgentCard
                              key={agent._id}
                              agent={agent}
                              property={this.state.property}
                              title={this.state.title}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  </Grid>
                </div>
              )}
            </Fragment>
          )}
      </div>
    );
  }
}

MatchDetail.propTypes = {
  classes: PropTypes.object,
  session: PropTypes.object
};

export default withStyles(styles)(MatchDetail);
