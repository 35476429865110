import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as storage from '../../persistence/storage';
import LoadingIndicator from '../../components/LoadingIndicator';

class Logout extends Component {
  delay = null;

  componentDidMount() {
    this.delay = setTimeout(() => {
      storage.clear();
      if (this.props.session.acceptCookies) {
        storage.put('acceptCookies', this.props.session.acceptCookies);
      }
      this.props.actions.clearApplication();
      this.props.actions.clearSession();
      this.props.history.push('/');
    }, 1500);
  }

  componentWillUnmount() {
    clearTimeout(this.delay);
    this.delay = null;
  }

  render() {
    return (
      <div className="realm--logout-screen">
        <div>
          <h1>Thank you for visiting the Realm</h1>
          <div className="realm--loading-container">
            <LoadingIndicator />
          </div>
          <div className="realm--signing-out">Signing out ...</div>
        </div>
      </div>
    );
  }
}

Logout.propTypes = {
  actions: PropTypes.object,
  session: PropTypes.object
};

export default Logout;
