import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';

import api from '../../api';
import FormValidator from '../../helpers/form-validator';

class RegisterAgent extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      user: {}
    };
    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.'
      }
    ]);
    this.onRegister = this.onRegister.bind(this);
  }

  onRegister = async () => {
    this.setState({ loading: true });

    // Register a new user as an admin
    api
      .post('/admin/register', {
        // /user/agent/register - Old API endpoint was for users registring themselves
        ...this.state.user,
        roles: ['agent'],
        isAdmin: false // TODO: Will eventually go away as roles array get implemented
      })
      .then(() => {
        // nothing here
      })
      .catch((ex) => {
        alert(ex.response.data.msg);
      });
    this.props.onRefresh();
    this.handleClose();
  };

  handleClose = () => {
    this.props.handleClose();
  };

  validationClass = (validation) => validation && !validation.isValid ? 'input-error' : '';

  handleChange = (event) => {
    const { user } = Object.assign({}, this.state);
    user[event.target.id] = event.target.value;
    this.setState(user);
  };

  renderTextFieldWithValidator = (validator) => (label, id, type = 'text') => (
    <TextField
      autoFocus
      className={this.validationClass(validator[id])}
      fullWidth
      id={id}
      label={label}
      margin="dense"
      onChange={this.handleChange}
      type={type}
      value={this.state.user[id]}
    />
  );

  renderDialog() {
    const { isVisible } = this.props;
    const validator = this.validator.validate(this.state);
    const renderTextField = this.renderTextFieldWithValidator(validator);

    return (
      <div>
        <Dialog
          open={isVisible}
          onClose={this.handleClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog"
        >
          <DialogTitle id="delete-dialog-title">New agent user</DialogTitle>
          <DialogContent className="edit-modal">
            {/* {renderTextField('Username', 'username')} // Username is internal to Cognito - Realm uses emails as a login */}
            {/* Password auto generated by Cognito - {renderTextField('Password', 'password')} */}
            {renderTextField('First name', 'firstName')}
            {renderTextField('Last name', 'lastName')}
            {renderTextField('Email', 'email')}
            {renderTextField('Phone', 'phone')}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onRegister} color="default">
              Register user
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    return <span>{this.renderDialog()}</span>;
  }
}

RegisterAgent.propTypes = {
  onRefresh: PropTypes.func,
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func
};

export default RegisterAgent;
