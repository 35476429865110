import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles
} from '@material-ui/core';

const styles = {
  topPanel: {
    textAlign: 'center',
    height: 144,
    margin: 24,
    borderBottom: '1px solid #888'
  },
  topValue: {
    fontSize: 50,
    fontWeight: 400,
    color: '#fff'
  },
  bottomValue: {
    fontSize: 32,
    fontWeight: 400,
    color: '#fff'
  },
  caption: {
    fontSize: 11,
    fontWeight: 300
  },
  leftBottom: {
    borderRight: '1px solid #888'
  }
};

class DataCard extends PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.topPanel}>
          {this.props.title}
          <div className={classes.topValue}>
            {this.props.value1}
          </div>
          <div className={classes.caption}>
            {this.props.caption1}
          </div>
        </div>
        <Grid container spacing={8}>
          <Grid item xs>
            <div className={classes.leftBottom}>
              <div className={classes.bottomValue}>
                {this.props.value2}
              </div>
              <div className={classes.caption}>
                {this.props.caption2}
              </div>
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.bottomValue}>
              {this.props.value3}
            </div>
            <div className={classes.caption}>
              {this.props.caption3}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DataCard.propTypes = {
  caption1: PropTypes.string,
  caption2: PropTypes.string,
  caption3: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  value1: PropTypes.number,
  value2: PropTypes.number,
  value3: PropTypes.number
};

export default withStyles(styles)(DataCard);
