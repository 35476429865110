/* eslint-disable no-nested-ternary */
import './scss/index.scss';
import 'react-table/react-table.css';
import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import IdleTimer from 'react-idle-timer';
import { IntlProvider } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import actions from './actions';
import api from './api';
import enMessages from './locale/en.json';
import { EXTRA_LOGOUT_DURATION, INITIAL_WARNING_DURATION } from './constants';
import localstring from './services/Localization';

import App from './routes/App';
import Login from './routes/Login/Login';
import CookieWarning from './components/Dialogs/CookieWarning';
import UnsupportedBrowser from './routes/UnsupportedBrowser';

export class Application extends Component {
  state = {
    inactive: false,
    timeoutEnabled: true
  };

  _endResizeTimeout = 300;

  _logoutTimerID = null;

  _logoutIntervalID = null;

  _pageResizeTimeout = null;

  componentDidMount() {
    window.addEventListener('resize', this._onResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize, false);
    window.clearTimeout(this._pageResizeTimeout);
  }

  onIdle = () => {
    if (this.state.timeoutEnabled) {
      this.setState({
        inactive: true
      });
      this.clearActivityInterval();
      this.setupLogoutTimer();
    }
  };

  // RESIZING
  _onResize = () => {
    if (this.props.application.isResizing) {
      return null;
    }
    this.props.actions.onResize(true);
    clearTimeout(this._pageResizeTimeout);
    this._pageResizeTimeout = setTimeout(
      this._onResizeEnd,
      this._endResizeTimeout
    );
    return null;
  };

  _onResizeEnd = () => {
    clearTimeout(this._pageResizeTimeout);
    this.props.actions.onResize(false);
  };

  setupActivityInterval = () => {
    this._logoutIntervalID = setInterval(async () => {
      await api.get('/ping');
    }, EXTRA_LOGOUT_DURATION + INITIAL_WARNING_DURATION);
  };

  setupLogoutTimer = () => {
    this._logoutTimerID = setTimeout(() => {
      this.handleLogout();
    }, EXTRA_LOGOUT_DURATION);
  };

  clearActivityInterval = () => {
    if (this._logoutIntervalID !== null) {
      clearInterval(this._logoutIntervalID);
    }
  };

  clearLogoutTimer = () => {
    if (this._logoutTimerID !== null) {
      clearTimeout(this._logoutTimerID);
      this._logoutTimerID = null;
    }
  };

  stayActive = async () => {
    this.setState({
      inactive: false
    });
    this.clearLogoutTimer();
    this.setupActivityInterval();
  };

  handleLogout = () => {
    window.location.replace('/logout');
  };

  render() {
    const { actions, application, session } = this.props;
    const browserVersion = Number.parseInt(application.browserVersion, 10);
    if (!application.isMobile && application.isIE && browserVersion < 11) {
      return <UnsupportedBrowser />;
    }
    if (!session.isAuthenticated) {
      return (
        <Fragment>
          <Login actions={this.props.actions} session={this.props.session} />
          {!session.acceptCookies && (
            <CookieWarning acceptCookies={actions.acceptCookies} />
          )}
        </Fragment>
      );
    }
    return (
      <IntlProvider locale={application.locale} messages={enMessages}>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          onIdle={this.onIdle}
          timeout={INITIAL_WARNING_DURATION}
        >
          <Router>
            <App {...this.props} />
          </Router>
          {session.isAuthenticated && (
            <Dialog
              style={{ alignItems: 'center', justifyContent: 'center' }}
              open={this.state.inactive}
            >
              <DialogTitle id="add-agent-dialog-title">
                {localstring.inactivity_warning}
              </DialogTitle>
              <DialogContent className="realm--white-text">
                {localstring.inactivity_message}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleLogout} color="default">
                  {localstring.menu1_user_logout}
                </Button>
                <Button
                  onClick={this.stayActive}
                  color="primary"
                  variant="contained"
                >
                  {localstring.onboarding.continue}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {!session.acceptCookies && (
            <CookieWarning acceptCookies={actions.acceptCookies} />
          )}
        </IdleTimer>
      </IntlProvider>
    );
  }
}

Application.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  session: PropTypes.object
};

export default connect(
  state => ({
    application: state.application,
    session: state.session
  }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) })
)(Application);
