import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from '../Dashboard';
import Brokerages from '../Brokerages';
import Matching from '../Matching';
import MatchDetail from '../Matching/MatchDetail';
import MatchAlerts from '../Matching/MatchAlerts';
import MatchAlertsDetail from '../Matching/MatchAlertsDetail';
import Agents from '../Agents';
import AgentProfile from '../Agents/AgentProfile';
import Clients from '../Clients';
import ClientProfile from '../Clients/ClientProfile';
import Properties from '../Properties';
import Blog from '../Blog';
import BlogPost from '../Blog/BlogPost';
import Company from '../Company/Company';
import Logout from '../Logout';
import Users from '../Users/Users';
import Admins from '../Users/Admins';
import AdminAction from '../Admin/AdminActions';
import MyAccount from '../Users/Users/MyAccount';
import NotFoundPage from '../NotFoundPage';

const AppRoutes = (storeProps) => (
  <Switch>
    <Route
      exact
      path="/"
      render={(props) => <Dashboard {...props} {...storeProps} />}
    />
    <Route
      exact
      path="/dashboard"
      render={(props) => <Dashboard {...props} {...storeProps} />}
    />
    <Route path="/admin/tools" component={AdminAction} />
    <Route
      path="/manage-admins"
      render={() => <Admins session={storeProps.session} />}
    />
    <Route
      path="/agents"
      render={(props) => <Agents {...props} {...storeProps} />}
    />
    <Route
      path="/agent-profile"
      render={(props) => <AgentProfile {...props} {...storeProps} />}
    />
    <Route
      path="/client-profile"
      render={(props) => <ClientProfile {...props} {...storeProps} />}
    />
    <Route
      path="/blog"
      render={() => <Blog language={storeProps.application.locale} />}
    />
    <Route
      path="/blogpost"
      render={(props) => (
        <BlogPost
          application={storeProps.application}
          location={props.location}
        />
      )}
    />
    <Route
      path="/brokerages/list"
      render={(props) => <Brokerages {...props} {...storeProps} />}
    />
    <Route
      path="/my-clients"
      render={() => (
        <Clients
          application={storeProps.application}
          session={storeProps.session}
        />
      )}
    />
    <Route
      path="/company"
      render={(props) => (
        <Company
          application={storeProps.application}
          location={props.location}
        />
      )}
    />
    <Route
      path="/logout"
      render={(props) => <Logout {...props} actions={storeProps.actions} session={storeProps.session} />}
    />
    <Route
      path="/match-alerts"
      render={(props) => <MatchAlerts {...props} {...storeProps} />}
    />
    <Route
      path="/match-alerts-detail"
      render={(props) => <MatchAlertsDetail {...props} {...storeProps} />}
    />
    <Route
      path="/match-detail"
      render={() => <MatchDetail session={storeProps.session} />}
    />
    <Route
      path="/matching"
      render={(props) => <Matching {...props} {...storeProps} />}
    />
    <Route
      path="/my-account"
      render={(props) => (
        <MyAccount key={props.location.search} {...props} {...storeProps} />
      )}
    />
    <Route
      path="/properties/list"
      render={() => (
        <Properties
          application={storeProps.application}
          session={storeProps.session}
        />
      )}
    />
    <Route
      path="/manage-users"
      render={() => <Users isAdmin={storeProps.session.isAdmin} />}
    />
    <Route path="" component={NotFoundPage} />
  </Switch>
);

export default AppRoutes;
