const styles = () => ({
  iconSelected: {
    color: '#fff'
  },
  iconUnselected: {
    color: '#808080',
    '&:hover': {
      color: '#fff'
    }
  }
});

export default styles;
