/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import isEmpty from 'lodash.isempty';
import isFunction from 'lodash.isfunction';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

import ContactForm from '../components/ContactForm';
import NavButtons from '../components/NavButtons';
import LoadingIndicator from '../../../components/LoadingIndicator';

import api from '../../../api';
import localization from '../../../services/Localization';

class Clients extends Component {
  state = {
    content: null,
    contentLoading: false,
    fileFormat: 'Realm',
    fileToUpload: null,
    itemDropped: false,
    showContentDialog: false,
    showContactForm: false,
    showUploadDialog: false,
    showUploadStateDialog: false,
    uploadSuccessful: ''
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateStep(2);
    const el = document.getElementById('onboarding-container');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delay);
    this.delay = null;
  }

  delay = null;

  uploadFile = () => {
    this.setState({ showUploadDialog: false });
    const data = new FormData();
    data.append('agentId', this.props.session.agentData._id);
    data.append('file', this.state.fileToUpload);
    data.append('fileFormat', this.state.fileFormat);
    api
      .post('/uploads/agents/client-csv', data)
      .then(() => {
        this.setState({
          itemDropped: false,
          showUploadStateDialog: true,
          uploadSuccessful: true
        });
      })
      .catch(() => {
        this.setState({
          itemDropped: false,
          showUploadStateDialog: true,
          uploadSuccessful: false
        });
      });
  };

  handleDrop = (files) => {
    this.setState({
      itemDropped: true,
      fileToUpload: files[0],
      showUploadDialog: true
    });
  };

  updatePerson = async () => {
    await this.props.actions.getAgentData();
  };

  endUpload = () => {
    this.setState({
      showUploadDialog: false,
      showUploadStateDialog: false,
      itemDropped: false
    });
    if (this.state.uploadSuccessful) {
      this.updatePerson();
    }
    // below avoids blinking in modal as it fades out
    this.delay = setTimeout(() => {
      this.setState({
        fileToUpload: null
      });
    }, 350);
  };

  handleSelection = (event) => {
    this.setState({ fileFormat: event.target.value });
  };

  loadContent = async (endpoint) => {
    const { lang } = this.props;
    this.setState({ contentLoading: true });
    try {
      const response = await api.get('/cms/full-post', {
        params: {
          slug: `${endpoint}-${lang}`,
          language: lang
        }
      });
      const data = response.data.data.data[0];
      const {
        content: { rendered: htmlContent },
        title: { rendered: htmlTitle }
      } = data;
      this.setState({
        content: {
          htmlContent,
          htmlTitle
        },
        contentLoading: false
      });
    } catch (err) {
      alert(err);
      this.setState({
        contentLoading: false,
        showContentDialog: false
      });
    }
  };

  showContentDialog = (e) => {
    e.preventDefault();
    this.setState({ showContentDialog: true });
    this.loadContent(e.target.name);
  };

  hideContentDialog = () => {
    this.setState({
      content: null,
      showContentDialog: false
    });
  };

  toggleContactForm = (e) => {
    e.preventDefault();
    this.setState({
      showContactForm: !this.state.showContactForm
    });
  };

  render() {
    const { onboarding: lang } = localization;
    const { uploadSuccessful } = this.state;
    const hasUploaded = !isEmpty(this.props.session.agentData.realmData.clientUpload);
    return (
      <Fragment>
        <h1
          className="realm-onboarding--title centered sm"
          dangerouslySetInnerHTML={{ __html: lang.account_title }}
        />
        <p className="realm-onboarding--welcome centered sm">
          {lang.account_welcome1}{' '}
          <a href="" role="button" onClick={this.toggleContactForm}>
            {lang.account_welcome2}
          </a>{' '}
          {lang.account_welcome3}
        </p>
        <div className="flex-container flex-spaceAround">
          <div className="row">
            <div className="col col-1">
              <h4 dangerouslySetInnerHTML={{ __html: lang.clients1_welcome }} />
              <p dangerouslySetInnerHTML={{ __html: lang.clients1_p1 }} />
              <p dangerouslySetInnerHTML={{ __html: lang.clients1_p2 }} />
              <p
                className="centered"
                style={
                  uploadSuccessful || hasUploaded
                    ? { margin: '3em 0' }
                    : { margin: '3em 0 -2em' }
                }
                dangerouslySetInnerHTML={{
                  __html:
                    uploadSuccessful || hasUploaded
                      ? lang.clients1_p4
                      : lang.clients1_p3
                }}
              />
              {!uploadSuccessful
                && !hasUploaded
                && (
                  <div className="realm-onboarding--dropzone-container">
                    <FormControl className="realm-onboarding--dropzone-selector">
                      <InputLabel htmlFor="age-simple">File Format</InputLabel>
                      <Select
                        className="selectWhite"
                        value={this.state.fileFormat}
                        onChange={this.handleSelection}
                        inputProps={{
                          name: 'selectFormat',
                          id: 'inviteFormat'
                        }}
                      >
                        <MenuItem value="Realm">Realm Format</MenuItem>
                        <MenuItem value="TopProducer">TopProducer CRM</MenuItem>
                        <MenuItem value="SalesForce">SalesForce</MenuItem>
                        <MenuItem value="Outlook">Outlook</MenuItem>
                        <MenuItem value="MailChimp">Mail Chimp</MenuItem>
                        }
                      </Select>
                    </FormControl>
                    <Dropzone
                      className="realm-onboarding--dropzone"
                      onDrop={(files) => this.handleDrop(files)}
                    >
                      {this.state.itemDropped ? (
                        <LoadingIndicator />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lang.clients1_dropzone
                          }}
                        />
                      )}
                    </Dropzone>
                  </div>
                )}
              <div className="realm-onboarding--help centered">
                {!uploadSuccessful
                  && !hasUploaded
                  && (
                    <h4 style={{ marginBottom: 15 }}>
                      <a
                        name="client-upload-help"
                        onClick={this.showContentDialog}
                        role="dialog"
                      >
                        {lang.clients1_help1}
                      </a>{' '}
                      {lang.clients1_help2}
                    </h4>
                  )}
                <p>
                  <a
                    name="marketing-company-confidentiality-agreement"
                    onClick={this.showContentDialog}
                    role="dialog"
                  >
                    {lang.clients1_policy}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <NavButtons
          continueOnly
          history={this.props.history}
          isSaveDisabled={!uploadSuccessful}
          nextPage="/property-tags"
          previousPage="/your-account"
        />
        <Dialog
          className="realm-onboarding--dialog"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="md"
          open={this.state.showContentDialog}
        >
          <DialogTitle>
            {this.state.contentLoading
              ? 'Loading ...'
              : this.state.content && (
                <span
                  style={{ color: '#fff' }}
                  dangerouslySetInnerHTML={{
                    __html: this.state.content.htmlTitle
                  }}
                />
              )}
          </DialogTitle>
          <DialogContent>
            <div className="realm--white-text">
              {this.state.contentLoading ? (
                <LoadingIndicator />
              ) : (
                this.state.content && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.content.htmlContent
                    }}
                  />
                )
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.hideContentDialog}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* upload confirm dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showUploadDialog}
        >
          <DialogTitle>Uploading a client CSV</DialogTitle>
          <DialogContent>
            <div className="realm--white-text">
              You are uploading a file named{' '}
              {this.state.fileToUpload && this.state.fileToUpload.name}?
              <br />
              Do you wish to proceed?
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.endUpload}
              color="default"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={this.uploadFile}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* upload state dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showUploadStateDialog}
        >
          <DialogTitle>
            {typeof this.state.uploadSuccessful !== 'string'
            && this.state.uploadSuccessful
              ? 'Success'
              : 'Upload failed'}
          </DialogTitle>
          <DialogContent>
            <div className="realm--white-text">
              {typeof this.state.uploadSuccessful !== 'string'
                && this.state.uploadSuccessful
                ? 'The file was successfully uploaded.'
                : 'The upload failed. Please contact support if you continue to have problems.'}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.endUpload}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* contact form */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showContactForm}
        >
          <DialogTitle>Contact Us</DialogTitle>
          <DialogContent>
            <div className="realm--white-text">
              <ContactForm />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.toggleContactForm}
              color="default"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

Clients.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object,
  session: PropTypes.object,
  updateStep: PropTypes.func
};

export default Clients;
