import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, withStyles } from '@material-ui/core';
import localstring from '../../services/Localization';
import MatchChart from './MatchChart';
import numberWithCommas from '../../utils/number-with-commas';

import genericHouse from '../../images/img/estateblur2.jpg';

const styles = {
  houseImage: {
    background: `url(${genericHouse}) center center`,
    height: 192,
    backgroundSize: '100% auto !important',
    backgroundRepeat: 'no-repeat !important',
    transition: 'background-size 0.5s ease',
    '&:hover': {
      backgroundSize: '110% auto !important',
      transition: 'background-size 0.5s ease'
    }
  }
};

class HouseOfCard extends Component {
  state = {
    attemptImageReload: false,
    high: null,
    med: null,
    low: null,
    total: 0
  };

  componentWillMount() {
    const { matching, property } = this.props;
    if (matching && property.realmData && property.realmData.matchResults) {
      this.setState({
        high: property.realmData.matchResults.high,
        med: property.realmData.matchResults.med,
        low: property.realmData.matchResults.low,
        total: property.realmData.matchResults.total
      });
    }
  }

  getListingAgent = (property) => {
    if (!property) {
      return null;
    }
    let returnAgents = '';
    if (typeof property.listingAgents !== 'undefined') {
      if (property.listingAgents.length) {
        property.listingAgents.map((agent, i) => {
          if (agent.person) {
            if (agent.person.name) {
              returnAgents +=
                i > 0
                  ? `, ${agent.person.name.firstName} ${
                  agent.person.name.lastName
                  }`
                  : `${agent.person.name.firstName} ${
                  agent.person.name.lastName
                  }`;
            }
          }
          return null;
        });
      }
    }
    return returnAgents;
  };

  imageDidNotLoad = () => {
    if (!this.state.attemptImageReload) {
      this.setState({
        attemptImageReload: true
      });
    }
  };

  renderMatchingCard = () => {
    const { property } = this.props;
    const { attemptImageReload } = this.state;
    const details = property.property;
    const photoURL =
      property.pictures && property.pictures.length > 0
        ? property.pictures[0].pictureUrl
        : '';
    return (
      <div className="realm--house-card">
        <Link
          to={`/match-detail?id=${property._id}`}
          className="realm--house-card-detail-link"
        >
          <div className="realm--house-card-details-link">
            {localstring.details}
          </div>
          <div className="realm--house-card-image">
            {photoURL !== '' && !attemptImageReload ? (
              <img
                src={photoURL || genericHouse}
                alt={details.streetAddress}
                style={{ background: `url(${photoURL || genericHouse}) center center` }}
                onError={this.imageDidNotLoad}
              />
            ) : (
                <img
                  src={genericHouse}
                  alt={details.streetAddress}
                  style={{ background: `url(${genericHouse}) center center` }}
                />
              )}
          </div>
          <div className="realm--house-card-info">
            <div className="realm--house-card-address-block">
              {details.streetAddress}
              <br />
              {details.city}, {details.state} {details.zipcode}
              <br />
              {property.price &&
                property.price.amount &&
                `$${numberWithCommas(property.price.amount)}`}
            </div>
            {this.state.total > 0 && (
              <div className="realm--house-card-pie-block">
                <MatchChart
                  high={this.state.high}
                  med={this.state.med}
                  low={this.state.low}
                  small="true"
                  usePie="true"
                />
              </div>
            )}
          </div>
        </Link>
      </div>
    );
  };

  renderPropertyCard = () => {
    const { property } = this.props;
    const { attemptImageReload } = this.state;
    const details = property.property;
    const price =
      property.price && property.price.amount
        ? `$${numberWithCommas(property.price.amount)}`
        : null;
    const photoURL =
      property.pictures && property.pictures.length > 0
        ? property.pictures[0].pictureUrl
        : '';
    return (
      <div className="realm--house-card property">
        <div className="realm--house-card-image">
          {photoURL !== '' && !attemptImageReload ? (
            <Link
              to={`/match-detail?id=${property._id}`}
              className="realm--house-card-detail-link"
            >
              <img
                src={photoURL || genericHouse}
                alt={details.streetAddress}
                style={{ background: `url(${photoURL || genericHouse}) center center` }}
                onError={this.imageDidNotLoad}
              />
            </Link>
          ) : (
              <img
                src={genericHouse}
                alt={details.streetAddress}
                style={{ background: `url(${genericHouse}) center center` }}
              />
            )}
        </div>
        <div className="realm--house-card-info">
          <div className="realm--house-card-address-block">
            {details.streetAddress}
            <br />
            {details.city}, {details.state} {details.zipcode}
            <br />
            {property.status && (
              <span>
                <strong>Status</strong>: {property.status}
              </span>
            )}
            <br />
            <br />
            <Link
              to={`/match-detail?id=${property._id}`}
              className="realm--house-card-detail-link"
            >
              See Full Listing
            </Link>
          </div>
          <div className="realm--house-card-broker-info">
            <strong>Price: {price}</strong>
            <br />
            {property.listingBrokerage && property.listingBrokerage.name}
            <br />
            {this.getListingAgent(property) && (
              <span>Agent: {this.getListingAgent(property)}</span>
            )}
            <br />
            <br />
            {property.alwaysEmailAgent && property.listingEmail && (
              <a href={`mailto: ${property.listingEmail}`}>Email Agent</a>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Grid key={this.props.property._id} item xs={12} sm={6} md={4} lg={3}>
        {this.props.matching
          ? this.renderMatchingCard()
          : this.renderPropertyCard()}
      </Grid>
    );
  }
}

HouseOfCard.propTypes = {
  matching: PropTypes.bool,
  property: PropTypes.object
};

export default withStyles(styles)(HouseOfCard);
