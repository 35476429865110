import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isFunction from 'lodash.isfunction';
import Dropzone from 'react-dropzone';
import NumberFormat from 'react-number-format';

import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import api from '../../api';
import { API_IMAGE, API_ROOT } from '../../constants';
import toTitleCase from '../../utils/to-title-case';
import localstring from '../../services/Localization';

import ContentDialog from './ContentDialog';
import ErrorDialog from './ErrorDialog';
import SuccessDialog from './SuccessDialog';
import LoadingIndicator from '../LoadingIndicator';
import Tag from '../Tags/Tag';
import TagDialog from '../Tags/TagDialog';
import USStates from '../../utils/us-states';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

class AddListingDialog extends Component {
  state = {
    city: '',
    dateAvailable: moment().format('YYYY-MM-DD'),
    fileToUpload: null,
    imageURL: '',
    listingAddress: '',
    price: '',
    st: '',
    status: 'None',
    tags: [],
    tagNames: [],
    termsAccepted: false,
    title: '',
    unit: '',
    zip: '',

    errorMessage: '',
    imageError: false,
    isCreatingListing: false,
    isDirty: false,
    isError: false,
    isPocketListing: true,
    isValidForm: false,
    itemDropped: false,
    showContentDialog: false,
    showSaveSuccessful: false,
    showUnsavedWarning: false,
    showZipMessage: true
  };

  delay = null;

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.delay) {
      clearTimeout(this.delay);
      this.delay = null;
    }
  }

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
        isDirty: true
      },
      () => {
        this.checkForValidForm();
        this.checkForValidZip();
      }
    );
  };

  handleTags = (tagNames = [], tags = []) => {
    this.setState(
      {
        tags,
        tagNames
      },
      () => {
        this.checkForValidForm();
      }
    );
  };

  onChecked = (e) => {
    this.setState(
      {
        termsAccepted: e.target.checked,
        isDirty: true
      },
      () => {
        this.checkForValidForm();
      }
    );
  };

  handleDrop = (files) => {
    this.setState({
      fileToUpload: files[0]
    }, () => {
      this.setState({
        itemDropped: true
      });
    });
  };

  checkForValidForm = () => {
    const isValid = this.isValidForm();
    if (isValid !== this.state.isValidForm) {
      this.setState({
        isValidForm: isValid
      });
    }
  };

  checkForValidZip = () => {
    if (this.isValidZip(this.state.zip)) {
      this.state.showZipMessage && this.setState({ showZipMessage: false });
    } else {
      !this.state.showZipMessage && this.setState({ showZipMessage: true });
    }
  }

  isValidZip = (zip) => /^\d{5}(?:[-\s]\d{4})?$/.test(zip);

  isValidForm = () => {
    const hasValidRequiredPocket =
      this.state.isDirty &&
      this.state.termsAccepted &&
      this.state.city &&
      this.state.listingAddress &&
      this.state.price &&
      this.state.st &&
      this.state.tags.length > 0 &&
      this.isValidZip(this.state.zip);

    const hasValidRequiredProspect =
      this.state.isDirty &&
      this.state.city &&
      this.state.listingAddress &&
      this.state.st &&
      this.state.tags.length > 0 &&
      this.state.zip;

    const hasValidRequired = this.state.isPocketListing
      ? hasValidRequiredPocket
      : hasValidRequiredProspect;
    return hasValidRequired;
  };

  resetState = () => {
    if (this._isMounted) {
      this.setState({
        city: '',
        dateAvailable: moment().format('YYYY-MM-DD'),
        fileToUpload: null,
        imageURL: '',
        listingAddress: '',
        price: '',
        st: '',
        status: 'None',
        tags: [],
        tagNames: [],
        termsAccepted: false,
        title: '',
        unit: '',
        zip: '',
        errorMessage: '',
        imageError: false,
        isDirty: false,
        isError: false,
        isPocketListing: true,
        isValidForm: false,
        itemDropped: false,
        showUnsavedWarning: false,
        showZipMessage: true
      });
    }
  };

  getListingData = () => {
    const baseData = {
      listingAgents: [this.props.agentId],
      listingBrokerage: this.props.brokerage._id,
      listingType: this.state.isPocketListing ? 'Pocket' : 'Prospect',
      dateListed: new Date().toISOString(),
      property: {
        streetAddress: this.state.listingAddress,
        unitNumber: this.state.unit,
        city: this.state.city,
        state: this.state.st,
        zipcode: this.state.zip
      },
      realmData: {
        tags: this.state.tags,
        tagNames: this.state.tagNames
      },
      pictures: [
        {
          pictureUrl: this.state.imageURL
        }
      ],
      title: this.state.title
    };
    const pocketData = {
      dateAvailable: new Date(this.state.dateAvailable).toISOString(),
      price: {
        amount: this.state.price,
        currency: 'USD'
      },
      status: this.state.status
    };
    const listingData = this.state.isPocketListing
      ? Object.assign({}, baseData, pocketData)
      : baseData;
    return listingData;
  }

  createListing = async () => {
    this.setState({
      isCreatingListing: true
    });
    if (this.state.fileToUpload) {
      const data = new FormData();
      data.append('file', this.state.fileToUpload);
      try {
        const response = await api.post(
          `${API_IMAGE}/uploads/image/listing-pic`,
          data
        );
        await this.setState({
          imageURL: response.data.data
        }, () => {
          this.writeListingToAPI();
        });
      } catch (err) {
        this.setState({
          errorMessage: 'Your image cannot be saved. Would you like cancel and try another image, or is it OK to save this listing without an image?',
          isError: true,
          imageError: true
        });
      }
    } else {
      await this.writeListingToAPI();
    }
  };

  tryNewImage = () => {
    this.setState({
      errorMessage: '',
      isCreatingListing: false,
      isError: false,
      imageError: false,
      itemDropped: false,
      fileToUpload: null,
      imageURL: ''
    });
  }

  tryWithoutImage = () => {
    this.setState({
      isCreatingListing: false,
      isError: false,
      imageError: false,
      itemDropped: false,
      fileToUpload: null,
      imageURL: ''
    }, () => {
      this.writeListingToAPI();
    });
  }

  writeListingToAPI = async () => {
    const listingData = this.getListingData();
    try {
      await api.post(`${API_ROOT}/listings/`, listingData);
      this.showSuccessDialog();
      if (this._isMounted) {
        this.delay = setTimeout(() => {
          this.resetState();
        }, 2000);
      }
    } catch (err) {
      this.setState({
        isCreatingListing: false,
        errorMessage:
          `There was an error: ${err.response.data.msg}`,
        isError: true
      });
    }
  }

  // DIALOGS
  showSuccessDialog = () => {
    if (this._isMounted) {
      this.setState(
        { isCreatingListing: false, showSaveSuccessful: true },
        () => {
          setTimeout(() => {
            if (this._isMounted) {
              this.setState({ showSaveSuccessful: false });
            }
            if (isFunction(this.props.handleClose)) {
              this.props.handleClose();
            }
          }, 1500);
        }
      );
    }
  };

  showContentDialog = (e) => {
    e.preventDefault();
    this.setState({ showContentDialog: true });
  };

  hideContentDialog = () => {
    this.setState({ showContentDialog: false });
  };

  closeErrorDialog = () => {
    this.setState({
      errorMessage: '',
      isError: false
    });
  };

  cancelAddListing = () => {
    this.resetState();
    if (isFunction(this.props.handleClose)) {
      this.props.handleClose();
    }
  };

  showUnsavedWarning = () => {
    this.setState({
      showUnsavedWarning: true
    });
  };

  closeUnsavedWarning = () => {
    this.setState({
      showUnsavedWarning: false
    });
  };

  // RENDER
  getStates = () => {
    const states = Object.keys(USStates);
    return states.map((key) => (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    ));
  };

  renderForm = () => (
    <Fragment>
      <div style={{ marginTop: 20, fontSize: 18, lineHeight: 1.5 }}>
        {this.state.isPocketListing ? (
          <span>
            Enter your pocket listing details and allow the REALM to find the
            best possible client matches for your property.
          </span>
        ) : (
          <span>
            Enter your prospect listing details and allow the REALM to showcase
            possible client matches for your prospective property.
          </span>
        )}
      </div>
      <Grid container spacing={32}>
        <Grid item xs={12} lg={6}>
          <h3>Property Details</h3>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <TextField
              fullWidth
              id="title"
              label="Title"
              type="text"
              name="title"
              onChange={this.handleChange('title')}
              value={this.state.title}
            />
          </div>
          <Grid container spacing={32} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                helperText="e.g. 123 Main St."
                id="listingAddress"
                label="Street Address"
                required
                type="text"
                name="listingAddress"
                onChange={this.handleChange('listingAddress')}
                value={this.state.listingAddress}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="unit"
                label="Unit #"
                type="text"
                name="unit"
                onChange={this.handleChange('unit')}
                value={this.state.unit}
              />
            </Grid>
          </Grid>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                id="city"
                label="City"
                required
                type="text"
                name="city"
                onChange={this.handleChange('city')}
                value={this.state.city}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl style={{ display: 'block', width: '100%' }}>
                <InputLabel htmlFor="st">State</InputLabel>
                <Select
                  fullWidth
                  required
                  value={this.state.st}
                  onChange={this.handleChange('st')}
                  inputProps={{
                    name: 'st',
                    id: 'st'
                  }}
                >
                  {this.getStates()}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                helperText={this.state.showZipMessage ? 'Zip must be five digits' : 'Zip is valid'}
                id="zip"
                label="Zip Code"
                required
                type="text"
                name="zip"
                onChange={this.handleChange('zip')}
                value={this.state.zip}
              />
            </Grid>
          </Grid>
          {this.state.isPocketListing && (
            <Grid
              container
              spacing={32}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="price"
                  label="Price"
                  required
                  type="text"
                  name="price"
                  onChange={this.handleChange('price')}
                  value={this.state.price}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl style={{ display: 'block', width: '100%' }}>
                  <InputLabel htmlFor="status">Status</InputLabel>
                  <Select
                    fullWidth
                    value={this.state.status}
                    onChange={this.handleChange('status')}
                    inputProps={{
                      name: 'status',
                      id: 'status'
                    }}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    {/* <MenuItem value="inactive">InActive</MenuItem> */}
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="ComingSoon">Coming Soon</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="dateAvailable"
                  label="Date Available"
                  type="date"
                  name="dateAvailable"
                  required
                  onChange={this.handleChange('dateAvailable')}
                  value={this.state.dateAvailable}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <h3>Upload Photo</h3>
            <p>
              Drag and drop a photo, or type a valid image URL into the field
              below the drag-and-drop target.
            </p>
            <Dropzone
              className="realm-onboarding--dropzone"
              onDrop={(files) => this.handleDrop(files)}
            >
              {this.state.itemDropped ? (
                <div>
                  <img
                    style={{ width: '75%', margin: '0 auto 10px', display: 'block' }}
                    src={this.state.fileToUpload.preview} />
                  <p><small>{this.state.fileToUpload.name}</small></p>
                </div>
              ) : (
                <div>
                  <p>{localstring.drop_photo}</p>
                  <small>{localstring.photo_limit}</small>
                </div>
              )}
            </Dropzone>
            <div style={{ marginTop: 10 }}>
              <TextField
                fullWidth
                id="imageURL"
                label="Upload photo by URL"
                type="text"
                name="imageURL"
                onChange={this.handleChange('imageURL')}
                value={this.state.imageURL}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <h3>Qualities</h3>
            <p>Add at least one quality to the listing (required)</p>
            <TagDialog
              existingFilterTags={this.state.tags}
              filterable
              onFilter={this.handleTags}
              title="Add Qualities"
              type="listings"
            />
            <div style={{ marginTop: 10, width: '100%' }}>
              {!!this.state.tagNames.length &&
                this.state.tagNames.map((tag) => (
                  <Tag
                    key={tag}
                    label={toTitleCase(tag.replace(/_/g, ' '))}
                    readonly
                  />
                ))}
            </div>
          </div>
        </Grid>
        {this.state.isPocketListing ? (
          <Grid item xs={12}>
            <div>
              <FormControl
                component="fieldset"
                style={{ display: 'block', width: '100%' }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.termsAccepted}
                        color="primary"
                        id="termsAccepted"
                        name="termsAccepted"
                        required
                        onChange={this.onChecked}
                      />
                    }
                    label={
                      <span>
                        By submitting this listing, you agree that you have
                        exclusive rights to list this property and to the{' '}
                        <a
                          style={{
                            color: '#d3d3d3',
                            textDecoration: 'underline'
                          }}
                          onClick={this.showContentDialog}
                          role="dialog"
                        >
                          REALM Terms & Conditions
                        </a>
                        .
                      </span>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {!this.props.isMobile && <div style={{ height: 54 }} />}
          </Grid>
        )}
      </Grid>
    </Fragment>
  );

  toggleListingType = (e) => {
    this.setState(
      {
        isPocketListing: e.currentTarget.name === 'pocket'
      },
      () => {
        this.checkForValidForm();
      }
    );
  };

  render() {
    return (
      <Fragment>
        <Dialog
          aria-describedby="add-listing-dialog"
          aria-labelledby="add-listing-dialog-title"
          open={this.props.open || false}
          disableBackdropClick
          disableEscapeKeyDown
          PaperProps={{ style: { maxWidth: '85%' } }}
        >
          {this.state.isCreatingListing && (
            <div className="realm--loading-container fixed overlay">
              <LoadingIndicator />
            </div>
          )}
          <DialogTitle id="add-listing-dialog-title">
            Create New {this.state.isPocketListing ? 'Pocket' : 'Prospect'}{' '}
            Listing
          </DialogTitle>
          <DialogContent
            className="realm--white-text"
            style={{ color: '#fff' }}
          >
            <div className="realm--button-group">
              <Button
                onClick={this.toggleListingType}
                name="pocket"
                variant={this.state.isPocketListing ? 'contained' : undefined}
              >
                Pocket Listing
              </Button>
              <Button
                onClick={this.toggleListingType}
                name="prospect"
                variant={!this.state.isPocketListing ? 'contained' : undefined}
              >
                Prospect Listing
              </Button>
            </div>
            {this.state.isLoading ? (
              <div className="realm--loading-container">
                <LoadingIndicator />
              </div>
            ) : (
              this.renderForm()
            )}
            <DialogActions>
              <Button
                onClick={
                  this.state.isDirty
                    ? this.showUnsavedWarning
                    : this.cancelAddListing
                }
                color="default"
                variant="contained"
              >
                {localstring.cancel}
              </Button>
              <Button
                onClick={this.createListing}
                color="primary"
                variant="contained"
                disabled={!this.state.isValidForm}
              >
                {localstring.onboarding.save}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <ContentDialog
          language={this.props.language}
          onClose={this.hideContentDialog}
          open={this.state.showContentDialog}
          slug="terms-and-conditions"
        />
        <SuccessDialog open={this.state.showSaveSuccessful} />
        <ErrorDialog
          handleRetry={this.state.imageError ? this.tryWithoutImage : undefined}
          handleClose={this.state.imageError ? this.tryNewImage : this.closeErrorDialog}
          message={this.state.errorMessage}
          okBtnOverride={this.state.imageError ? 'Save Without Image' : 'Close'}
          open={this.state.isError}
        />
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showUnsavedWarning}
        >
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogContent>
            <div className="realm--white-text">
              You have unsaved changes that will be lost if you skip this step.
              <br />
              Do you wish to discard your changes?
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeUnsavedWarning}
              color="default"
              variant="contained"
            >
              No
            </Button>
            <Button
              onClick={this.cancelAddListing}
              color="primary"
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

AddListingDialog.propTypes = {
  agentId: PropTypes.string,
  brokerage: PropTypes.object,
  handleClose: PropTypes.func,
  isMobile: PropTypes.bool,
  language: PropTypes.string,
  open: PropTypes.bool
};

export default AddListingDialog;
