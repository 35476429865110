import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormControl extends Component {
  state = {
    value: this.props.value
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value
    });
    this.props.onChange(e);
  }

  render() {
    const {
      value,
      ...rest
    } = this.props;

    return (
      <input
        {...rest}
        className="form-control"
        onChange={this.handleChange}
        value={this.state.value}
      />
    );
  }
}

FormControl.propTypes = {
  value: PropTypes.string
};

export default FormControl;
