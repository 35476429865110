/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import isURL from 'is-url';
import isEmpty from 'lodash.isempty';
import merge from 'lodash.merge';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import ContactForm from '../components/ContactForm';
import EditField from '../components/EditField';
import NavButtons from '../components/NavButtons';
import TagList from '../../../components/Tags/TagList';
import UploadPhoto from '../../../components/Upload/UploadPhoto';

import genericAvatar from '../assets/avatar.png';
import localization from '../../../services/Localization';

class YourAccount extends Component {
  constructor(props) {
    super(props);
    const {
      agentData: { realmData },
      currentPerson: { realmData: personData }
    } = this.props.session;
    this.state = {
      description: realmData.description || '',
      isDirty: false,
      showContactForm: false,
      showDialog: false,
      socialAccounts: personData.socialAccounts || [],
      tags: realmData.tags || []
    };
  }

  componentDidMount() {
    this.props.updateStep(1);
    const el = document.getElementById('onboarding-container');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }

  getSocialValues = () => {
    const {
      currentPerson: { realmData: personData }
    } = this.props.session;
    const { socialAccounts } = personData;
    const obj = {};
    socialAccounts.map((a) => {
      obj[a.socialType] = a.url;
      return null;
    });
    return obj;
  };

  handleChange = (e) => {
    this.checkForDirtyForm();
    const { name, value } = e.target;
    if (name === 'description') {
      this.setState({ description: value });
    }
  };

  handleSocialAccountState = (e) => {
    this.checkForDirtyForm();
    const { name, value } = e.target;
    const { socialAccounts } = this.state;
    const newObj = { socialType: name, url: value };
    const newState = [];
    const existingKeys = socialAccounts.map((obj) => obj.socialType);
    if (!existingKeys.includes(name)) {
      newState.push(newObj);
    }
    socialAccounts.length > 0 &&
      socialAccounts.map((obj) => {
        if (obj.socialType === newObj.socialType) {
          newState.push(newObj);
        } else {
          newState.push(obj);
        }
        return null;
      });
    this.setState({ socialAccounts: newState });
    return null;
  };

  checkForDirtyForm = () => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
  };

  toggleDialog = () => {
    this.setState({
      showDialog: !this.state.showDialog
    });
  };

  proceedWithSkip = () => {
    this.props.history.push('/clients');
  };

  handleCloseUpload = (uploadSuccessful) => {
    if (uploadSuccessful) {
      this.props.actions.getPersonData();
      this.props.actions.getAgentData();
    }
  };

  updatePerson = async () => {
    const person = Object.assign({}, this.props.session.currentPerson);
    const agentData = Object.assign({}, this.props.session.agentData);
    const updatedPerson = merge(person, {
      realmData: {
        tags: this.state.tags,
        socialAccounts: this.state.socialAccounts
      }
    });
    const updatedRealmData = merge(agentData.realmData, {
      description: this.state.description,
      tags: this.state.tags
    });
    await this.props.actions.updateAgent({
      realmData: updatedRealmData
    });
    this.props.actions.updatePerson({
      realmData: updatedPerson.realmData
    });
    this.props.history.push('/clients');
  };

  getCurrentAgentData = () => {
    this.props.actions.getAgentData();
    this.checkForDirtyForm();
  };

  toggleContactForm = (e) => {
    e.preventDefault();
    this.setState({
      showContactForm: !this.state.showContactForm
    });
  };

  render() {
    const { session } = this.props;
    const { avatar } = session;
    const { onboarding: lang } = localization;
    const { _id: agentId, currentBrokerage, realmData } = session.agentData;
    const { emails, name, phones } = session.currentPerson;
    const { description } = realmData;
    const social = this.getSocialValues();
    return (
      <Fragment>
        <h1
          className="realm-onboarding--title centered sm"
          dangerouslySetInnerHTML={{ __html: lang.account_title }}
        />
        <p className="realm-onboarding--welcome centered sm">
          {lang.account_welcome1}{' '}
          <a href="/" onClick={this.toggleContactForm}>
            {lang.account_welcome2}
          </a>{' '}
          {lang.account_welcome3}
        </p>
        <div className="flex-container flex-spaceAround">
          <div className="row">
            <div className="col col-1 centered">
              <h4 className="centered">{lang.account_label}</h4>
              <div
                style={{
                  overflow: 'hidden',
                  width: 150,
                  height: 150,
                  borderRadius: '50%',
                  margin: '0 auto 1em'
                }}
              >
                <img
                  width="150"
                  alt="agent"
                  src={
                    isURL(avatar)
                      ? avatar
                      : genericAvatar
                  }
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <UploadPhoto
                  block
                  forName={`${name.firstName} ${name.lastName}`}
                  handleClose={this.handleCloseUpload}
                  hasPhoto={!!avatar || undefined}
                />
              </div>
              <h4 className="lg">{`${name.firstName} ${name.lastName}`}</h4>
              {!isEmpty(currentBrokerage) && (
                <p>
                  <strong>{lang.brokerage}</strong>: {currentBrokerage.name}
                </p>
              )}
              {!!emails.length && (
                <p>
                  <strong>{lang.email}</strong>: {emails[0].address}
                </p>
              )}
              {!!phones.length && (
                <p>
                  <strong>{lang.phone}</strong>: {phones[0].number}
                </p>
              )}
            </div>
            <div className="col col-4">
              <div className="realm-onboarding--group">
                <h4>{lang.account_form_label1}</h4>
                <p className="realm-onboarding--help">
                  {lang.account_form_help1}
                </p>
                <textarea
                  onChange={this.handleChange}
                  col="10"
                  rows="10"
                  defaultValue={description}
                  name="description"
                  placeholder="Tell us about yourself"
                  style={{
                    padding: '.5em',
                    background: '#fff',
                    width: '50%',
                    border: '1px solid #c5c3bb',
                    color: '#333'
                  }}
                />
              </div>
              <div className="realm-onboarding--group">
                <h4>{lang.account_form_label2}</h4>
                <p className="realm-onboarding--help">
                  {lang.account_form_help2}
                </p>
                <TagList
                  callbackOnUpdate={this.getCurrentAgentData}
                  format="default"
                  id={agentId}
                  name={`${name.firstName} ${name.lastName}`}
                  title="Add Specialities"
                  type="agents"
                />
              </div>
              <div className="realm-onboarding--group">
                <h4>{lang.account_form_label3}</h4>
                <p className="realm-onboarding--help">
                  {lang.account_form_help3}
                </p>
                <EditField
                  change={this.handleSocialAccountState}
                  className="left-aligned"
                  name="facebook"
                  label="Facebook"
                  value={social.facebook || ''}
                  placeholder="Enter the URL for your Facebook account"
                />
                <EditField
                  change={this.handleSocialAccountState}
                  className="left-aligned"
                  name="instagram"
                  label="Instagram"
                  value={social.instagram || ''}
                  placeholder="Enter the URL for your Instagram account"
                />
                <EditField
                  change={this.handleSocialAccountState}
                  className="left-aligned"
                  name="linkedin"
                  label="LinkedIn"
                  value={social.linkedin || ''}
                  placeholder="Enter the URL for your LinkedIn account"
                />
                <EditField
                  change={this.handleSocialAccountState}
                  className="left-aligned"
                  name="snapchat"
                  label="Snapchat"
                  value={social.snapchat || ''}
                  placeholder="Enter the URL for your Snapchat account"
                />
                <EditField
                  change={this.handleSocialAccountState}
                  className="left-aligned"
                  name="twitter"
                  label="Twitter"
                  value={social.twitter || ''}
                  placeholder="Enter the URL for your Twitter account"
                />
                <EditField
                  change={this.handleSocialAccountState}
                  className="left-aligned"
                  name="whatsapp"
                  label="WhatsApp"
                  value={social.whatsapp || ''}
                  placeholder="Enter the URL for your WhatsApp account"
                />
              </div>
            </div>
          </div>
        </div>
        <NavButtons
          backDisabled
          history={this.props.history}
          isSaveDisabled={!this.state.isDirty}
          nextPage="/clients"
          onSave={this.updatePerson}
          onSkip={this.state.isDirty ? this.toggleDialog : undefined}
          previousPage="Agreements"
        />
        {/* in case they skip without saving changes */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showDialog}
        >
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogContent>
            <div className="realm--white-text">
              You have unsaved changes that will be lost if you skip this step.
              <br />
              Do you wish to discard your changes?
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.toggleDialog}
              color="default"
              variant="contained"
            >
              No
            </Button>
            <Button
              onClick={this.proceedWithSkip}
              color="primary"
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* contact form */}
        <ContactForm
          handleClose={this.toggleContactForm}
          open={this.state.showContactForm}
        />
      </Fragment>
    );
  }
}

YourAccount.propTypes = {
  actions: PropTypes.object,
  session: PropTypes.object,
  history: PropTypes.object,
  updateStep: PropTypes.func
};

export default YourAccount;
