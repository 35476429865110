import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import MatchChart from '../../components/Matching/MatchChart';

const TableView = (props) => (
  <ReactTable
    data={props.data}
    filterable
    defaultSorted={[
      {
        id: 'propertyStatus',
        desc: false
      }
    ]}
    getTrProps={(state, rowInfo) => ({
      onClick: () => {
        this.showMatchingDetail(rowInfo.original);
      }
    })}
    columns={[
      {
        columns: [
          {
            id: 'address',
            Header: 'Address',
            maxWidth: 250,
            filterMethod: (filter, row) => {
              if (row._original.property.streetAddress) {
                let name = `${row._original.property.streetAddress} ${
                  row._original.property.city
                }`;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.includes(match);
              }
              return false;
            },
            accessor: '',
            Cell: (cellInfo) => (
              <div>
                {cellInfo.original.property &&
                  cellInfo.original.property.streetAddress &&
                  cellInfo.original.property.streetAddress}

                {cellInfo.original.property && (
                  <div>
                    {cellInfo.original.property.city}{' '}
                    {cellInfo.original.property.state},{' '}
                    {cellInfo.original.property.zipcode}
                  </div>
                )}
              </div>
            )
          },
          {
            id: 'propertyStatus',
            Header: 'Status',
            accessor: 'status',
            maxWidth: 150,
            filterMethod: (filter, row) => {
              if (row._original.status) {
                let name = row._original.status;
                name = name.toUpperCase();
                const match = filter.value.toUpperCase();
                return name.startsWith(match);
              }
              return false;
            }
          },
          {
            id: 'chart',
            Header: 'Matches',
            filterable: false,
            maxWidth: 150,
            accessor: (n) => n.listingBrokerage,
            Cell: () => (
              <div style={{ width: 50 }}>
                <MatchChart high={80} med={20} low={32} usePie="true" />
              </div>
            )
          },
          {
            id: 'total',
            Header: 'Total',
            maxWidth: 150,
            accessor: (n) => n.listingBrokerage,
            Cell: () => <div className="matchTotal">132</div>
          }
        ]
      }
    ]}
    defaultPageSize={25}
    className="-striped -highlight"
  />
);

TableView.propTypes = {
  data: PropTypes.array
};

export default TableView;
