const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  drawerP: {
    marginTop: 64
  },
  drawerPaper: {
    position: 'static',
    marginTop: 64,
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
    },
    backgroundColor: '#000',
    color: '#fff',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperisRTL: {
    left: 'auto',
    right: 0
  },
  drawerPaperisNotRTL: {
    left: 0,
    right: 'auto'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    }
  },
  subMenu: {
    backgroundColor: '#282837'
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  submenutext: {
    padding: '0px 8px!important'
  }
});

export default styles;
