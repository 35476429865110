import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Button, withStyles } from '@material-ui/core';

import ChangePassword from '../../components/Dialogs/ChangePassword';
import ErrorDialog from '../../components/Dialogs/ErrorDialog';
import FormControl from '../../components/Users/FormControl';
import LoadingIndicator from '../../components/LoadingIndicator';
import ResetPassword from '../../components/Dialogs/ResetPasswordRequest';

import api from '../../api';
import localstring from '../../services/Localization';
import styles from './styles';

class Login extends Component {
  state = {
    attemptRetry: false,
    errorMessage: '',
    isError: false,
    isLoading: false,
    isValid: false,
    password: '',
    showStepRequestNewPassword: false,
    showStepChangePassword: false,
    showPasswordRequestSuccess: false,
    username: ''
  };

  onRetryPassword = () => {
    this.setState({
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      showStepChangePassword: true
    });
  };

  validateForm = () => this.validateEmail() && this.state.password.length > 0;

  // for now -- need better validation
  validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.username);
  };

  handleChange = (event) => {
    this.setState(
      {
        [event.target.id]: event.target.value
      },
      () => {
        this.setState({ isValid: this.validateForm() });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const authenticationData = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.actions.authenticate(authenticationData);
  };

  handleClose = () => {
    this.props.actions.retryAuthentication();
    this.setState({
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      isLoading: false,
      showStepRequestNewPassword: false,
      showStepChangePassword: false,
      showPasswordRequestSuccess: false,
    });
  };

  /* reset password stuff */
  requestResetPassword = () => {
    this.setState({
      showStepRequestNewPassword: true
    });
  };

  cancelResetPassword = () => {
    this.setState({
      showStepRequestNewPassword: false
    });
  };

  resetPassword = async (emailAddress) => {
    this.setState({
      showStepRequestNewPassword: true
    });
    try {
      const response = await api.post(
        '/user/reset-password',
        { username: emailAddress }
      );
      if (response.status === 200) {
        this.setState({
          emailAddress,
          showStepRequestNewPassword: false,
          showStepChangePassword: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepRequestNewPassword: false
      });
    }
  };

  changePassword = async (authObj) => {
    if (!authObj.password || !authObj.verificationCode) {
      return null;
    }
    try {
      const response = await api.post(
        '/user/confirm-password',
        {
          code: authObj.verificationCode,
          password: authObj.password,
          username: this.state.emailAddress
        }
      );
      if (response.status === 200) {
        this.setState({
          showPasswordRequestSuccess: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepChangePassword: false,
        attemptRetry: true
      });
    }
    return null;
  };

  render() {
    const { classes, session } = this.props;
    return (
      <Fragment>
        <AppBar className={classes.appBar}>{localstring.login_please}</AppBar>
        <div className={classes.page}>
          <div className={classes.loginPanel}>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group form-group-lg">
                <label className="control-label" htmlFor="username">
                  {localstring.onboarding.email}
                </label>
                <FormControl
                  autoFocus
                  id="username"
                  type="username"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group form-group-lg">
                <label className="control-label" htmlFor="password">
                  {localstring.onboarding.password}
                </label>
                <FormControl
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                />
              </div>
              {this.state.isLoading ? (
                <div className={classes.loadingContainer}>
                  <LoadingIndicator message={localstring.signing_in} />
                </div>
              ) : (
                <div style={{ textAlign: 'center', marginBottom: 20 }}>
                  <Button
                    className={classes.buttonBlock}
                    color="primary"
                    size="large"
                    style={{
                      marginBottom: 20,
                      opacity: this.state.isValid ? '1' : '.5',
                      pointerEvents: this.state.isValid ? 'auto' : 'none'
                    }}
                    type="submit"
                    variant="outlined"
                  >
                    {localstring.login}
                  </Button>

                  <ResetPassword
                    forgot
                    handleOpen={this.requestResetPassword}
                    handleClose={this.cancelResetPassword}
                    handleReset={this.resetPassword}
                    open={this.state.showStepRequestNewPassword}
                    showButton
                    small
                  />
                </div>
              )}
            </form>
          </div>
        </div>
        <ChangePassword
          handleReset={this.changePassword}
          isSuccess={this.state.showPasswordRequestSuccess}
          onSuccess={this.handleClose}
          open={this.state.showStepChangePassword}
        />
        <ErrorDialog
          handleClose={this.handleClose}
          message={session.errorMessage}
          open={session.authenticationFailed}
        />
        <ErrorDialog
          handleClose={this.handleClose}
          handleRetry={
            this.state.attemptRetry ? this.onRetryPassword : undefined
          }
          message={this.state.errorMessage}
          open={this.state.isError}
        />
      </Fragment>
    );
  }
}

Login.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);
