// demo api values
export const API_ROOT='https://25vez9yzq7.execute-api.us-east-1.amazonaws.com/demo/api/v1'
export const API_IMAGE='http://ec2-3-84-217-214.compute-1.amazonaws.com:8020'
export const API_URL = '/api/v2';
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

// Timeouts
export const INITIAL_WARNING_DURATION = 1000 * 60 * 60; // 60 minutes
export const EXTRA_LOGOUT_DURATION = 1000 * 60 * 70; // on 70 minutes, force logout

// Application constants
export const DEFAULT_LOCALE = 'en';
export const RESIZE_START = 'RESIZE_START';
export const RESIZE_END = 'RESIZE_END';
export const SCROLLING_START = 'SCROLLING_START';
export const SCROLLING_END = 'SCROLLING_END';
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';
export const SWITCH_DIRECTION = 'SWITCH_DIRECTION';
export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const SWITCH_MEASURE = 'SWITCH_MEASURE';
export const WINDOW_ACTIVE = 'WINDOW_ACTIVE';
export const WINDOW_INACTIVE = 'WINDOW_INACTIVE';

// Session constants
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const AUTHENTICATE_PENDING = 'AUTHENTICATE_PENDING';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_REJECTED = 'AUTHENTICATE_REJECTED';
export const AUTHENTICATE_RETRY = 'AUTHENTICATE_RETRY';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING';
export const FETCH_AGENT_PENDING = 'FETCH_AGENT_PENDING';
export const FETCH_AGENT_SUCCESS = 'FETCH_AGENT_SUCCESS';
export const FETCH_AGENT_REJECTED = 'FETCH_AGENT_REJECTED';
export const FETCH_BROKERAGES_PENDING = 'FETCH_BROKERAGES_PENDING';
export const FETCH_BROKERAGES_SUCCESS = 'FETCH_BROKERAGES_SUCCESS';
export const FETCH_BROKERAGES_REJECTED = 'FETCH_BROKERAGES_REJECTED';
export const FETCH_PERSON_PENDING = 'FETCH_PERSON_PENDING';
export const FETCH_PERSON_SUCCESS = 'FETCH_PERSON_SUCCESS';
export const FETCH_PERSON_REJECTED = 'FETCH_PERSON_REJECTED';

export const UPDATE_AGENT_PENDING = 'UPDATE_AGENT_PENDING';
export const UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS';
export const UPDATE_AGENT_REJECTED = 'UPDATE_AGENT_REJECTED';
export const UPDATE_BROKERAGE_PENDING = 'UPDATE_BROKERAGE_PENDING';
export const UPDATE_BROKERAGE_SUCCESS = 'UPDATE_BROKERAGE_SUCCESS';
export const UPDATE_BROKERAGE_REJECTED = 'UPDATE_BROKERAGE_REJECTED';
export const UPDATE_PERSON_PENDING = 'UPDATE_PERSON_PENDING';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_REJECTED = 'UPDATE_PERSON_REJECTED';
