import React from 'react';
import PropTypes from 'prop-types';
import RealmButton from './RealmButton';
import localization from '../../../services/Localization';

const NavButtons = (props) => {
  const { onboarding: lang } = localization;
  return (
    <div className="realm-onboarding--actions flex-container">
      {!props.backDisabled && (
        <RealmButton
          addFunc={props.onBack || undefined}
          className="realm-onboarding--nav"
          history={props.history}
          nextPage={props.previousPage}
          onClick={props.onBack || undefined}
          title={lang.back}
          variant="outlined"
        />
      )}
      {!props.nextDisabled && (
        <RealmButton
          addFunc={props.onSkip || undefined}
          className="realm-onboarding--nav"
          history={props.history}
          nextPage={props.nextPage}
          onClick={props.onSkip || undefined}
          title={lang.skip}
          variant="outlined"
        />
      )}
      <RealmButton
        addFunc={props.onSave || undefined}
        className="realm-onboarding--save"
        disabled={props.isSaveDisabled}
        history={props.history}
        nextPage={props.nextPage}
        onClick={props.onSave || undefined}
        title={props.continueOnly ? lang.continue : lang.save_continue}
      />
    </div>
  );
};

NavButtons.propTypes = {
  backDisabled: PropTypes.bool,
  continueOnly: PropTypes.bool,
  history: PropTypes.object,
  isSaveDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  nextPage: PropTypes.string,
  previousPage: PropTypes.string,
  // optional functions
  onBack: PropTypes.func,
  onSave: PropTypes.func,
  onSkip: PropTypes.func
};

export default NavButtons;
