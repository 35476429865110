const sortList = [
  {
    index: 0,
    name: 'Name (A-Z)',
    field: 'fullName',
    order: 1
  },
  {
    index: 1,
    name: 'Name (Z-A)',
    field: 'fullName',
    order: -1
  }
];

export default sortList;
