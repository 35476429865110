import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Grid, withStyles } from '@material-ui/core';
import localstring from '../../services/Localization';
import numberWithCommas from '../../utils/number-with-commas';

import genericHouse from '../../images/img/estateblur2.jpg';

const styles = {
  houseImage: {
    background: `url(${genericHouse}) center center`,
    height: 192,
    backgroundSize: '100% auto !important',
    backgroundRepeat: 'no-repeat !important',
    transition: 'background-size 0.5s ease',
    '&:hover': {
      backgroundSize: '110% auto !important',
      transition: 'background-size 0.5s ease'
    }
  }
};

class MatchingAlertCard extends Component {
  state = {
    attemptImageReload: false
  };

  getListingAgent = (property) => {
    if (!property) {
      return null;
    }
    let returnAgents = '';
    if (typeof property.listingAgents !== 'undefined') {
      if (property.listingAgents.length) {
        property.listingAgents.map((agent, i) => {
          if (agent.person) {
            if (agent.person.name) {
              returnAgents +=
                i > 0
                  ? `, ${agent.person.name.firstName} ${
                      agent.person.name.lastName
                    }`
                  : `${agent.person.name.firstName} ${
                      agent.person.name.lastName
                    }`;
            }
          }
          return null;
        });
      }
    }
    return returnAgents;
  };

  imageDidNotLoad = () => {
    if (!this.state.attemptImageReload) {
      this.setState({
        attemptImageReload: true
      });
    }
  };

  renderMatchingCard = () => {
    const { attemptImageReload } = this.state;
    const { message } = this.props;
    const details = message.listing.property;
    const { listing } = message;
    let price = 'N/A';
    if (listing.price && listing.price.amount) {
      price = `$${numberWithCommas(listing.price.amount)}`;
    }
    const photoURL =
      listing.pictures && listing.pictures.length > 0
        ? listing.pictures[0].pictureUrl
        : '';

    return (
      <div className="realm--house-card">
        <Link
          className="realm--house-card-detail-link"
          to={`/match-alerts-detail?id=${this.props.message.messageId}`}
        >
          <div className="realm--alert-card-title">
            {message.clients.length === 1 ? (
              <span>1 Client</span>
            ) : (
              <span>{message.clients.length} Clients</span>
            )}{' '}
            Matched
          </div>
          <div className="realm--alert-card-date">On {moment(message.dateUTC).format('MMMM Do, YYYY')}</div>
          <div className="realm--house-card-image">
            {photoURL !== '' && !attemptImageReload ? (
              <img
                src={photoURL || genericHouse}
                alt={details.streetAddress}
                style={{ background: `url(${photoURL || genericHouse}) center center` }}
                onError={this.imageDidNotLoad}
              />
            ) : (
              <img
                src={genericHouse}
                alt={details.streetAddress}
                style={{ background: `url(${genericHouse}) center center` }}
              />
            )}
          </div>
          <div className="realm--house-card-info">
            <div className="realm--house-card-address-block">
              {details.streetAddress}
              <br />
              {details.city}, {details.state} {details.zipcode}
            </div>
            <div className="realm--house-card-pie-block">{price}</div>
          </div>
        </Link>
        <div className="realm--alert-card-offered">
          Offered By:
          <div className="realm--alert-card-from-agent">
            {message.fromAgentName}
            <br />
            {message.fromAgent.brokerages[0].name}
            <br />
            <div style={{ textAlign: 'center' }}>
              <span>{message.fromAgent.person.primaryPhone}</span>
              {message.fromAgent.person.primaryEmail && (
                <span>
                  {' '}|{' '}
                  <a href={`mailto: ${message.fromAgent.person.primaryEmail}`}>
                    Send Email
                  </a>
                </span>
              )}
            </div>
          </div>
          <br />
          <br />
          <Link
            style={{ display: 'inline-block' }}
            to={`/match-alerts-detail?id=${this.props.message.messageId}`}
          >
            <Button variant="outlined" size="medium">
              {localstring.details}
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  renderPropertyCard = () => {
    const { property } = this.props.listing;
    const { attemptImageReload } = this.state;
    const details = property.property;
    const price =
      `$${numberWithCommas(property.price.amount)}` || null;
    const photoURL =
      property.pictures && property.pictures.length > 0
        ? property.pictures[0].pictureUrl
        : '';
    return (
      <div className="realm--house-card property">
        <div className="realm--house-card-image">
          {photoURL !== '' && !attemptImageReload ? (
            <Link
              to={`/match-detail?id=${property._id}`}
              className="realm--house-card-detail-link"
            >
              <img
                src={photoURL || genericHouse}
                alt={details.streetAddress}
                style={{ background: `url(${photoURL || genericHouse}) center center` }}
                onError={this.imageDidNotLoad}
              />
            </Link>
          ) : (
            <img
              src={genericHouse}
              alt={details.streetAddress}
              style={{ background: `url(${genericHouse}) center center` }}
            />
          )}
        </div>
        <div className="realm--house-card-info">
          <div className="realm--house-card-address-block">
            {details.streetAddress}
            <br />
            {details.city}, {details.state} {details.zipcode}
            <br />
            {property.status && (
              <span>
                <strong>Status</strong>: {property.status}
              </span>
            )}
            <br />
            <br />
            <Link
              to={`/match-detail?id=${property._id}`}
              className="realm--house-card-detail-link"
            >
              See Full Listing
            </Link>
          </div>
          <div className="realm--house-card-broker-info">
            <strong>Price: {price}</strong>
            <br />
            {property.listingBrokerage && property.listingBrokerage.name}
            <br />
            {this.getListingAgent(property) && (
              <span>Agent: {this.getListingAgent(property)}</span>
            )}
            <br />
            <br />
            {property.alwaysEmailAgent && property.listingEmail && (
              <a href={`mailto: ${property.listingEmail}`}>Email Agent</a>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Grid
        key={this.props.message.messageId}
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
      >
        {this.renderMatchingCard()}
      </Grid>
    );
  }
}

MatchingAlertCard.propTypes = {
  message: PropTypes.object
};

export default withStyles(styles)(MatchingAlertCard);
