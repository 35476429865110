import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, withStyles } from '@material-ui/core';
import styles from './styles';

class ViewToggle extends Component {
  state = {
    gridView: this.props.gridView
  };

  handleClick = (e) => {
    const isGridView = e.currentTarget.name === 'grid';
    this.setState({
      gridView: isGridView
    });
    this.props.clickHandler(isGridView);
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        className="realm--legend-actions"
        style={this.props.style || undefined}
      >
        <IconButton name="grid" onClick={this.handleClick}>
          <i
            className={classNames(
              'material-icons',
              this.state.gridView
                ? classes.iconSelected
                : classes.iconUnselected
            )}
          >
            grid_on
          </i>
        </IconButton>
        <IconButton name="tables" onClick={this.handleClick}>
          <i
            className={classNames(
              'material-icons',
              this.state.gridView
                ? classes.iconUnselected
                : classes.iconSelected
            )}
          >
            format_align_justify
          </i>
        </IconButton>
      </div>
    );
  }
}

ViewToggle.propTypes = {
  classes: PropTypes.object,
  clickHandler: PropTypes.func,
  gridView: PropTypes.bool
};

export default withStyles(styles)(ViewToggle);
