/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import SelectableTag from './SelectableTag';
import LoadingIndicator from '../LoadingIndicator';
import AppData from '../../services/AppData';

import localstring from '../../services/Localization';

class TagDialog extends Component {
  state = {
    editMode: false,
    isDialogOpen: false,
    isLoadingTags: true,
    isUpdating: false,
    selectedTags: [],
    selectedTagIds: [],
    tagDefinitions: []
  };

  setCheckedTags = () => {
    const { selectedTags, tagDefinitions } = this.state;
    const labels = Object.keys(tagDefinitions);
    const newState = [];
    labels.map((label) => {
      newState[label] = tagDefinitions[label].map((tag) => {
        if (selectedTags.includes(tag.tagName)) {
          tag.checked = true;
        } else {
          tag.checked = false;
        }
        return tag;
      });
      return null;
    });
    this.setState({
      tagDefinitions: newState
    });
  };

  openDialog = (e) => {
    e.preventDefault();
    this.loadTags();
    this.setState({
      isDialogOpen: true
    });
  };

  closeDialog = (e) => {
    e.preventDefault();
    this.setState({
      isDialogOpen: false
    });
    if (this.props.filterable && this.props.onFilterDone) {
      this.props.onFilterDone();
    }
  };

  cancelDialog = (e) => {
    e.preventDefault();
    this.setState({
      isDialogOpen: false
    });
  }

  toggleEditMode = (e) => {
    e.preventDefault();
    this.setState({
      editMode: !this.state.editMode
    });
  };

  loadTags = async () => {
    const { id, type } = this.props;
    if (!this.props.filterable || AppData.tagsLoaded === false) {
      await AppData.loadTags(type);
      if (!this.props.filterable) {
        await AppData.loadSelectedTags(type, id);
      }
    }
    this.setState(
      {
        isLoadingTags: false,
        selectedTags: this.props.filterable ? this.state.selectedTags : AppData.selectedTags,
        tagDefinitions: AppData.tagCategories
      },
      () => {
        this.setCheckedTags();
      }
    );
  };

  updateTag = (e) => {
    const tagId = e.target.id;
    const { checked, name } = e.target;
    const { type, id: idp } = this.props;
    const { selectedTags, selectedTagIds } = this.state;
    const newState = selectedTags.slice(0);
    const newIdState = selectedTagIds.slice(0);
    if (checked) {
      if (!this.props.filterable) {
        AppData.addUserTag(type, idp, name);
      }
      newState.push(name);
      newIdState.push(tagId);
    } else {
      if (!this.props.filterable) {
        AppData.deleteUserTag(type, idp, name);
      }
      const i = newState.indexOf(name);
      const j = newIdState.indexOf(tagId);
      if (i !== -1) {
        newState.splice(i, 1);
      }
      if (j !== -1) {
        newIdState.splice(i, 1);
      }
    }
    this.setState(
      {
        selectedTags: newState,
        selectedTagIds: newIdState
      },
      () => {
        this.setCheckedTags(newState);
        this.props.onFilter(this.state.selectedTags, this.state.selectedTagIds);
      }
    );
  };

  renderEditableTags = () => {
    const output = [];
    const { tagDefinitions } = this.state;
    const labels = Object.keys(tagDefinitions);
    labels.map((label) => {
      output.push(
        <div className="realm--tag-list-label" key={label}>
          {label}
        </div>
      );
      tagDefinitions[label].map((tag) => {
        output.push(
          <SelectableTag
            changeHandler={this.updateTag}
            key={tag.id}
            tag={tag}
          />
        );
        return null;
      });
      return null;
    });
    return output;
  };

  renderFilterableTags = () => {
    const output = [];
    const { tagDefinitions } = this.state;
    const labels = Object.keys(tagDefinitions);
    labels.map((label) => {
      output.push(
        <div className="realm--tag-list-label" key={label}>
          {label}
        </div>
      );
      tagDefinitions[label].map((tag) => {
        output.push(
          <SelectableTag
            changeHandler={this.updateTag}
            key={tag.id}
            tag={tag}
          />
        );
        return null;
      });
      return null;
    });
    return output;
  };

  renderReadOnlyTags = () => {
    const { tagDefinitions } = this.state;
    const labels = Object.keys(tagDefinitions);
    const flattenedTags = [];
    labels.map((label) => {
      tagDefinitions[label].map((obj) => {
        flattenedTags.push(obj);
        return null;
      });
      return null;
    });
    const selectedTags = flattenedTags.filter((tag) => tag.checked);
    if (selectedTags.length === 0) {
      return (
        <div className="realm--no-tags-container">
          <p>
            {localstring.no_tags_client}
          </p>
        </div>
      );
    }
    return (
      <Fragment>
        <div className="realm--tag-list-label">Selected Tags</div>
        {selectedTags.map((tag) => (
          <div key={`$selected-tag-${tag.id}`} className="realm--tag-list-tag">
            {tag.label}
          </div>
        ))}
      </Fragment>
    );
  };

  renderTagList = () => {
    if (this.state.isLoadingTags) {
      return (
        <div className="realm--loading-container">
          <LoadingIndicator />
        </div>
      );
    }
    return (
      <div className="realm--tag-dialog-container">
        {this.props.editable && (
          <a
            className="realm--edit-tags-toggle"
            href=""
            onClick={this.toggleEditMode}
            role="button"
          >
            {this.state.editMode ? 'View Tags' : 'Add/Edit Tags'}
          </a>
        )}
        {this.props.filterable && this.renderFilterableTags()}
        {this.state.editMode && !this.props.filterable
          ? this.renderEditableTags()
          : this.renderReadOnlyTags()}
        {this.state.isUpdating && (
          <div className="realm--loading-container overlay">
            <LoadingIndicator />
          </div>
        )}
      </div>
    );
  };

  render() {
    const editingTitle = this.props.editable ? 'View/Edit Tags' : 'View Tags';
    const title = this.props.title ? this.props.title : editingTitle;
    return (
      <Fragment>
        <Button color="primary" onClick={this.openDialog} variant="contained">
          {title}
        </Button>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth={this.props.size}
          onClose={this.closeDialog}
          open={this.state.isDialogOpen}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent style={{ position: 'relative' }}>
            {this.renderTagList()}
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={this.cancelDialog}
              style={{
                opacity: this.state.isUpdating ? '.5' : '1',
                pointerEvents: this.state.isUpdating ? 'none' : 'auto'
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={this.closeDialog}
              style={{
                opacity: this.state.isUpdating ? '.5' : '1',
                pointerEvents: this.state.isUpdating ? 'none' : 'auto'
              }}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

TagDialog.propTypes = {
  editable: PropTypes.bool,
  filterable: PropTypes.bool,
  id: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterDone: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
  title: PropTypes.string,
  type: PropTypes.oneOf(['agents', 'agentClients', 'brokerages', 'listings', 'properties'])
};

TagDialog.defaultProps = {
  size: 'sm'
};

export default TagDialog;
