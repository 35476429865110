import axios from 'axios';

const interceptors = {
  onUnauthorized: null,
};

const noop = (response) => response;

const redirectOnUnauthorized = (axiosResponse) => {
  if (
    axiosResponse &&
    axiosResponse.response &&
    axiosResponse.response.status === 403
  ) {
    window.location.replace('/login');
  } else {
    throw axiosResponse;
  }
};

const attachInterceptors = () => {
  interceptors.onUnauthorized = axios.interceptors.response.use(noop, redirectOnUnauthorized);
};

const getInterceptors = () => interceptors;

export {
  attachInterceptors,
  getInterceptors,
};
