import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  withStyles,
} from '@material-ui/core';

const styles = () => ({
  formLocation: {
    // display: 'inline-block',
    // flexWrap: 'wrap',
    // marginLeft: 20,
  },
  formControl: {
    minWidth: 170,
    fontSize: 30
  },
});

class BrokeragePicker extends Component {
  state = {
    brokerageList: this.props.brokerages || [],
    selected: this.props.selected,
    selectedBrokerage: ''
  };

  // componentDidMount() {
  //   const { selected } = this.props;
  //   if (selected && selected !== '' && selected !== 'All') {
  //     this.setState({ selected });
  //   }
  // }

  handleSelection = (event) => {
    if (typeof this.props.handleBrokeragePicked !== 'function') {
      return null;
    }
    this.setState({ [event.target.name]: event.target.value });
    this.props.handleBrokeragePicked(event);
    return null;
  };

  render() {
    const { classes } = this.props;
    // since classes is required, return nothing is classes is not available
    if (!classes) { return null; }
    return (
      <span>
        <span>{this.state.selected}</span>
        <form className={classes.formLocation} autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel
              className="muiInputLabel"

            >
              Brokerage
            </InputLabel>
            <Select
              className="selectWhite"
              value={this.state.selectedBrokerage}
              onChange={this.handleSelection}
              inputProps={{
                name: 'selectedBrokerage',
                id: 'brokerage-simple'
              }}
            >
              <MenuItem value="All">All</MenuItem>
              {this.state.brokerageList.map((brokerage) => (
                <MenuItem key={brokerage._id} value={brokerage._id}>{brokerage.name}</MenuItem>
              ))
              }
            </Select>
          </FormControl>
        </form>
      </span>
    );
  }
}

BrokeragePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBrokeragePicked: PropTypes.func,
  selected: PropTypes.bool
};

export default withStyles(styles)(BrokeragePicker);
