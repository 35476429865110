import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

const SuccessDialog = (props) => (
  <Dialog
    open={props.open || false}
    aria-labelledby="success-dialog-title"
    aria-describedby="success-dialog"
  >
    <DialogTitle id="success-dialog-title">Success!</DialogTitle>
    <DialogContent className="edit-modal">
      <DialogContentText className="error-msg">
        {props.message || 'Your changes have been successfully saved.'}
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

SuccessDialog.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool
};

export default SuccessDialog;
