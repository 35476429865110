import axios from 'axios';
import { API_ROOT } from '../constants';
import * as storage from '../persistence/storage';

const token = storage.get('token') || '';

const api = axios.create({
  baseURL: API_ROOT,
  timeout: 24000
});

if (token) {
  api.defaults.headers.common['X-Token'] = token;
}

export default api;
