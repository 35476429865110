import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import DevTools from '../components/DevTools';
import persistenceStore from '../persistence/store';
import reducers from '../reducers';

const middleware = [applyMiddleware(thunk), DevTools.instrument(), persistenceStore];
const buildMiddleware = () => middleware;
const finalCreateStore = compose(...buildMiddleware())(createStore);

export default function configureStore(initialState) {
  const store = finalCreateStore(reducers, initialState);
  return store;
}
