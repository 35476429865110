import * as constants from '../constants';

export function changeActiveStatus(isActive) {
  if (typeof isActive !== 'boolean') {
    return null;
  }
  return isActive
    ? { type: constants.WINDOW_ACTIVE, payload: isActive }
    : { type: constants.WINDOW_INACTIVE, payload: isActive };
}

export function changeCurrency(currency) {
  if (typeof currency !== 'string') {
    return null;
  }
  return { type: constants.SWITCH_CURRENCY, payload: currency };
}

export function changeDirection(isRTL) {
  if (typeof isRTL !== 'boolean') {
    return null;
  }
  return { type: constants.SWITCH_DIRECTION, payload: isRTL };
}

export function changeLocale(locale) {
  if (typeof locale !== 'string') {
    return null;
  }
  return { type: constants.SWITCH_LOCALE, payload: locale };
}

export function changeMeasure(measure) {
  if (typeof measure !== 'string') {
    return null;
  }
  return { type: constants.SWITCH_MEASURE, payload: measure };
}

export function clearApplication() {
  return { type: constants.CLEAR_APPLICATION, payload: null };
}

export function onScroll(isScrolling) {
  return {
    type: isScrolling ? constants.SCROLLING_START : constants.SCROLLING_END,
    payload: isScrolling
  };
}

export function onResize(isResizing) {
  return {
    type: isResizing ? constants.RESIZE_START : constants.RESIZE_END,
    payload: isResizing
  };
}
