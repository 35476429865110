import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import localstring from '../../services/Localization';

class ProfileProgress extends PureComponent {
  hasPhoneNumber = () => {
    const { data } = this.props;
    let returnValue = false;
    if (data.phones && data.phones.length) {
      data.phones.map((phone) => {
        if (phone.number) {
          returnValue = true;
        }
        return null;
      });
    }
    return returnValue;
  };

  hasEmail = () => {
    const { data } = this.props;
    let returnValue = false;
    if (data.emails && data.emails[0]) {
      data.emails.map((email) => {
        if (email.address) {
          returnValue = true;
        }
        return null;
      });
    }
    return returnValue;
  };

  calculateProgressCompleted = () => {
    const { data, realmData, type } = this.props;
    if (!data) {
      return 0;
    }
    const hasProfilePhoto = typeof data.photoURL === 'string' && data.photoURL.trim() !== '';
    const hasAddress = data.address && data.address.streetAddress !== '';
    const hasEmail = this.hasEmail();
    const hasPhone = this.hasPhoneNumber();
    const hasClients =
      realmData && realmData.clients && realmData.clients.length > 0;
    const hasTags =
      realmData && realmData.tagNames && realmData.tagNames.length > 0;
    const hasSocialAccounts =
      realmData &&
      realmData.socialAccounts &&
      realmData.socialAccounts.length > 0;

    let profilePoints = 0;

    if (type === 'agents') {
      profilePoints = 55;
      profilePoints += hasProfilePhoto ? 5 : 0;
      profilePoints += hasEmail ? 10 : 0;
      profilePoints += hasPhone ? 5 : 0;
      profilePoints += hasClients ? 10 : 0;
      profilePoints += hasTags ? 10 : 0;
      profilePoints += hasSocialAccounts ? 5 : 0;
      return profilePoints;
    }

    if (type === 'agentClients') {
      profilePoints = 40;
      profilePoints += hasEmail ? 15 : 0;
      profilePoints += hasPhone ? 8 : 0;
      profilePoints += hasAddress ? 7 : 0;
      profilePoints += hasTags ? 15 : 0;
      return profilePoints;
    }

    return profilePoints;
  };

  render() {
    if (!this.props.data || !this.props.realmData) {
      return null;
    }
    return (
      <div style={{ textAlign: 'center' }}>
        {localstring.dash_profile_completion}:
        <LinearProgress
          variant="determinate"
          value={this.calculateProgressCompleted()}
          style={{
            marginLeft: '25%',
            marginRight: '25%',
            marginTop: 18
          }}
        />
      </div>
    );
  }
}

ProfileProgress.propTypes = {
  data: PropTypes.object,
  realmData: PropTypes.object,
  type: PropTypes.oneOf(['agents', 'agentClients'])
};

ProfileProgress.defaultProps = {
  type: 'agents'
};

export default ProfileProgress;
