import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

class EditField extends Component {
  state = {
    value: this.props.value
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.change(event);
  };

  render() {
    return (
      <div className={`realm-onboarding--input-group ${this.props.className}`}>
        <div className="realm-onboarding--form-label">{this.props.label}</div>
        <div className="realm-onboarding--input-field">
          {this.props.format ? (
            <NumberFormat
              name={this.props.name}
              type={this.props.password ? 'password' : 'text'}
              value={this.state.value}
              onChange={this.handleChange}
              placeholder={this.props.placeholder}
              format={this.props.format}
              mask={this.props.mask}
            />
          ) : (
            <input
              name={this.props.name}
              type={this.props.password ? 'password' : 'text'}
              value={this.state.value}
              onChange={this.handleChange}
              placeholder={this.props.placeholder}
            />
          )}
        </div>
      </div>
    );
  }
}

EditField.propTypes = {
  change: PropTypes.func
};

export default EditField;
