import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Agreements from './routes/Agreements';
import Clients from './routes/Clients';
import OnboardingComplete from './routes/OnboardingComplete';
import PropertyTags from './routes/PropertyTags';
import YourAccount from './routes/YourAccount';

const OnboardingRoutes = (storeProps) => (
  <Switch>
    <Route
      exact
      path="/"
      render={(props) => <Agreements {...props} {...storeProps} />}
    />
    <Route
      exact
      path="/agreements"
      render={(props) => <Agreements {...props} {...storeProps} />}
    />
    <Route
      path="/clients"
      render={(props) => <Clients {...props} {...storeProps} />}
    />
    <Route
      path="/complete"
      render={(props) => <OnboardingComplete {...props} {...storeProps} />}
    />
    <Route
      path="/property-tags"
      render={(props) => <PropertyTags {...props} {...storeProps} />}
    />
    <Route
      path="/your-account"
      render={(props) => <YourAccount {...props} {...storeProps} />}
    />
    <Route path="" render={() => <Redirect to={{ pathname: '/' }} />} />
  </Switch>
);

export default OnboardingRoutes;
